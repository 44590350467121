import React, { useState, useContext } from "react";
import "./ForgotPassword.css";
import InputWithValidation from "../InputWithValidation/InputWithValidation.component";
import Button from "../Button/Button.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme, setUserID, setShowReset } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmnewpassword, setConfirmNewPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newpasswordError, setNewPasswordError] = useState("");
  const [confirmnewpasswordError, setConfirmNewPasswordError] = useState("");
  const [emailsent, setemailsent] = React.useState("");
  const [codesent, setcodesent] = React.useState(false);
  const [thecodeisvalid, setthecodeisvalid] = React.useState(false);
  const [codevalue, setcodevalue] = React.useState("");
  const [passmessage, setpassmessage] = React.useState("");

  const evaluatecode = (value) => {
    if (value == codevalue) {
      setthecodeisvalid(true);
      setcodesent(false);
      setcodevalue("");
    } else {
      setcodesent(false);
      setcodevalue("");
      setemailsent("");
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    if (newpassword == "") {
      setNewPasswordError(<p>{t("p1")}</p>);
    } else if (confirmnewpassword == "") {
      setConfirmNewPasswordError(<p>{t("p2")}</p>);
    } else if (newpassword != confirmnewpassword) {
      setNewPasswordError(<p>{t("p3")}</p>);
    } else {
      return fetch(
        "https://marihuanadating.com/endpoint=wld/changepasswordthroughemail.php",
        {
          method: "post",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            newpass: newpassword,
          }),
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          setpassmessage(responseJson);
        });
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setNewPasswordError("");
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
    setConfirmNewPasswordError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate email and password
    if (!email) {
      setEmailError(t("p9"));
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t("p17"));
      return;
    }

    fetch("https://marihuanadating.com/endpoint-wld/resetpass.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson == "ok") {
          setemailsent(t("g4"));
          setcodesent(true);
          fetch("https://marihuanadating.com/endpoint-wld/getotp.php", {
            method: "post",
            headers: {
              "access-control-allow-origin": "*",
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              email: email,
            }),
          })
            .then((response) => response.json())
            .then((responseJson) => {
              setcodevalue(responseJson);
            })
            .catch((error) => {
              console.error(error);
            });
        } else if (responseJson == "notfound") {
          setEmailError(t("p10"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      {thecodeisvalid ? (
        <div id="fortheform2">
          <div className="resetblock">
            <form onSubmit={handleUpdate}>
              <InputWithValidation
                label={t("p5")}
                type="password"
                value={newpassword}
                error={newpasswordError}
                onChange={handleNewPasswordChange}
              />

              <InputWithValidation
                label={t("p6")}
                type="password"
                value={confirmnewpassword}
                error={confirmnewpasswordError}
                onChange={handleConfirmNewPasswordChange}
              />

              <Button
                text={t("p7")}
                color={"#fff"}
                backgroundcolor={theme}
                type="submit"
              />
            </form>

            {passmessage !== "" ? (
              <p
                style={{
                  color: "green",
                  fontSize: 18,
                  textAlign: "center",
                  margin: 30,
                }}
              >
                {t("p8")}
              </p>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      ) : (
        <div>
          {codesent ? (
            <div className="codeblocking">
              {emailsent !== "" ? (
                <p
                  style={{
                    color: theme,
                    fontSize: 22,
                    marginTop: 30,
                    marginHorizontal: 10,
                    textAlign: "center",
                  }}
                >
                  {emailsent}
                </p>
              ) : (
                <p></p>
              )}
              <div className="inthecode">
                <ReactCodeInput onComplete={(value) => evaluatecode(value)} />
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <InputWithValidation
                label={t("p15")}
                type="email"
                value={email}
                error={emailError}
                onChange={handleEmailChange}
              />
              <p
                style={{ color: theme, textAlign: "right", cursor: "pointer" }}
                onClick={() => setShowReset(false)}
              >
                {t("p16")}
              </p>

              <Button
                text={t("p13")}
                color={"#fff"}
                backgroundcolor={theme}
                type="submit"
              />
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
