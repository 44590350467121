import { useContext, useState, useEffect } from "react";
import homeBackground from "../../assets/home_background.jpg";
import Footer from "../../components/Footer/Footer.component";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import TopMembers from "../../components/Top Members/TopMembers.component";
import Testimoials from "../../components/Testimonials/Testimonials.component";
import HomeDate from "../../components/HomeDate/HomeDate.component";
import HomeSpecifications from "../../components/HomeSpecifications/HomeSpecifications.component";
import ExpandBox from "../../components/ExpandBox/ExpandBox.component";

const Expand = () => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);








  return (
    <div>
      <div className="container">
        <div className="gradient-overlay"></div>
        <img className="image" src={homeBackground} />
        <ExpandBox />
      </div>
      <HomeDate />
      <HomeSpecifications />
      <TopMembers />
      <Testimoials />
      <AppLinks />
      <Footer />

    
     
    </div>
  );
};

export default Expand;
