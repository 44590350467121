import React, { useState, useEffect, useRef, useContext } from "react";
import "./ThemeEditor.styles.scss";
import { MdClose } from "react-icons/md";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";

const ThemeEditor = ({ isPanelOpen, toggleThemeEditor }) => {
  const panelRef = useRef();
  const { theme, setTheme } = useContext(UserContext);
  const { t } = useTranslation();

  const changeTheme = (color) => {
    setTheme(color);
  };

  const handleCloseClick = () => {
    toggleThemeEditor();
  };

  const handleOutsideClick = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      toggleThemeEditor();
    }
  };

  // useEffect(() => {
  //   document.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);

  return (
    <div
      className={`theme-panel ${isPanelOpen ? "open" : ""}`}
      ref={panelRef}
      style={{ borderColor: theme }}
    >
      <button
        className="close-button"
        onClick={handleCloseClick}
        style={{ color: theme }}
      >
        <MdClose size={30} />
      </button>
      <h3 style={{ textAlign: "center", color: theme, width: "80%" }}>
        {t("o28")}
      </h3>
      <div
        className="delimiter"
        style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
      ></div>
      <div className="colorgrid">
        <div
          className={`grid-item ${theme === "#6B3FA0" ? "bold" : ""}`}
          onClick={() => changeTheme("#6B3FA0")}
          style={{ color: "#6B3FA0" }}
        >
          Purple
        </div>
        <div
          className={`grid-item ${theme === "#ed4167" ? "bold" : ""}`}
          onClick={() => changeTheme("#ed4167")}
          style={{ color: "#ed4167" }}
        >
          Pink
        </div>
        <div
          className={`grid-item ${theme === "#3ae346" ? "bold" : ""}`}
          onClick={() => changeTheme("#3ae346")}
          style={{ color: "#3ae346" }}
        >
          Green
        </div>

        <div
          className={`grid-item ${theme === "#0099a7" ? "bold" : ""}`}
          onClick={() => changeTheme("#0099a7")}
          style={{ color: "#0099a7" }}
        >
          Blue
        </div>
        <div
          className={`grid-item ${theme === "#ff7474" ? "bold" : ""}`}
          onClick={() => changeTheme("#ff7474")}
          style={{ color: "#ff7474" }}
        >
          Orange
        </div>
        <div
          className={`grid-item ${theme === "#40E0D0" ? "bold" : ""}`}
          onClick={() => changeTheme("#40E0D0")}
          style={{ color: "#40E0D0" }}
        >
          Turquoise
        </div>

        <div
          className={`grid-item ${theme === "#FF7F50" ? "bold" : ""}`}
          onClick={() => changeTheme("#FF7F50")}
          style={{ color: "#FF7F50" }}
        >
          Coral
        </div>
        <div
          className={`grid-item ${theme === "#9966CC" ? "bold" : ""}`}
          onClick={() => changeTheme("#9966CC")}
          style={{ color: "#9966CC" }}
        >
          Amethyst
        </div>
        <div
          className={`grid-item ${theme === "#50C878" ? "bold" : ""}`}
          onClick={() => changeTheme("#50C878")}
          style={{ color: "#50C878" }}
        >
          Emerald
        </div>

        <div
          className={`grid-item ${theme === "#0F52BA" ? "bold" : ""}`}
          onClick={() => changeTheme("#0F52BA")}
          style={{ color: "#0F52BA" }}
        >
          Sapphire
        </div>
        <div
          className={`grid-item ${theme === "#B76E79" ? "bold" : ""}`}
          onClick={() => changeTheme("#B76E79")}
          style={{ color: "#B76E79" }}
        >
          Rose Gold
        </div>
        <div
          className={`grid-item ${theme === "#FFC512" ? "bold" : ""}`}
          onClick={() => changeTheme("#FFC512")}
          style={{ color: "#FFC512" }}
        >
          Sunflower
        </div>

        <div
          className={`grid-item ${theme === "#39FF14" ? "bold" : ""}`}
          onClick={() => changeTheme("#39FF14")}
          style={{ color: "#39FF14" }}
        >
          Neon Green
        </div>
        <div
          className={`grid-item ${theme === "#E0115F" ? "bold" : ""}`}
          onClick={() => changeTheme("#E0115F")}
          style={{ color: "#E0115F" }}
        >
          Ruby
        </div>
      </div>
    </div>
  );
};

export default ThemeEditor;
