import React, { useEffect, useContext, useState } from "react";
import "./ModalLogin.styles.scss";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Login from "../Login/Login.component";
import ForgotPassword from "../ForgotPassword/ForgotPassword.component";
import { UserContext } from "../../contexts/UserProvider";

const ModalLogin = ({ isOpenLogin, closeModalLogin }) => {
  const { t } = useTranslation();
  const { theme, showReset, setShowReset } = useContext(UserContext);
  const handleCloseModalLogin = () => {
    closeModalLogin();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modalLogin")) {
        handleCloseModalLogin();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`modalLogin ${isOpenLogin ? "open" : ""}`}>
      <div className="modal-content-login">
        {showReset ? <h2>{t("p13")}</h2> : <h2>{t("l1")}</h2>}

        <div className="delimiter"></div>
        {showReset ? <ForgotPassword /> : <Login />}

        <button
          className="close-button"
          style={{ color: theme }}
          onClick={handleCloseModalLogin}
        >
          <MdClose size={20} /> {/* Use the desired icon component */}
        </button>
      </div>
    </div>
  );
};

export default ModalLogin;
