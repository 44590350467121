import React, { useEffect, useContext } from "react";
import "./ModalRegister.styles.scss";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Register from "../Register/Register.component";
import { UserContext } from "../../contexts/UserProvider";

const ModalRegister = ({ isOpenRegister, closeModalRegister }) => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
  const handleCloseModalRegister = () => {
    closeModalRegister();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modalRegister")) {
        handleCloseModalRegister();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`modalRegister ${isOpenRegister ? "open" : ""}`}>
      <div className="modal-content-register">
        <h2>{t("p24")}</h2>
        <div className="delimiter"></div>

        <Register />

        <button
          className="close-button"
          style={{ color: theme }}
          onClick={handleCloseModalRegister}
        >
          <MdClose size={20} /> 
        </button>
      </div>
    </div>
  );
};

export default ModalRegister;
