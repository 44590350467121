import { useContext, useState, useEffect } from "react";
import "./HomeBox.css";
import { UserContext } from "../../contexts/UserProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HomeBox = (props) => {
  const { theme, showBox, setShowBox, setRegisterPanel } = useContext(
    UserContext
  );
  const { t } = useTranslation();
  const [selectedGender, setSelectedGender] = useState("man");
  const [selectedLookingfor, setSelectedLookingfor] = useState("women");
  const [agemin, setagemin] = useState(21);
  const [agemax, setagemax] = useState(90);

  useEffect(() => {
    // Set showBox to true after component mounts to trigger animation
    setShowBox(true);
  }, []);

  const handleChance = () => {
    setShowBox(false);
    setRegisterPanel(true);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleLookingforChange = (event) => {
    setSelectedLookingfor(event.target.value);
  };

  const handleAgemin = (event) => {
    setagemin(event.target.value);
  };

  const handleAgemax = (event) => {
    setagemax(event.target.value);
  };

  const ageOptions = [];
  for (let age = 21; age <= 89; age++) {
    ageOptions.push(
      <option key={age} value={age}>
        {age}
      </option>
    );
  }

  const ageOptions2 = [];
  for (let age = 22; age <= 90; age++) {
    ageOptions2.push(
      <option key={age} value={age}>
        {age}
      </option>
    );
  }

  return (
    <div>
      <div className={`interaction-box ${showBox ? "show" : ""}`}>
        <p className="thetitle" style={{ color: theme }}>
          {t("l30")} {t("l31")}
        </p>
        <p className="theparagraf">
          {t("l32")} <br /> <span style={{ color: theme }}> {t("l35")}</span>
        </p>
        <div className="genders">
          <p>{t("l37")} </p>
          <select
            id="gender"
            value={selectedGender}
            onChange={handleGenderChange}
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: theme,
            }}
          >
            <option value="male">{t("d12")}</option>
            <option value="female">{t("d13")}</option>
          </select>
          <p> {t("l38")} </p>
          <select
            id="lookingfor"
            value={selectedLookingfor}
            onChange={handleLookingforChange}
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: theme,
            }}
          >
            <option value="female">{t("z2")}</option>
            <option value="male">{t("z1")}</option>
          </select>
        </div>
        <div className="ages">
          <p>{t("l39")} </p>
          <select
            id="agemin"
            value={agemin}
            onChange={handleAgemin}
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: theme,
            }}
          >
            {ageOptions}
          </select>

          <p>
            {" "}
            {t("l40")} {t("l36")}{" "}
          </p>
          <select
            id="agemax"
            value={agemax}
            onChange={handleAgemax}
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: theme,
            }}
          >
            {ageOptions2}
          </select>
          <p> {t("l40")}</p>
        </div>
        <div
          className="chancebutton"
          style={{ backgroundColor: theme }}
          onClick={handleChance}
        >
          <p>{t("l33")}</p>
        </div>
        <p className="theparagraf">
          {t("l34")} <br />{" "}
          <Link style={{ color: theme }} to="/terms">
            {t("t1")}
          </Link>{" "}
          {t("l36")}{" "}
          <Link style={{ color: theme }} to="/privacy">
            {t("t2")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default HomeBox;
