import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import { UserProvider } from "./contexts/UserProvider";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./locale/en.json"),
    },
    fr: {
      translation: require("./locale/fr.json"),
    },
    de: {
      translation: require("./locale/de.json"),
    },
    it: {
      translation: require("./locale/it.json"),
    },
    es: {
      translation: require("./locale/es.json"),
    },
    af: {
      translation: require("./locale/af.json"),
    },
    bn: {
      translation: require("./locale/bn.json"),
    },
    cs: {
      translation: require("./locale/cs.json"),
    },
    ro: {
      translation: require("./locale/ro.json"),
    },
    pl: {
      translation: require("./locale/pl.json"),
    },
    nl: {
      translation: require("./locale/nl.json"),
    },
    lt: {
      translation: require("./locale/lt.json"),
    },
    lv: {
      translation: require("./locale/lv.json"),
    },
    ru: {
      translation: require("./locale/ru.json"),
    },
    da: {
      translation: require("./locale/da.json"),
    },
    fi: {
      translation: require("./locale/fi.json"),
    },
    sv: {
      translation: require("./locale/sv.json"),
    },
    ko: {
      translation: require("./locale/ko.json"),
    },
    vi: {
      translation: require("./locale/vi.json"),
    },
    sk: {
      translation: require("./locale/sk.json"),
    },
    sl: {
      translation: require("./locale/sl.json"),
    },
    et: {
      translation: require("./locale/et.json"),
    },
    el: {
      translation: require("./locale/el.json"),
    },
    th: {
      translation: require("./locale/th.json"),
    },
    tr: {
      translation: require("./locale/tr.json"),
    },
    hr: {
      translation: require("./locale/hr.json"),
    },
    hu: {
      translation: require("./locale/hu.json"),
    },
    hi: {
      translation: require("./locale/hi.json"),
    },
    id: {
      translation: require("./locale/id.json"),
    },
    is: {
      translation: require("./locale/is.json"),
    },
    ja: {
      translation: require("./locale/ja.json"),
    },
    zh: {
      translation: require("./locale/zh.json"),
    },
    pt: {
      translation: require("./locale/pt.json"),
    },
    no: {
      translation: require("./locale/no.json"),
    },
   
  },
  lng: "en", // Set the default language here
  fallbackLng: "en", // Set the fallback language here
  interpolation: {
    escapeValue: false, // React already escapes variables
  },
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
        ,
      </BrowserRouter>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
