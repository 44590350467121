import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedAdmin = ({ children }) => {
  const userid = localStorage.getItem("userId");

  if (userid) {
    console.log(userid);
    return <Navigate to="/dashboard"  />;
  }

  return children;
};

export default ProtectedAdmin;