import "./AppLinks.styles.scss";
import { useTranslation } from "react-i18next";
import gplay from "../../assets/gplay.png";
import astore from "../../assets/apple-logo.png";
import hstore from "../../assets/huawei_logo.png";

const AppLinks = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="theapplinks">
      <h3>{t("x1")}</h3>
      <div className="blackbox">
        <img src={gplay} className="gplay-logo" alt="Google Play" />
        <div className="texting">
          <p style={{ color: "#fff", fontSize: 12 }}>{t("x2")}</p>
          <p style={{ color: "#fff", fontSize: 12 }}>Google Play</p>
        </div>
      </div>

      <div className="blackbox">
        <img src={astore} className="gplay-logo" alt="Apple Store" />
        <div className="texting">
          <p style={{ color: "#fff", fontSize: 12 }}>{t("x3")}</p>
          <p style={{ color: "#fff", fontSize: 12 }}>Apple Store</p>
        </div>
      </div>

      <div className="blackbox">
        <img src={hstore} className="gplay-logo" alt="Huawei Store" />
        <div className="texting">
          <p style={{ color: "#fff", fontSize: 12 }}>{t("x3")}</p>
          <p style={{ color: "#fff", fontSize: 12 }}>Huawei Store</p>
        </div>
      </div>
    </div>
  );
};

export default AppLinks;
