import { useContext } from "react";
import "./Testimonials.css";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
const Testimoials = (props) => {
  const { theme } = useContext(UserContext);
  const { t } = useTranslation();
  const colorTheme = {
    primaryColor: theme, // Example primary color
  };
  const boxShadowStyle = {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme,
    WebkitBoxShadow: `0px 0px 25px 0px ${colorTheme.primaryColor}`,
    MozBoxShadow: `0px 0px 25px 0px ${colorTheme.primaryColor}`,
    boxShadow: `0px 0px 25px 0px ${colorTheme.primaryColor}`,
  };
  const linethrough = {
    width: "90%",
    margin: "0 5%",
    borderBottomWidth: "0.5px",
    borderColor: theme,
    borderStyle: "solid",
    WebkitBoxShadow: `0px 0px 25px 0px ${colorTheme.primaryColor}`,
    MozBoxShadow: `0px 0px 25px 0px ${colorTheme.primaryColor}`,
    boxShadow: `0px 0px 25px 0px ${colorTheme.primaryColor}`,
  };
  return (
    <div className="fifth-chapter">
      <p className="usersay" style={{ color: theme }}>
        {t("l19")}
      </p>
      <div className="testimonials">
        <div className="columna" style={boxShadowStyle}>
          <p className="title">Kenneth & Elliot</p>
          <div style={linethrough}></div>
          <p className="description">
            I honestly had been on many Tinder dates and was absolutely sure I
            was meeting a fling to get a free meal and have some fun...3 years
            and sooo many dates and memories later, I am married to my Marijuana
            Dating guy, Kenny.
          </p>
        </div>
        <div className="columna" style={boxShadowStyle}>
          <p className="title">Victoria & Baileigh Rodriguez</p>
          <div style={linethrough}></div>
          <p className="description">
            Thank you for making it possible for me to meet my soulmate. Five
            minutes into our first conversation, my now-wife mentioned how we
            would have an amazibg wedding.
          </p>
        </div>
        <div className="columna" style={boxShadowStyle}>
          <p className="title">Ryan & Lindsay Walsh</p>
          <div style={linethrough}></div>
          <p className="description">
            ...just gotten out of a bad breakup and created an account to keep
            my mind off the breakup. After about a week of talking we decided to
            meet up at a local bar for drinks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimoials;
