import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import './SearchInput.styles.scss';
import { useTranslation } from "react-i18next";

const SearchInput = (props) => {
  const { theme } = useContext(UserContext);
  const {t} = useTranslation();
  return (
    <input
      style={{ borderColor: theme, color: theme }}
      className="TextInputStyleClass"
      onChange={(event) =>
        props.onSearchFilterFunction(event.target.value.toLocaleUpperCase())
      }
      placeholder={t("s1")}
    />
  );
};



export default SearchInput;
