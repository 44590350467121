import React, { useContext } from "react";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import SearchInput from "../../components/SearchInput/SearchInput.component";
import Empty from "../../components/Empty/Empty.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import LikedRow from "../../components/LikedRow/LikedRow.component";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';

const ProfileLiked = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { liked, filteredLiked, filterLikedUsers } = useContext(UserContext);

  const SearchFilterFunction = (text) => {
    filterLikedUsers(text);
  };

  if (liked.length == 0) {
    return (
      <div className="app-container">
        <div className="mobile-container">
          <Header title={t("o29")} hasgoback={true}/>
          <Empty>{t("ll4")}</Empty>
          <BottomNavigation />
        </div>
      </div>
    );
  }
  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("o29")} hasgoback={true}/>
        <SearchInput onSearchFilterFunction={SearchFilterFunction} />
        {filteredLiked.map((item, key) => (
          <LikedRow
            image={item.profile_photo}
            first_name={item.first_name}
            last_name={item.last_name}
            thetime={item.thetime}
            onSelect={() => {
              navigate(
                `/encounters/UserProfile?${queryString.stringify(item)}`
              );
            }}
          />
        ))}
        <div style={{height:'50px'}}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default ProfileLiked;
