import "./Button.styles.scss";


const Button = (props) => {
  return (
    <button
      style={{ backgroundColor: props.backgroundcolor, color: props.color }}
      className={'button ' +props.class}
      onClick={props.onClick}
      type={props.type}
    >
      {props.text}
    </button>
  );
};

export default Button;
