import { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
const Gradient = (props) => {
    const { theme } = useContext(UserContext);
    return (
      <div>
        <p
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginLeft: 10,
            marginRight: 10,
            color: theme,
          }}
        >
          {props.children}
        </p>
      </div>
    );
};

export default Gradient;
