import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserProvider";

const ProtectedRoute = ({ children }) => {
  const  userid  = localStorage.getItem("userId");

  if (!userid) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
