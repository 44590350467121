import { useContext, useState } from "react";
import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import Footer from "../../components/Footer/Footer.component";
import { useTranslation } from "react-i18next";
import "./About.styles.scss";
import { UserContext } from "../../contexts/UserProvider";
import auBackground from "../../assets/aboutbackground.jpg";
import AppLinks from "../../components/AppLinks/AppLinks.component";


const About = () => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
 
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <img className="contact-image" src={auBackground} />
      </div>
      <div className="about-container">
        <h2>{t("l5")}</h2>
        <p className="about-paragraph">{t("n2")}</p>

        <p className="about-paragraph">{t("n3")}</p>

        <p className="about-paragraph">{t("n4")}</p>

        <p className="about-paragraph">{t("n5")}</p>
      </div>

     
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default About;
