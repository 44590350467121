import "./Contact.styles.scss";
import { useTranslation } from "react-i18next";
import contactBackground from "../../assets/contact.jpg";
import Footer from "../../components/Footer/Footer.component";
import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import Background from "../../components/Background/Background.component";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <Background />
      </div>
      <div className="info">
        <p className="minititle">{t("o1")}</p>
        <p>{t("o2")}</p>
        <p className="minititle">{t("o3")}</p>
        <p>{t("o4")}</p>
        <p>{t("p15")}: support@weedloversdating.com</p>
        <p>{t("o5")}: 1-718-737-3686</p>
        <p>Weed Lovers Dating LLC, New York</p>
        <p className="minititle">{t("o6")}</p>
        <p>{t("o7")}</p>
        <p>{t("p15")}: development@weedloversdating.com</p>
      </div>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default Contact;
