import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserProvider";
import { IoIosArrowForward, IoIosCamera } from "react-icons/io";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import Gradient from "../../components/Gradient/Gradient.component";
import RowInfo from "../../components/RowInfo/RowInfo.component";
import DivNotSelected from "../../components/divNotSelected/divNotSelected.component";
import DivSelected from "../../components/divSelected/divSelected.component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./Profile.styles.scss";
const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    userid,
    theme,
    profilephoto,
    description,
    setDescription,
    ethnicity,
    setEthnicity,
    relationship,
    setRelationship,
    relationgoals,
    setRelationgoals,
    workout,
    setWorkout,
    religion,
    setReligion,
    education,
    setEducation,
    smoking,
    setSmoking,
    marijuana,
    setMarijuana,
    drinking,
    setDrinking,
    photos,
    hobbies,
  } = useContext(UserContext);

  const [showethnicity, setShowethnicity] = useState(false);
  const [showrelation, setShowrelation] = useState(false);
  const [showreligion, setShowreligion] = useState(false);
  const [showeducation, setShoweducation] = useState(false);
  const [showsmoke, setShowsmoke] = useState(false);
  const [showmarijuana, setShowmarijuana] = useState(false);
  const [showdrink, setShowdrink] = useState(false);
  const [showworkout, setShowworkout] = useState(false);
  const [showrelationgoals, setShowrelationgoals] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  function _changeDescription() {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changedescription.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thetext: description,
        }),
      }
    );
  }

  function _changeReligion(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changereligion.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    );
  }

  function _changeRelation(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changerelation.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    );
  }

  function _changeWorkout(thevalue) {
    return fetch("https://marihuanadating.com/endpoint-wld/changeworkout.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        thevalue: thevalue,
      }),
    });
  }

  function _changeRelationgoals(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changerelationgoals.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    );
  }

  function _changeEthnicity(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changeethnicity.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    );
  }

  function _changeEducation(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changeeducation.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    );
  }

  function _changeSmoke(thevalue) {
    return fetch("https://marihuanadating.com/endpoint-wld/changesmoke.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        thevalue: thevalue,
      }),
    });
  }

  function _changeDrink(thevalue) {
    return fetch("https://marihuanadating.com/endpoint-wld/changedrink.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        thevalue: thevalue,
      }),
    });
  }

  function _changeCannabis(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changecannabis.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    );
  }

  function _renderEthnicity() {
    switch (ethnicity) {
      case "1":
        return <p>{t("k1")}</p>;
        break;
      case "2":
        return <p>{t("k2")}</p>;
        break;
      case "3":
        return <p>{t("k3")}</p>;
        break;
      case "4":
        return <p>{t("k4")}</p>;
        break;
      case "5":
        return <p>{t("k5")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderEducation() {
    switch (education) {
      case "1":
        return <p>{t("k6")}</p>;
        break;
      case "2":
        return <p>{t("k7")}</p>;
        break;
      case "3":
        return <p>{t("k8")}</p>;
        break;
      case "4":
        return <p>{t("k9")}</p>;
        break;
      case "5":
        return <p>{t("k10")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderReligion() {
    switch (religion) {
      case "1":
        return <p>{t("k11")}</p>;
        break;
      case "2":
        return <p>{t("k12")}</p>;
        break;
      case "3":
        return <p>{t("k13")}</p>;
        break;
      case "4":
        return <p>{t("k14")}</p>;
        break;
      case "5":
        return <p>{t("k15")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderWorkout() {
    switch (workout) {
      case "1":
        return <p>{t("k36")}</p>;
        break;
      case "2":
        return <p>{t("k37")}</p>;
        break;
      case "3":
        return <p>{t("k30")}</p>;
        break;
      case "4":
        return <p>{t("k29")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderRelationgoals() {
    switch (relationgoals) {
      case "1":
        return <p>{t("k38")}</p>;
        break;
      case "2":
        return <p>{t("k39")}</p>;
        break;
      case "3":
        return <p>{t("k40")}</p>;
        break;
      case "4":
        return <p>{t("k41")}</p>;
        break;
      case "5":
        return <p>{t("k42")}</p>;
        break;
      case "6":
        return <p>{t("k43")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderRelation() {
    switch (relationship) {
      case "1":
        return <p>{t("k16")}</p>;
        break;
      case "2":
        return <p>{t("k17")}</p>;
        break;
      case "3":
        return <p>{t("k18")}</p>;
        break;
      case "4":
        return <p>{t("k19")}</p>;
        break;
      case "5":
        return <p>{t("k20")}</p>;
        break;
      case "6":
        return <p>{t("k21")}</p>;
        break;
      case "7":
        return <p>{t("k22")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }
  function _renderSmoke() {
    switch (smoking) {
      case "1":
        return <p>{t("n1")}</p>;
        break;
      case "2":
        return <p>{t("k23")}</p>;
        break;
      case "3":
        return <p>{t("k24")}</p>;
        break;
      case "4":
        return <p>{t("k25")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderDrink() {
    switch (drinking) {
      case "1":
        return <p>{t("k26")}</p>;
        break;
      case "2":
        return <p>{t("k27")}</p>;
        break;
      case "3":
        return <p>{t("k28")}</p>;
        break;
      case "4":
        return <p>{t("k29")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function _renderCannabis() {
    switch (marijuana) {
      case "1":
        return <p>{t("n1")}</p>;
        break;
      case "2":
        return <p>{t("k30")}</p>;
        break;
      case "3":
        return <p>{t("y1")}</p>;
        break;
      default:
        return <p style={{ color: "red", fontSize: 14 }}>{t("z90")}</p>;
    }
  }

  function renderHobby(item) {
    switch (item) {
      case "1":
        return <p style={{ color: theme }}>{t("h1")}</p>;
        break;
      case "2":
        return <p style={{ color: theme }}>{t("h2")}</p>;
        break;
      case "3":
        return <p style={{ color: theme }}>{t("h3")}</p>;
        break;
      case "4":
        return <p style={{ color: theme }}>{t("h4")}</p>;
        break;
      case "5":
        return <p style={{ color: theme }}>{t("h5")}</p>;
        break;
      case "6":
        return <p style={{ color: theme }}>{t("h6")}</p>;
        break;
      case "7":
        return <p style={{ color: theme }}>{t("h7")}</p>;
        break;
      case "8":
        return <p style={{ color: theme }}>{t("h8")}</p>;
        break;
      case "9":
        return <p style={{ color: theme }}>{t("h9")}</p>;
        break;
      case "10":
        return <p style={{ color: theme }}>{t("h10")}</p>;
        break;
      case "11":
        return <p style={{ color: theme }}>{t("h11")}</p>;
        break;
      case "12":
        return <p style={{ color: theme }}>{t("h12")}</p>;
        break;
      case "13":
        return <p style={{ color: theme }}>{t("h13")}</p>;
        break;
      case "14":
        return <p style={{ color: theme }}>{t("h14")}</p>;
        break;
      case "15":
        return <p style={{ color: theme }}>{t("h15")}</p>;
        break;
      case "16":
        return <p style={{ color: theme }}>{t("h16")}</p>;
        break;
      case "17":
        return <p style={{ color: theme }}>{t("h17")}</p>;
        break;
      case "18":
        return <p style={{ color: theme }}>{t("h18")}</p>;
        break;
      case "19":
        return <p style={{ color: theme }}>{t("h19")}</p>;
        break;
      case "20":
        return <p style={{ color: theme }}>{t("h20")}</p>;
        break;
      case "21":
        return <p style={{ color: theme }}>{t("h21")}</p>;
        break;
      case "22":
        return <p style={{ color: theme }}>{t("h22")}</p>;
        break;
      case "23":
        return <p style={{ color: theme }}>{t("h23")}</p>;
        break;
      case "24":
        return <p style={{ color: theme }}>{t("h24")}</p>;
        break;
      case "25":
        return <p style={{ color: theme }}>{t("h25")}</p>;
        break;
      case "26":
        return <p style={{ color: theme }}>{t("h26")}</p>;
        break;
      case "27":
        return <p style={{ color: theme }}>{t("h27")}</p>;
        break;
      case "28":
        return <p style={{ color: theme }}>{t("h28")}</p>;
        break;
      case "29":
        return <p style={{ color: theme }}>{t("h29")}</p>;
        break;
      case "30":
        return <p style={{ color: theme }}>{t("h30")}</p>;
        break;
      case "31":
        return <p style={{ color: theme }}>{t("h31")}</p>;
        break;
      case "32":
        return <p style={{ color: theme }}>{t("h32")}</p>;
        break;
      case "33":
        return <p style={{ color: theme }}>{t("h33")}</p>;
        break;
      case "34":
        return <p style={{ color: theme }}>{t("h34")}</p>;
        break;
      case "35":
        return <p style={{ color: theme }}>{t("h35")}</p>;
        break;
      case "36":
        return <p style={{ color: theme }}>{t("h36")}</p>;
        break;
    }
  }
  return (
    <div className="app-container">
      <div className="mobile-container">
        <div style={{ flex: 1 }}>
          {isFullScreen ? (
              <div
                className="fullScreenContainer"
                onClick={handleExitFullScreen}
              >
                <img
                   src={`https://www.marihuanadating.com/wld_uploads/${tempimg}`}
                  className="fullScreenImage"
                />
              </div>
          ) : null}

          <div style={{ backgroundColor: "#ddd" }}>
            <div style={{ position: "relative" }}>
              <img
                style={{ width: "100%", height: "auto" }}
                src={`https://www.marihuanadating.com/wld_uploads/${profilephoto}`}
              />
              <div
                onClick={() => navigate("/profile/add-profile-photo")}
                style={{
                  position: "absolute",
                  right: 10,
                  bottom: 40,
                  backgroundColor: "#1f1f1f",
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <IoIosCamera color={theme} size={25} />
              </div>
            </div>

            <div
              style={{
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
                marginTop: -30,
                backgroundColor: "#1f1f1f",
              }}
            ></div>

            <div className="maindiv">
              <div className="border">
                <p className="titlerow">{t("k44")}</p>
                <textarea
                  rows={4}
                  maxLength={400}
                  placeholder={t("z91")}
                  value={description}
                  className="descriptiontext"
                  onBlur={(text) => _changeDescription()}
                  onChange={(text) => setDescription(text.target.value)}
                  style={{ border: "none", width: "100%", color: theme }}
                />
              </div>
              <Gradient>
                <p className="maintext">{t("k45")}</p>
              </Gradient>
              <div className="border">
                <p className="titlerow">{t("k50")}</p>
                <RowInfo
                  title={_renderEthnicity()}
                  onClick={() => setShowethnicity(!showethnicity)}
                  showoption={showethnicity}
                />
              </div>
              {showethnicity ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {ethnicity == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k1")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEthnicity(JSON.stringify(1));
                        _changeEthnicity(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k1")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {ethnicity == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k2")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEthnicity(JSON.stringify(2));
                        _changeEthnicity(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k2")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {ethnicity == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k3")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEthnicity(JSON.stringify(3));
                        _changeEthnicity(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k3")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {ethnicity == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k4")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEthnicity(JSON.stringify(4));
                        _changeEthnicity(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k4")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {ethnicity == 5 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k5")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEthnicity(JSON.stringify(5));
                        _changeEthnicity(5);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k5")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k51")}</p>
                <RowInfo
                  title={_renderEducation()}
                  onClick={() => setShoweducation(!showeducation)}
                  showoption={showeducation}
                />
              </div>
              {showeducation ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {education == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k6")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEducation(JSON.stringify(1));
                        _changeEducation(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k6")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {education == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k7")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEducation(JSON.stringify(2));
                        _changeEducation(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k7")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {education == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k8")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEducation(JSON.stringify(3));
                        _changeEducation(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k8")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {education == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k9")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEducation(JSON.stringify(4));
                        _changeEducation(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k9")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {education == 5 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k10")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setEducation(JSON.stringify(5));
                        _changeEducation(5);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k10")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k52")}</p>

                <RowInfo
                  title={_renderReligion()}
                  onClick={() => setShowreligion(!showreligion)}
                  showoption={showreligion}
                />
              </div>
              {showreligion ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {religion == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k11")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setReligion(JSON.stringify(1));
                        _changeReligion(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k11")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {religion == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k12")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setReligion(JSON.stringify(2));
                        _changeReligion(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k12")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {religion == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k13")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setReligion(JSON.stringify(3));
                        _changeReligion(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k13")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {religion == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k14")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setReligion(JSON.stringify(4));
                        _changeReligion(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k14")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {religion == 5 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k15")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setReligion(JSON.stringify(5));
                        _changeReligion(5);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k15")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k53")}</p>
                <RowInfo
                  title={_renderRelation()}
                  onClick={() => setShowrelation(!showrelation)}
                  showoption={showrelation}
                />
              </div>
              {showrelation ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {relationship == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k16")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(1));
                        _changeRelation(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k16")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationship == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k17")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(2));
                        _changeRelation(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k17")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationship == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k18")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(3));
                        _changeRelation(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k18")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationship == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k19")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(4));
                        _changeRelation(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k19")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationship == 5 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k20")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(5));
                        _changeRelation(5);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k20")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationship == 6 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k21")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(6));
                        _changeRelation(6);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k21")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationship == 7 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k22")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationship(JSON.stringify(7));
                        _changeRelation(7);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k22")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k54")}</p>
                <RowInfo
                  title={_renderRelationgoals()}
                  onClick={() => setShowrelationgoals(!showrelationgoals)}
                  showoption={showrelationgoals}
                />
              </div>

              {showrelationgoals ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {relationgoals == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k38")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationgoals(JSON.stringify(1));
                        _changeRelationgoals(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k38")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationgoals == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k39")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationgoals(JSON.stringify(2));
                        _changeRelationgoals(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k39")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationgoals == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k40")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationgoals(JSON.stringify(3));
                        _changeRelationgoals(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k40")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationgoals == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k41")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationgoals(JSON.stringify(4));
                        _changeRelationgoals(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k41")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationgoals == 5 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k42")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationgoals(JSON.stringify(5));
                        _changeRelationgoals(5);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k42")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {relationgoals == 6 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k43")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setRelationgoals(JSON.stringify(6));
                        _changeRelationgoals(6);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k43")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <Gradient>
                <p className="maintext">{t("k46")}</p>
              </Gradient>

              <div className="border">
                <p className="titlerow">{t("k56")}</p>
                <RowInfo
                  title={_renderWorkout()}
                  onClick={() => setShowworkout(!showworkout)}
                  showoption={showworkout}
                />
              </div>

              {showworkout ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {workout == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k36")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setWorkout(JSON.stringify(1));
                        _changeWorkout(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k36")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {workout == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k37")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setWorkout(JSON.stringify(2));
                        _changeWorkout(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k37")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {workout == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k30")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setWorkout(JSON.stringify(3));
                        _changeWorkout(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k30")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {workout == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k29")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setWorkout(JSON.stringify(4));
                        _changeWorkout(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k29")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k57")}</p>
                <RowInfo
                  title={_renderSmoke()}
                  onClick={() => setShowsmoke(!showsmoke)}
                  showoption={showsmoke}
                />
              </div>
              {showsmoke ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {smoking == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("n1")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSmoking(JSON.stringify(1));
                        _changeSmoke(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("n1")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {smoking == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k23")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSmoking(JSON.stringify(2));
                        _changeSmoke(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k23")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {smoking == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k24")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSmoking(JSON.stringify(3));
                        _changeSmoke(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k24")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {smoking == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k25")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSmoking(JSON.stringify(4));
                        _changeSmoke(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k25")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k58")}</p>
                <RowInfo
                  title={_renderDrink()}
                  onClick={() => setShowdrink(!showdrink)}
                  showoption={showdrink}
                />
              </div>
              {showdrink ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {drinking == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k26")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setDrinking(JSON.stringify(1));
                        _changeDrink(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k26")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {drinking == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k27")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setDrinking(JSON.stringify(2));
                        _changeDrink(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k27")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {drinking == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k28")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setDrinking(JSON.stringify(3));
                        _changeDrink(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k28")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {drinking == 4 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k29")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setDrinking(JSON.stringify(4));
                        _changeDrink(4);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k29")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <p className="titlerow">{t("k59")}</p>
                <RowInfo
                  title={_renderCannabis()}
                  onClick={() => setShowmarijuana(!showmarijuana)}
                  showoption={showmarijuana}
                />
              </div>
              {showmarijuana ? (
                <div
                  style={{
                    flex: 1,
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    marginBottom: 10,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {marijuana == 1 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("n1")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setMarijuana(JSON.stringify(1));
                        _changeCannabis(1);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("n1")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {marijuana == 2 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("k30")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setMarijuana(JSON.stringify(2));
                        _changeCannabis(2);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("k30")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                  {marijuana == 3 ? (
                    <div>
                      <DivSelected>
                        <p className="selectedText">{t("y1")}</p>
                      </DivSelected>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setMarijuana(JSON.stringify(3));
                        _changeCannabis(3);
                      }}
                    >
                      <DivNotSelected>
                        <p className="notselectedText" style={{ color: theme }}>
                          {t("y1")}
                        </p>
                      </DivNotSelected>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="border">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginVertical: 10,
                  }}
                >
                  <p className="titlerow">{t("k60")}</p>

                  <div onClick={() => navigate("/profile/hobbies")}>
                    <IoIosArrowForward
                      color={theme}
                      size={45}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                {hobbies.length == 0 ? (
                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: 14,
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    {t("z90")}
                  </p>
                ) : (
                  <div
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      display: "flex",
                    }}
                  >
                    {hobbies.map((item, key) => (
                      <DivNotSelected
                        key={key}
                        style={{
                          color: theme,
                          fontSize: 20,
                          padding: 7,
                          borderColor: theme,
                          borderStyle: "solid",
                          borderRadius: 10,
                          borderWidth: 1,
                          marginTop: 5,
                          marginBottom: 5,
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        {renderHobby(item)}
                      </DivNotSelected>
                    ))}
                  </div>
                )}
              </div>
              <div className="border">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginVertical: 10,
                  }}
                >
                  <p className="titlerow">{t("k61")}</p>

                  <div onClick={() => navigate("/profile/photo-gallery")}>
                    <IoIosArrowForward
                      color={theme}
                      size={45}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  {photos == "" ? (
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: 14,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      {t("z90")}
                    </p>
                  ) : (
                    photos.map((item, key) => (
                      <div
                        key={key}
                        style={{
                          width: "30%",
                          aspectRatio: 1,
                          margin: "1.66%",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{ flex: 1 }}
                          onClick={() => {
                            handleThumbnailPress();
                            setTempimg(item.thepath);
                          }}
                        >
                          <img
                            style={{
                              flex: 1,
                              objectFit: "cover",
                              display: "flex",
                              width: "100%",
                              height: 150,
                            }}
                            src={`https://www.marihuanadating.com/wld_uploads/${item.thepath}`}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default Profile;
