import React, { useEffect, useContext, useState } from "react";
import Button from "../../components/Button/Button.component";
import { Outlet, Link } from "react-router-dom";
import Modal from "../../components/Modal/Modal.component";
import ModalLogin from "../../components/ModalLogin/ModalLogin.component";
import ThemeEditor from "../../components/ThemeEditor/ThemeEditor.component";
import { Fragment } from "react";
import Logo from "../../assets/oneicon.png";
import { MdLanguage } from "react-icons/md";
import { MdColorLens } from "react-icons/md";
import "./navigation.styles.scss";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import ModalRegister from "../../components/ModalRegister/ModalRegister.component";

const Navigation = () => {
  const { t } = useTranslation();
  const {
    theme,
    loginpanel,
    setLoginPanel,
    themepanel,
    setThemePanel,
    languagepanel,
    setLanguagePanel,
    registerpanel,
    setRegisterPanel,
    setShowReset,
    setShowBox,
  } = useContext(UserContext);
  const [scrolling, setScrolling] = useState(false);

  const toggleThemeEditor = () => {
    setThemePanel(false);
    setShowBox(true);
  };

  const openThemeEditor = () => {
    setThemePanel(true);
    setShowBox(false);
    setRegisterPanel(false);
    setLoginPanel(false);
    setLanguagePanel(false);
  };

  const openModal = () => {
    setLanguagePanel(true);
    setShowBox(false);
    setThemePanel(false);
    setRegisterPanel(false);
    setLoginPanel(false);
  };

  const closeModal = () => {
    setLanguagePanel(false);
    setShowBox(true);
  };

  const openModalLogin = () => {
    setLoginPanel(true);
    setShowBox(false);
    setLanguagePanel(false);
    setThemePanel(false);
    setRegisterPanel(false);
  };

  const closeModalLogin = () => {
    setLoginPanel(false);
    setShowReset(false);
    setShowBox(true);
  };

  const openModalRegister = () => {
    setRegisterPanel(true);
    setShowBox(false);
    setLanguagePanel(false);
    setLoginPanel(false);
    setThemePanel(false);
  };

  const closeModalRegister = () => {
    setRegisterPanel(false);
    setShowBox(true);
  };

  useEffect(() => {
    // Add a scroll event listener to the window
    const handleScroll = () => {
      // Calculate the scroll position in pixels
      const scrollY = window.scrollY;

      // Determine whether to add or remove the "hidden" class based on scroll position
      if (scrollY >= window.innerHeight * 0.3) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <div className={`navigation ${scrolling ? "hidden" : ""}`}>
        <Link className="logo-container" to="/">
          <img src={Logo} className="logo" alt="Logo" />
          <p className="logotext" style={{ color: "green" }}>
            Weed <span style={{ color: "red" }}>Lovers</span> Dating
          </p>
        </Link>
        <div className="nav-links-container">
          <div
            className="nav-link"
            style={{ alignItems: "center", display: "flex" }}
            onClick={openThemeEditor}
          >
            <MdColorLens size={20} /> <p>{t("o27")}</p>
          </div>
          <div
            className="nav-link"
            style={{ alignItems: "center", display: "flex" }}
            onClick={openModal}
          >
            <MdLanguage size={20} /> <p>{t("l3")}</p>
          </div>
          <Button
            text={t("l1")}
            class="login-button"
            backgroundcolor={"#fff"}
            color={theme}
            onClick={openModalLogin}
            type="button"
          />
          <Button
            text={t("l2")}
            class="register-button"
            backgroundcolor={theme}
            onClick={openModalRegister}
            color="#fff"
          />
          <div className="nav-link"></div>
        </div>
      </div>
      <Modal isOpen={languagepanel} closeModal={closeModal} />
      <ModalLogin isOpenLogin={loginpanel} closeModalLogin={closeModalLogin} />
      <ModalRegister
        isOpenRegister={registerpanel}
        closeModalRegister={closeModalRegister}
      />
      <ThemeEditor
        isPanelOpen={themepanel}
        toggleThemeEditor={toggleThemeEditor}
      />
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
