import { useContext } from "react";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserProvider";
import Empty from "../../components/Empty/Empty.component";
import SearchInput from "../../components/SearchInput/SearchInput.component";
import MessageRow from "../../components/MessageRow/MessageRow.component";
import queryString from "query-string";
import "./FullChat.styles.scss";
const FullChat = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    conversations,
    filteredConversations,
    userid,
    theme,
    removeConversation,
    removeConversationFromFilteredConversations,
    filterConversations,
  } = useContext(UserContext);

  function deleteConversation(item) {
    removeConversation(item);
    removeConversationFromFilteredConversations(item);
    return fetch(
      "https://marihuanadating.com/endpoint-wld/deleteconversationnew.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          theuser: item.id,
          convid: item.conversation_id,
        }),
      }
    );
  }



  const SearchFilterFunction = (text) => {
    filterConversations(text);
  };

  if (conversations == "") {
    return (
      <div className="app-container">
        <div className="mobile-container">
          <Header title={t("f1")} />
          <Empty>{t("f2")}</Empty>
          <BottomNavigation />
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("f1")} />
        <SearchInput onSearchFilterFunction={SearchFilterFunction} />
        <div>
          {filteredConversations.map((item, key) => (
            <div key={key}>
              <MessageRow
                image={item.profile_photo}
                first_name={item.first_name}
                last_name={item.last_name}
                id={item.id}
                message={item.message}
                thetime={item.thetime}
                onNavigate={() => {
                  navigate(`/chat/${item.first_name + "." + item.last_name}`, {
                    state: item,
                  });
                }}
                onDelete={() => {
                  deleteConversation(item);
                }}
              />
            </div>
          ))}
        </div>
        <div style={{ height: "50px" }}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default FullChat;
