import React, { useContext } from "react";
import "./Hobbies.styles.scss";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import DivNotSelected from "../../components/divNotSelected/divNotSelected.component";
import DivSelected from "../../components/divSelected/divSelected.component";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";

const Hobbies = () => {
  const { t } = useTranslation();
  const { hobbies, addNewHobby, removeTheHobby, userid, theme } = useContext(
    UserContext
  );

  const removeselected = (e) => {
    removeTheHobby(e);
  };

  const addselected = (e) => {
    addNewHobby(e);
  };

  React.useEffect(() => {
    return () => {
      updatehobbies();
    };
  }, [hobbies]);

  function updatehobbies() {
    return fetch("https://marihuanadating.com/endpoint-wld/updatehobbies.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        thehobbies: hobbies,
      }),
    });
  }

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("k60")} hasgoback={true}/>
        <div className="selectionContainer">
          {hobbies.includes("1") ? (
            <div onClick={() => removeselected("1")}>
              <DivSelected>
                <p className="selectedText">{t("h1")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("1")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h1")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("2") ? (
            <div onClick={() => removeselected("2")}>
              <DivSelected>
                <p className="selectedText">{t("h2")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("2")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h2")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("3") ? (
            <div onClick={() => removeselected("3")}>
              <DivSelected>
                <p className="selectedText">{t("h3")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("3")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h3")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("4") ? (
            <div onClick={() => removeselected("4")}>
              <DivSelected>
                <p className="selectedText">{t("h4")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("4")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h4")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("5") ? (
            <div onClick={() => removeselected("5")}>
              <DivSelected>
                <p className="selectedText">{t("h5")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("5")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h5")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("6") ? (
            <div onClick={() => removeselected("6")}>
              <DivSelected>
                <p className="selectedText">{t("h6")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("6")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h6")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("7") ? (
            <div onClick={() => removeselected("7")}>
              <DivSelected>
                <p className="selectedText">{t("h7")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("7")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h7")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("8") ? (
            <div onClick={() => removeselected("8")}>
              <DivSelected>
                <p className="selectedText">{t("h8")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("8")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h8")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("9") ? (
            <div onClick={() => removeselected("9")}>
              <DivSelected>
                <p className="selectedText">{t("h9")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("9")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h9")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("10") ? (
            <div onClick={() => removeselected("10")}>
              <DivSelected>
                <p className="selectedText">{t("h10")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("10")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h10")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("11") ? (
            <div onClick={() => removeselected("11")}>
              <DivSelected>
                <p className="selectedText">{t("h11")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("11")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h11")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("12") ? (
            <div onClick={() => removeselected("12")}>
              <DivSelected>
                <p className="selectedText">{t("h12")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("12")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h12")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("13") ? (
            <div onClick={() => removeselected("13")}>
              <DivSelected>
                <p className="selectedText">{t("h13")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("13")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h13")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("14") ? (
            <div onClick={() => removeselected("14")}>
              <DivSelected>
                <p className="selectedText">{t("h14")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("14")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h14")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("15") ? (
            <div onClick={() => removeselected("15")}>
              <DivSelected>
                <p className="selectedText">{t("h15")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("15")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h15")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("16") ? (
            <div onClick={() => removeselected("16")}>
              <DivSelected>
                <p className="selectedText">{t("h16")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("16")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h16")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("17") ? (
            <div onClick={() => removeselected("17")}>
              <DivSelected>
                <p className="selectedText">{t("h17")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("17")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h17")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("18") ? (
            <div onClick={() => removeselected("18")}>
              <DivSelected>
                <p className="selectedText">{t("h18")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("18")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h18")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("19") ? (
            <div onClick={() => removeselected("19")}>
              <DivSelected>
                <p className="selectedText">{t("h19")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("19")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h19")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("20") ? (
            <div onClick={() => removeselected("20")}>
              <DivSelected>
                <p className="selectedText">{t("h20")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("20")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h20")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("21") ? (
            <div onClick={() => removeselected("21")}>
              <DivSelected>
                <p className="selectedText">{t("h21")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("21")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h21")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("22") ? (
            <div onClick={() => removeselected("22")}>
              <DivSelected>
                <p className="selectedText">{t("h22")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("22")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h22")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("23") ? (
            <div onClick={() => removeselected("23")}>
              <DivSelected>
                <p className="selectedText">{t("h23")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("23")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h23")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("24") ? (
            <div onClick={() => removeselected("24")}>
              <DivSelected>
                <p className="selectedText">{t("h24")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("24")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h24")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("25") ? (
            <div onClick={() => removeselected("25")}>
              <DivSelected>
                <p className="selectedText">{t("h25")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("25")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h25")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("26") ? (
            <div onClick={() => removeselected("26")}>
              <DivSelected>
                <p className="selectedText">{t("h26")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("26")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h26")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("27") ? (
            <div onClick={() => removeselected("27")}>
              <DivSelected>
                <p className="selectedText">{t("h27")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("27")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h27")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("28") ? (
            <div onClick={() => removeselected("28")}>
              <DivSelected>
                <p className="selectedText">{t("h28")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("28")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h28")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("29") ? (
            <div onClick={() => removeselected("29")}>
              <DivSelected>
                <p className="selectedText">{t("h29")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("29")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h29")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("30") ? (
            <div onClick={() => removeselected("30")}>
              <DivSelected>
                <p className="selectedText">{t("h30")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("30")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h30")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("31") ? (
            <div onClick={() => removeselected("31")}>
              <DivSelected>
                <p className="selectedText">{t("h31")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("31")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h31")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("32") ? (
            <div onClick={() => removeselected("32")}>
              <DivSelected>
                <p className="selectedText">{t("h32")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("32")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h32")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("33") ? (
            <div onClick={() => removeselected("33")}>
              <DivSelected>
                <p className="selectedText">{t("h33")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("33")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h33")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("34") ? (
            <div onClick={() => removeselected("34")}>
              <DivSelected>
                <p className="selectedText">{t("h34")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("34")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h34")}{" "}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("35") ? (
            <div onClick={() => removeselected("35")}>
              <DivSelected>
                <p className="selectedText">{t("h35")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("35")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h35")}
                </p>
              </DivNotSelected>
            </div>
          )}
          {hobbies.includes("36") ? (
            <div onClick={() => removeselected("36")}>
              <DivSelected>
                <p className="selectedText">{t("h36")}</p>
              </DivSelected>
            </div>
          ) : (
            <div onClick={() => addselected("36")}>
              <DivNotSelected>
                <p className="notSelectedText" style={{ color: theme }}>
                  {t("h36")}
                </p>
              </DivNotSelected>
            </div>
          )}
        </div>
        <div style={{height:'50px'}}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default Hobbies;
