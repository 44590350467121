import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import { IoIosTrash } from "react-icons/io";
import moment from "moment";

const MessageRow = (props) => {
  const { messagesNotifications, theme } = useContext(UserContext);

  return (
    <div>
      {messagesNotifications.includes(props.id) ? (
        <div className="userBar" style={{ backgroundColor: theme }}>
          <div className="partplusbutton">
            <div className="imageandtext">
              <img
                onClick={props.onNavigate}
                className="userPic"
                src={`https://marihuanadating.com/wld_uploads/${props.image}`}
              />
              <div style={{ width: "80%", marginLeft: "10px" }}>
                <p
                  className="name"
                  style={{ color: "#fff" }}
                  onClick={props.onNavigate}
                >
                  {props.first_name} {props.last_name}
                </p>
                <p className="lastmessagetext" style={{ color: "#fff" }}>
                  {props.message}
                </p>
                <p className="thedate" style={{ color: "#fff" }}>
                  {moment(props.thetime * 1000).format("MMMM Do YYYY")}
                </p>
              </div>
            </div>

            <div
              style={{
                marginLeft: 10,
                marginRight: 10,
                marginTop: 15,
                cursor: "pointer",
              }}
              onClick={props.onDelete}
            >
              <IoIosTrash size={30} color={"#fff"} />
            </div>
          </div>
        </div>
      ) : (
        <div className="userBar">
          <div className="partplusbutton">
            <div className="imageandtext">
              <img
                onClick={props.onNavigate}
                className="userPic"
                src={`https://marihuanadating.com/wld_uploads/${props.image}`}
              />
              <div style={{ width: "80%", marginLeft: "10px" }}>
                <p
                  style={{ color: theme }}
                  className="name"
                  onClick={props.onNavigate}
                >
                  {props.first_name} {props.last_name}
                </p>
                <p className="lastmessagetext" style={{ color: theme }}>
                  {props.message}
                </p>
                <p style={{ color: theme }} className="thedate">
                  {moment(props.thetime * 1000).format("MMMM Do YYYY")}
                </p>
              </div>
            </div>

            <div
              style={{
                marginLeft: 10,
                marginRight: 10,
                marginTop: 15,
                cursor: "pointer",
              }}
              onClick={props.onDelete}
            >
              <IoIosTrash size={30} color={theme} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageRow;
