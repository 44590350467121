import { useContext, useState, useEffect } from "react";
import "./Home.styles.scss";
import homeBackground from "../../assets/home_background.jpg";
import Footer from "../../components/Footer/Footer.component";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import TopMembers from "../../components/Top Members/TopMembers.component";
import Testimoials from "../../components/Testimonials/Testimonials.component";
import HomeDate from "../../components/HomeDate/HomeDate.component";
import HomeSpecifications from "../../components/HomeSpecifications/HomeSpecifications.component";
import HomeBox from "../../components/HomeBox/HomeBox.component";

const Home = () => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
  const [showCookie, setShowCookie] = useState(false);
  const [showdisclaimer, setShowdisclaimer] = useState(false);
  const [showdisclaimer2, setShowdisclaimer2] = useState(false);

  useEffect(() => {
    // Set showBox to true after component mounts to trigger animation
    setShowCookie(true);
  }, []);

  const handleGDPR = () => {
    setShowCookie(false);
    setShowdisclaimer(true);
  };

  const handleDisclaimer = () => {
    setShowdisclaimer(false);
    setShowdisclaimer2(true);
  };

  const handleDisclaimer2 = () => {
    setShowdisclaimer2(false);
  };

  const handleLeave = () => {
    setShowdisclaimer(false);
    window.location.href = "https://www.google.com";
  };

  const handleLeave2 = () => {
    setShowdisclaimer2(false);
    window.location.href = "https://www.google.com";
  };

  return (
    <div>
      <div className="container">
        <div className="gradient-overlay"></div>
        <img className="image" src={homeBackground} />
        <HomeBox />
      </div>
      <HomeDate />
      <HomeSpecifications />
      <TopMembers />
      <Testimoials />
      <AppLinks />
      <Footer />

      <div
        style={{ background: "#000", position: "fixed", bottom: 0 }}
        className={`cookiesnotice ${showCookie ? "showcookie" : ""}`}
      >
        <p className="leavetext">{t("gdpr1")}</p>
        <p className="leavetext">{t("gdpr2")}</p>
        <div style={{ marginBottom: 50, textAlign: "right" }}>
          <p
            onClick={handleLeave}
            className="leavebutton"
            style={{
              color: theme,
            }}
          >
            {t("dt4")}
          </p>
          <p
            onClick={handleGDPR}
            className="leavebutton"
            style={{
              color: theme,
            }}
          >
            {t("a7")}
          </p>
        </div>
      </div>

      <div
        style={{ background: "#000", position: "fixed", bottom: 0 }}
        className={`disclaimernotice ${showdisclaimer ? "showdisclaimer" : ""}`}
      >
        <p className="leavetext">{t("dt1")}</p>

        <div style={{ marginBottom: 50, textAlign: "right" }}>
          <p
            onClick={handleLeave}
            className="leavebutton"
            style={{
              color: theme,
            }}
          >
            {t("dt9")}
          </p>
          <p
            onClick={handleDisclaimer}
            className="leavebutton"
            style={{
              color: theme,
            }}
          >
            {t("dt8")}
          </p>
        </div>
      </div>

      <div
        style={{ background: "#000", position: "fixed", bottom: 0 }}
        className={`disclaimernotice2 ${
          showdisclaimer2 ? "showdisclaimer2" : ""
        }`}
      >
        <p className="leavetext">{t("dt2")}</p>
        <div style={{ marginBottom: 50, textAlign: "right" }}>
          <p
            onClick={handleLeave2}
            className="leavebutton"
            style={{
              color: theme,
            }}
          >
            {t("dt4")}
          </p>
          <p
            onClick={handleDisclaimer2}
            className="leavebutton"
            style={{
              color: theme,
            }}
          >
            {t("dt3")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
