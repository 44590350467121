import { useContext } from "react";
import "./TopMembers.css";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import stelute from "../../assets/stelute.jpg";
const TopMembers = (props) => {
  const { theme } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <div className="forth-chapter">
      <p className="topmembers">
        {t("l16")} <span style={{ color: theme }}>{t("l17")}</span>
      </p>
      <img src={stelute} />
      <p className="allusers" style={{ color: theme }}>
        {t("l18")}
      </p>
    </div>
  );
};

export default TopMembers;
