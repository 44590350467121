
const ChatMessages = ({ messages, thesender, thephoto }) => {
  if (!messages) return null;

  return messages.map((message) => {
    const isSender = message.user._id === thesender;

    if (isSender) {
      return (
        <div key={message._id} className="chat__message--sender">
          <p className="chat__text">{message.text}</p>

          <span className="chat__timestamp">
            {message.createdAt.toLocaleString()}
          </span>
        </div>
      );
    } else {
      return (
        <div className="receiverDiv">
          <img
            className="userPicChat"
            src={`https://marihuanadating.com/wld_uploads/${thephoto}`}
          />
          <div key={message._id} className="chat__message--receiver">
            <p className="chat__text">{message.text}</p>

            <span className="chat__timestamp">
              {message.createdAt.toLocaleString()}
            </span>
          </div>
        </div>
      );
    }
  });
};

export default ChatMessages;
