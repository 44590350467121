import { useContext } from "react";
import "./Background.css";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import female from "../../assets/female-avatar.png";
import male from "../../assets/male-avatar.png";
import people from "../../assets/people.png";
const Background = (props) => {
  const { theme } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div className="containerwholepage">
      <div id="leftside">
        <div id="theball"></div>

        <p className="backquote">{t("l41")}</p>
        <img src={people} className="peopleimage" />
        <p className="backnumber">
          <span style={{ color: theme }}>23, 793+</span> {t("l42")}
        </p>
        <div className="sir">
          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#6B3FA0" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#ed4167" }}
          />
          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#3ae346" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#0099a7" }}
          />
          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#ff7474" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#40E0D0" }}
          />
          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#FF7F50" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#9966CC" }}
          />
          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#50C878" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#0F52BA" }}
          />

          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#B76E79" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#FFC512" }}
          />
          <img
            src={female}
            className="sirimage"
            style={{ backgroundColor: "#39FF14" }}
          />
          <img
            src={male}
            className="sirimage"
            style={{ backgroundColor: "#E0115F" }}
          />
        </div>
      </div>
      <div className="rightsideplus">
        <div class="half-circle"></div>
      </div>
    </div>
  );
};

export default Background;
