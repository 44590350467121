import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import Button from "../../components/Button/Button.component";
import InputWithValidation from "../../components/InputWithValidation/InputWithValidation.component";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import { IoEyeSharp } from "react-icons/io5";

const ChangePass = ({ route, navigation }) => {
  const { userid, email, theme } = useContext(UserContext);
  const { t } = useTranslation();
  const [passerror1, setpasserror1] = React.useState("");
  const [passerror2, setpasserror2] = React.useState("");
  const [passmessage, setpassmessage] = React.useState("");
  const [newpass, setnewpass] = React.useState("");
  const [confirmnewpass, setconfirmnewpass] = React.useState("");
  const [secureTextEntry1, setsecureTextEntry1] = React.useState(true);
  const [secureTextEntry2, setsecureTextEntry2] = React.useState(true);

  function renderPasswordAccessory1() {
    let name = secureTextEntry1 ? "visibility" : "visibility-off";

    return (
      <IoEyeSharp
        size={24}
        name={name}
        color={theme}
        onPress={onAccessoryPress1}
      />
    );
  }

  function onAccessoryPress1() {
    setsecureTextEntry1(!secureTextEntry1);
  }

  function onChangeText1(text) {
    setnewpass(text);
    setpasserror1("");
  }

  function renderPasswordAccessory2() {
    let name = secureTextEntry2 ? "visibility" : "visibility-off";

    return (
      <IoEyeSharp
        size={24}
        name={name}
        color={theme}
        onPress={onAccessoryPress2}
      />
    );
  }

  function onAccessoryPress2() {
    setsecureTextEntry2(!secureTextEntry2);
  }

  function onChangeText2(text) {
    setconfirmnewpass(text);
    setpasserror2("");
  }

  function _updatePass() {
    if (newpass == "") {
      setpasserror1(t("p1"));
    } else if (confirmnewpass == "") {
      setpasserror2(t("p2"));
    } else if (newpass !== confirmnewpass) {
      setpasserror1(t("p3"));
      setpasserror2(t("p3"));
    } else {
      return fetch(
        "https://marihuanadating.com/endpoint-wld/changepassword.php",
        {
          method: "post",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            id: userid,
            newpass: newpass,
          }),
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          setpassmessage(responseJson);

          setTimeout(() => {
            setpasserror1("");
            setpasserror2("");
            setpassmessage("");
            setnewpass("");
            setconfirmnewpass("");
          }, 2000);
        });
    }
  }

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("z23")} hasgoback={true} />
        <div style={{ flex: 1 }}>
          <div
            style={{
              flex: 1,
              width: "80%",
              marginLeft: "10%",
              marginRight: "10%",
              marginTop: 50,
            }}
          >
            <div contentContainerStyle={{ flex: 1, justifyContent: "center" }}>
              <div style={{ marginTop: 12, alignItems: "center" }}>
                <p
                  style={{
                    color: theme,
                    textAlign: "center",
                    fontSize: 22,
                  }}
                >
                  {" "}
                  {t("p4")}
                </p>

                <p
                  style={{
                    color: theme,
                    fontSize: 22,
                    marginBottom: 40,
                  }}
                >
                  {" "}
                  {email}
                </p>
              </div>

              <InputWithValidation
                label={t("p5")}
                error={passerror1}
                accessory={renderPasswordAccessory1}
                securetext={secureTextEntry1}
                onFunction={onChangeText1}
              />

              <InputWithValidation
                label={t("p6")}
                error={passerror2}
                accessory={renderPasswordAccessory2}
                securetext={secureTextEntry2}
                onFunction={onChangeText2}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  text={t("p7")}
                  onClick={_updatePass}
                  color={"#fff"}
                  backgroundcolor={theme}
                />
              </div>

              {passmessage !== "" ? (
                <p
                  style={{
                    color: theme,
                    textAlign: "center",
                    margin: 30,
                    fontSize: 18,
                  }}
                >
                  {t("p8")}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default ChangePass;
