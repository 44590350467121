import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import './Empty.styles.scss';
const bearpink = require("../../assets/bearpink.png");
const bearpurple = require("../../assets/bearpurple.png");
const bearblue = require("../../assets/bearblue.png");
const beargreen = require("../../assets/beargreen.png");
const bearorange = require("../../assets/bearorange.png");
const bearamethyst = require("../../assets/bearamethyst.png");
const bearcoral = require("../../assets/bearcoral.png");
const bearemerald = require("../../assets/bearemerald.png");
const bearneongreen = require("../../assets/bearneongreen.png");
const bearrosegold = require("../../assets/bearrosegold.png");
const bearsaphire = require("../../assets/bearsaphire.png");
const bearsunflower = require("../../assets/bearsunflower.png");
const bearturqoise = require("../../assets/bearturqoise.png");
const bearruby = require("../../assets/bearruby.png");

const Empty = (props) => {
  const { theme } = useContext(UserContext);
  const getImageSource = () => {
    switch (theme) {
      case "#ed4167":
        return bearpink;
      case "#6B3FA0":
        return bearpurple;
      case "#3ae346":
        return beargreen;
      case "#0099a7":
        return bearblue;
      case "#ff7474":
        return bearorange;
      case "#9966CC":
        return bearamethyst;
      case "#FF7F50":
        return bearcoral;
      case "#50C878":
        return bearemerald;
      case "#39FF14":
        return bearneongreen;
      case "#B76E79":
        return bearrosegold;
      case "#0F52BA":
        return bearsaphire;
      case "#FFC512":
        return bearsunflower;
      case "#40E0D0":
        return bearturqoise;
      case "#E0115F":
        return bearruby;
      default:
        return bearpink;
    }
  };

  return (
    <div>
        <img src={getImageSource()} style={{width:'100%', objectFit:'contain'}} />
        <p className="text" style={{ color: theme }}>{props.children}</p>
    </div>
  );
};



export default Empty;
