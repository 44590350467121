import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";

const DivNotSelected = (props) => {
  const { theme } = useContext(UserContext);
  return (
    <div
      style={{
        borderColor: theme,
        borderWidth: 1,
        borderStyle: 'solid',
        padding:'2px 5px',
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 5,
        marginRight: 5,
        display:'flex',
        alignItems: "center",
        justifyContent:'center',
        cursor: "pointer",
        minWidth:100
      }}
    >
      {props.children}
    </div>
  );
};

export default DivNotSelected;
