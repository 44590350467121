import React, { useState, useContext } from "react";
import InputWithValidation from "../InputWithValidation/InputWithValidation.component";
import "./Login.styles.scss";
import Button from "../Button/Button.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme, setUserID, setShowReset } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [expiredError, setExpiredError] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
    setExpiredError("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate email and password
    if (!email) {
      setEmailError(t("p9"));
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t("p17"));
      return;
    }
    if (!password) {
      setPasswordError(t("p18"));
      return;
    }

    fetch("https://marihuanadating.com/endpoint-wld/login.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson == "notfound") {
          setEmailError(t("p10"));
        } else if (responseJson == "wrongpass") {
          setPasswordError(t("p19"));
        } else if (responseJson == "membershipExpired") {
          setExpiredError(t("l45"));
        } else {
          localStorage.setItem("userId", responseJson);
          setUserID(responseJson);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleExpand = (event) => {
    event.preventDefault();

    // Validate email and password
    if (!email) {
      setEmailError(t("p9"));
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t("p17"));
      return;
    }

    navigate(`/expand-membership?email=${encodeURIComponent(email)}`);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputWithValidation
          label={t("p15")}
          type="email"
          value={email}
          error={emailError}
          onChange={handleEmailChange}
        />

        <InputWithValidation
          label={t("p20")}
          type="password"
          value={password}
          error={passwordError}
          onChange={handlePasswordChange}
        />
        <p
          style={{ color: theme, textAlign: "right", cursor: "pointer" }}
          onClick={() => setShowReset(true)}
        >
          {t("p21")}
        </p>
        {expiredError ? (
          <div>
            <p
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {t("l45")}
            </p>
            <Button
              text={t("l46")}
              color={"#fff"}
              backgroundcolor={theme}
              onClick={handleExpand}
            />
          </div>
        ) : (
          <Button
            text={t("l1")}
            color={"#fff"}
            backgroundcolor={theme}
            type="submit"
          />
        )}
      </form>
    </div>
  );
};

export default Login;
