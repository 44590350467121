import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import Empty from "../../components/Empty/Empty.component";
import SearchInput from "../../components/SearchInput/SearchInput.component";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LikesRow from "../../components/LikesRow/LikesRow.component";
import queryString from 'query-string';

const ProfileLikes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    likes,
    filteredLikes,
    userid,
    setNumberOfLikesNotifications,
    removeUserFromLikes,
    removeUserFromFilteredLikes,
    filterLikesUsers,
  } = useContext(UserContext);

  React.useEffect(() => {
    deletelikesnotifications();
  }, []);

  function deletelikesnotifications() {
    setNumberOfLikesNotifications(0);

    return fetch(
      "https://marihuanadating.com/endpoint-wld/deletelikesnotifications.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    );
  }

  function _deleteLike(item) {
    removeUserFromLikes(item);
    removeUserFromFilteredLikes(item);

    return fetch("https://marihuanadating.com/endpoint-wld/deletelike.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        theuser: item.id,
      }),
    });
  }

  const SearchFilterFunction = (text) => {
    filterLikesUsers(text);
  };

  if (likes.length == 0) {
    return (
      <div className="app-container">
        <div className="mobile-container">
          <Header title={t("ll1")} hasgoback={true}/>
          <Empty>{t("ll5")}</Empty>
          <BottomNavigation />
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("ll1")} hasgoback={true}/>
        <SearchInput onSearchFilterFunction={SearchFilterFunction} />
        {filteredLikes.map((item, key) => (
          <LikesRow
            image={item.profile_photo}
            first_name={item.first_name}
            last_name={item.last_name}
            thetime={item.thetime}
            onSelect={() => {
              navigate(
                `/encounters/UserProfile?${queryString.stringify(item)}`
              );
            }}
            onDelete={() => _deleteLike(item)}
          />
        ))}
        <div style={{ height: "50px" }}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default ProfileLikes;
