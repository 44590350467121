import "./Blog.styles.scss";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import { Link } from "react-router-dom";
import blogBackground from "../../assets/blogimage.jpg";
import Footer from "../../components/Footer/Footer.component";
import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import Background from "../../components/Background/Background.component";

const Blog = () => {
  const { t } = useTranslation();
  const { articles, theme } = useContext(UserContext);

  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <Background />
      </div>

      <ul>
        {articles.map((article) => (
          <li key={article.id}>
            <div className="postingdiv">
              <div className="image-container">
                <Link
                  to={`/blog/${article.slug}`}
                  state={{
                    title: article.title.rendered,
                    featuredimage:
                      article._embedded["wp:featuredmedia"][0].source_url,
                    content: article.content.rendered,
                  }}
                >
                  <img
                    className="postPic"
                    src={article._embedded["wp:featuredmedia"][0].source_url}
                  />
                </Link>
              </div>
              <div className="secondpart">
                <Link
                  to={`/blog/${article.slug}`}
                  state={{
                    title: article.title.rendered,
                    featuredimage:
                      article._embedded["wp:featuredmedia"][0].source_url,
                    content: article.content.rendered,
                  }}
                >
                  <p className="blogtitle" style={{ color: theme }}>
                    {article.title.rendered}
                  </p>
                </Link>
                <div className="excerptdiv">
                  <p
                    className="excerpt"
                    dangerouslySetInnerHTML={{
                      __html: article.excerpt.rendered,
                    }}
                  ></p>
                </div>
                <Link
                  to={`/blog/${article.slug}`}
                  state={{
                    title: article.title.rendered,
                    featuredimage:
                      article._embedded["wp:featuredmedia"][0].source_url,
                    content: article.content.rendered,
                  }}
                >
                  <p className="readmore" style={{ color: theme }}>
                    {t("o8")}
                  </p>
                </Link>
              </div>
            </div>
            <div className="theline"></div>
          </li>
        ))}
      </ul>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default Blog;
