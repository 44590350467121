import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import "./LanguageButton.styles.scss";
const LanguageButton = (props) => {
  const { theme } = useContext(UserContext);
  const { i18n, t } = useTranslation();

  const PrimaryClass =
    props.iconname === i18n.language ? "Primarybutton" : "Primarybuttonnot";

  return (
    <div className="buttonContainer">
      <div
        className={PrimaryClass}
        style={
          props.iconname === i18n.language
            ? { backgroundColor: theme, borderColor: theme }
            : { borderColor: theme }
        }
        onClick={props.onClick}
      >
        <p
          className={
            props.iconname === i18n.language ? "buttonText" : "buttonTextnot"
          }
          style={props.iconname === i18n.language ? null : { color: theme }}
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export default LanguageButton;
