import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import Footer from "../../components/Footer/Footer.component";
import { useTranslation } from "react-i18next";
import "./Terms.styles.scss";
import termsBackground from "../../assets/termsimage.jpg";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import Background from "../../components/Background/Background.component";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <Background />
      </div>

      <div className="terms-container">
        <h2>{t("l13")}</h2>
        <p className="terms-title">{t("y0")}</p>

        <p className="terms-paragraph">{t("y2")} </p>

        <p className="terms-paragraph">{t("y3")} </p>

        <p className="terms-paragraph">{t("y4")} </p>

        <p className="terms-paragraph">{t("y5")} </p>

        <p className="terms-title">{t("y6")} </p>

        <p className="terms-paragraph">{t("y7")} </p>

        <p className="terms-title">{t("y8")} </p>

        <p className="terms-paragraph">{t("y9")} </p>

        <p className="terms-title">{t("y10")} </p>

        <p className="terms-title">{t("y11")} </p>

        <p className="terms-paragraph">{t("y12")} </p>

        <p className="terms-paragraph">{t("y13")} </p>

        <p className="terms-paragraph">{t("y14")} </p>

        <p className="terms-paragraph">{t("y15")} </p>

        <p className="terms-paragraph">{t("y16")} </p>

        <p className="terms-paragraph">{t("y17")} </p>

        <p className="terms-paragraph">{t("y18")} </p>

        <p className="terms-title">{t("y19")} </p>

        <p className="terms-paragraph">{t("y20")} </p>

        <p className="terms-paragraph">{t("y21")} </p>

        <p className="terms-paragraph">{t("y22")} </p>

        <p className="terms-paragraph">{t("y23")} </p>

        <p className="terms-paragraph">{t("y24")} </p>

        <p className="terms-paragraph">{t("y25")} </p>

        <p className="terms-paragraph">{t("y26")} </p>

        <p className="terms-paragraph">{t("y27")} </p>

        <p className="terms-paragraph">{t("y28")} </p>

        <p className="terms-title">{t("y29")} </p>

        <p className="terms-paragraph">{t("y30")} </p>

        <p className="terms-paragraph">{t("y31")} </p>

        <p className="terms-paragraph">{t("y32")} </p>

        <p className="terms-title">{t("y33")} </p>

        <p className="terms-title">{t("y34")} </p>

        <p className="terms-paragraph">{t("y35")} </p>

        <p className="terms-title">{t("y36")} </p>

        <p className="terms-paragraph">{t("y37")} </p>

        <p className="terms-title">{t("y38")} </p>

        <p className="terms-paragraph">{t("y39")} </p>

        <p className="terms-title">{t("y40")} </p>

        <p className="terms-paragraph">{t("y41")} </p>

        <p className="terms-paragraph">{t("y42")} </p>

        <p className="terms-paragraph">{t("y43")} </p>

        <p className="terms-paragraph">{t("y44")} </p>

        <p className="terms-paragraph">{t("y45")} </p>

        <p className="terms-paragraph">{t("y46")} </p>

        <p className="terms-paragraph">{t("y47")} </p>

        <p className="terms-paragraph">{t("y48")} </p>

        <p className="terms-paragraph">{t("y49")} </p>

        <p className="terms-paragraph">{t("y50")} </p>

        <p className="terms-paragraph">{t("y51")} </p>

        <p className="terms-paragraph">{t("y52")} </p>

        <p className="terms-paragraph">{t("y53")} </p>

        <p className="terms-paragraph">{t("y54")} </p>

        <p className="terms-title">{t("y55")} </p>

        <p className="terms-paragraph">{t("y56")} </p>

        <p className="terms-paragraph">{t("y57")} </p>

        <p className="terms-paragraph">{t("y58")} </p>

        <p className="terms-paragraph">{t("y59")} </p>

        <p className="terms-paragraph">{t("y60")} </p>

        <p className="terms-paragraph">{t("y61")} </p>

        <p className="terms-paragraph">{t("y62")} </p>

        <p className="terms-paragraph">{t("y63")} </p>

        <p className="terms-paragraph">{t("y64")} </p>

        <p className="terms-paragraph">{t("y65")} </p>

        <p className="terms-paragraph">{t("y66")} </p>

        <p className="terms-paragraph">{t("y67")} </p>

        <p className="terms-paragraph">{t("y68")} </p>

        <p className="terms-paragraph">{t("y69")} </p>

        <p className="terms-paragraph">{t("y70")} </p>

        <p className="terms-paragraph">{t("y71")} </p>

        <p className="terms-paragraph">{t("y72")} </p>

        <p className="terms-title">{t("y73")} </p>

        <p className="terms-paragraph">{t("y74")} </p>

        <p className="terms-paragraph">{t("y75")} </p>

        <p className="terms-title">{t("y76")} </p>

        <p className="terms-paragraph">{t("y77")} </p>

        <p className="terms-title">{t("y78")} </p>

        <p className="terms-paragraph">{t("y79")} </p>

        <p className="terms-paragraph">{t("y80")} </p>

        <p className="terms-paragraph">{t("y81")} </p>

        <p className="terms-paragraph">{t("y82")} </p>

        <p className="terms-paragraph">{t("y83")} </p>

        <p className="terms-paragraph">{t("y84")} </p>

        <p className="terms-title">{t("y85")} </p>

        <p className="terms-paragraph">{t("y86")} </p>

        <p className="terms-title">{t("y87")} </p>

        <p className="terms-paragraph">{t("y88")} </p>

        <p className="terms-paragraph">{t("y89")} </p>

        <p className="terms-paragraph">{t("y90")} </p>

        <p className="terms-paragraph">{t("y91")} </p>

        <p className="terms-paragraph">{t("y92")} </p>

        <p className="terms-title">{t("y93")} </p>

        <p className="terms-title">{t("y94")} </p>

        <p className="terms-paragraph">{t("y95")} </p>

        <p className="terms-title">{t("y96")} </p>

        <p className="terms-paragraph">{t("y97")} </p>

        <p className="terms-title">{t("y98")} </p>

        <p className="terms-title">{t("y99")} </p>

        <p className="terms-paragraph">{t("y100")} </p>

        <p className="terms-title">{t("y101")} </p>

        <p className="terms-paragraph">{t("y102")} </p>

        <p className="terms-paragraph">{t("y103")} </p>

        <p className="terms-paragraph">{t("y104")} </p>

        <p className="terms-paragraph">{t("y105")} </p>

        <p className="terms-paragraph">{t("y106")} </p>

        <p className="terms-title">{t("y107")} </p>

        <p className="terms-paragraph">{t("y108")} </p>

        <p className="terms-title">{t("y109")} </p>

        <p className="terms-paragraph">{t("y110")} </p>

        <p className="terms-title">{t("y111")} </p>

        <p className="terms-paragraph">{t("y112")} </p>

        <p className="terms-paragraph">{t("y113")} </p>

        <p className="terms-title">{t("y114")} </p>

        <p className="terms-paragraph">{t("y115")} </p>

        <p className="terms-paragraph">{t("y116")} </p>

        <p className="terms-paragraph">{t("y117")} </p>

        <p className="terms-paragraph">{t("y118")} </p>

        <p className="terms-title">{t("y119")} </p>

        <p className="terms-paragraph">{t("y120")} </p>

        <p className="terms-title">{t("y121")} </p>

        <p className="terms-paragraph">{t("y122")} </p>

        <p className="terms-title">{t("y123")} </p>

        <p className="terms-paragraph">{t("y124")} </p>

        <p className="terms-title">{t("y125")} </p>

        <p className="terms-paragraph">{t("y126")} </p>

        <p className="terms-title">{t("y127")} </p>

        <p className="terms-paragraph">{t("y128")} </p>

        <p className="terms-title">{t("y129")} </p>

        <p className="terms-paragraph">{t("y130")} </p>

        <p className="terms-title">{t("y131")} </p>

        <p className="terms-paragraph">{t("y132")} </p>
      </div>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default Terms;
