import React, { useContext, useEffect } from "react";

import { UserContext } from "../../contexts/UserProvider";
import ThemeButton from "../../components/ThemeButton/ThemeButton.component";
import "./Theme.styles.scss";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import { useTranslation } from "react-i18next";

const Theme = (props) => {
  const { setTheme, theme } = useContext(UserContext);
  const { t } = useTranslation();
  const changeTheme = (color) => {
    setTheme(color);
    localStorage.setItem("themeColor", color);
  };

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("o27")} hasgoback={true}/>
        <div className="compartiment">
          <ThemeButton
            text="Purple"
            color="#6B3FA0"
            onClick={() => changeTheme("#6B3FA0")}
          />
          <ThemeButton
            text="Pink"
            color="#ed4167"
            onClick={() => changeTheme("#ed4167")}
          />
        </div>

        <div className="compartiment">
          <ThemeButton
            text="Green"
            color="#3ae346"
            onClick={() => changeTheme("#3ae346")}
          />
          <ThemeButton
            text="Blue"
            color="#0099a7"
            onClick={() => changeTheme("#0099a7")}
          />
        </div>

        <div className="compartiment">
          <ThemeButton
            text="Orange"
            color="#ff7474"
            onClick={() => changeTheme("#ff7474")}
          />
          <ThemeButton
            text="Turquoise"
            color="#40E0D0"
            onClick={() => changeTheme("#40E0D0")}
          />
        </div>

        <div className="compartiment">
          <ThemeButton
            text="Coral"
            color="#FF7F50"
            onClick={() => changeTheme("#FF7F50")}
          />
          <ThemeButton
            text="Amethyst"
            color="#9966CC"
            onClick={() => changeTheme("#9966CC")}
          />
        </div>

        <div className="compartiment">
          <ThemeButton
            text="Emerald"
            color="#50C878"
            onClick={() => changeTheme("#50C878")}
          />
          <ThemeButton
            text="Sapphire"
            color="#0F52BA"
            onClick={() => changeTheme("#0F52BA")}
          />
        </div>

        <div className="compartiment">
          <ThemeButton
            text="Rose Gold"
            color="#B76E79"
            onClick={() => changeTheme("#B76E79")}
          />
          <ThemeButton
            text="Sunflower"
            color="#FFC512"
            onClick={() => changeTheme("#FFC512")}
          />
        </div>

        <div className="compartiment">
          <ThemeButton
            text="Neon Green"
            color="#39FF14"
            onClick={() => changeTheme("#39FF14")}
          />
          <ThemeButton
            text="Ruby"
            color="#E0115F"
            onClick={() => changeTheme("#E0115F")}
          />
        </div>
        <div style={{height:'50px'}}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default Theme;
