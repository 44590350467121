import "./bottomNavigation.styles.scss";
import { useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { IoMdDisc } from "react-icons/io";
import { MdMessage, MdPerson, MdSettings } from "react-icons/md";
import { UserContext } from "../../contexts/UserProvider";

const BottomNavigation = () => {
  const { theme, messagesNotifications } = useContext(UserContext);
  const location = useLocation();
  const numberofmsgnotif = messagesNotifications.length;

  return (
    <nav className="bottom-tab-nav">
      <Link
        to="/profile"
        className="tab-link"
        style={{
          color: location.pathname.includes("/profile") ? theme : "gray",
        }}
      >
        <MdPerson size={location.pathname.includes("/profile") ? 30 : 20} />
      </Link>
      <Link
        to="/settings"
        className="tab-link"
        style={{
          color: location.pathname.includes("/settings") ? theme : "gray",
        }}
      >
        <MdSettings size={location.pathname.includes("/settings") ? 30 : 20} />
      </Link>
      <Link
        to="/chat"
        className="tab-link"
        style={{
          color: location.pathname.includes("/chat") ? theme : "gray",
        }}
      >
        <MdMessage size={location.pathname.includes("/chat") ? 30 : 20} />
        {numberofmsgnotif != 0 ? (
          <div
            style={{
              background: "red",
              width: 16,
              height: 16,
              borderRadius: 8,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              position: "absolute",
              top: 5,
              left: 240,
            }}
          >
            <p style={{ color: "#fff", fontSize: 10, fontWeight: "bold" }}>
              {numberofmsgnotif}
            </p>
          </div>
        ) : null}
      </Link>
      <Link
        to="/encounters"
        className="tab-link"
        style={{
          color: location.pathname.includes("/encounters") ? theme : "gray",
        }}
      >
        <IoMdDisc size={location.pathname.includes("/encounters") ? 30 : 20} />
      </Link>
    </nav>
  );
};

export default BottomNavigation;
