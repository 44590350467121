import { useContext } from "react";
import "./HomeDate.css";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import thepictures from "../../assets/thepictures.jpg";
const HomeDate = (props) => {
  const { theme } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div className="second-chapter">
      <img src={thepictures} />
      <div className="textulhome">
        <p className="titlul">
          {t("l20")}
          <span style={{ color: theme }}> {t("l21")}</span>
        </p>
        <p className="paragraful">{t("l22")}</p>
      </div>
    </div>
  );
};

export default HomeDate;
