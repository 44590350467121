import React, { useContext, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header.component";
import "./Chat.css";
import ChatMessages from "../../components/ChatMessages/ChatMessages";
import { IoIosSend } from "react-icons/io";
import SocketIOClient from "socket.io-client";

const { v4: uuidv4 } = require("uuid");

const Chat = (props) => {
  const { t } = useTranslation();
  const {
    theme,
    allMessages,
    rooms,
    addNewRoom,
    removeMessageNotification,
    userid,
    first_name,
    last_name,
    profilephoto,
    notifications,
    fcmtoken,
    addNewMessageToChat,
    addNewConversationToFilteredConversations,
    addNewConversation,
  } = useContext(UserContext);
  const location = useLocation();
  const params = location.state;
  const thename = params.first_name + " " + params.last_name;
  const thephoto = params.profile_photo;
  const thefriend = params;
  const [themessages, setTheMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [RoomID, setRoomID] = useState(null);
  const [input, setInput] = useState("");
  const [socket, setSocket] = useState(null);

  const onReceivedMessage = (data) => {
    if (data.senderid != userid) {
      const thenewmessage = {
        _id: data.chatid,
        text: data.mess,
        createdAt: new Date(data.createdAt * 1000),
        user: {
          _id: data.senderid,
        },
      };
      setTheMessages((prevMessages) => [thenewmessage, ...prevMessages]);
    } else return;
  };

  const insertnewroomidindatabase = (newidForRoom) => {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/insertnewroomid.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
          theotheruser: thefriend.id,
          newroom: newidForRoom,
        }),
      }
    );
  };

  const deletemessagenotification = () => {
    removeMessageNotification(thefriend.id);
    return fetch(
      "https://marihuanadating.com/endpoint-wld/deletemessagenotification.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
          friendid: thefriend.id,
        }),
      }
    );
  };

  const onChangeInput = (event) => {
    setInput(event.target.value);
  };

  const sendthemessagetoserver = (message, messid) => {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/sendmessagenew.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          sender: userid,
          themessage: message,
          messid: messid,
          roomid: RoomID,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      });
  };

  const sendMessage = (event) => {
    event.preventDefault();

    if (input !== "") {
      if (RoomID) {
        const themessageid = uuidv4();
        const timeofmessage = Date.now() / 1000;
        const newMessage = {
          conversation_id: RoomID,
          sender: userid,
          thetime: timeofmessage,
          message: input,
          mess_id: themessageid,
        };
        addNewMessageToChat(newMessage);
        addNewConversation({
          fcmtoken: thefriend.fcmtoken,
          first_name: thefriend.first_name,
          last_name: thefriend.last_name,
          id: thefriend.id,
          notifications: thefriend.notifications,
          profile_photo: thefriend.profile_photo,
          message: input,
          thetime: timeofmessage,
        });
        addNewConversationToFilteredConversations({
          fcmtoken: thefriend.fcmtoken,
          first_name: thefriend.first_name,
          last_name: thefriend.last_name,
          id: thefriend.id,
          notifications: thefriend.notifications,
          profile_photo: thefriend.profile_photo,
          message: input,
          thetime: timeofmessage,
        });
        sendthemessagetoserver(input, themessageid);
        const thenewmessage = {
          _id: themessageid,
          text: input,
          createdAt: new Date(timeofmessage * 1000),
          user: {
            _id: userid,
          },
        };
        setTheMessages((prevMessages) => [thenewmessage, ...prevMessages]);
        socket.emit("message", {
          senderid: userid,
          sender_first_name: first_name,
          sender_last_name: last_name,
          sender_photo: profilephoto,
          sender_notifications: notifications,
          sender_fcmtoken: fcmtoken,
          receiverid: thefriend.id,
          mess: input,
          chatid: themessageid,
          createdAt: timeofmessage,
          roomid: RoomID,
          receiverfcmtoken: thefriend.fcmtoken,
        });
      }
      setInput("");
    } else return;
  };

  useEffect(() => {
    const theroom = rooms.filter(
      (item) =>
        (item.user1 === userid && item.user2 === thefriend.id) ||
        (item.user1 === thefriend.id && item.user2 === userid)
    );
    if (theroom.length > 0) {
      setRoomID(theroom[0].roomid);
    } else {
      const newroomid = uuidv4();
      setRoomID(newroomid);
      const newroom = {
        roomid: newroomid,
        user1: userid,
        user2: thefriend.id,
      };
      addNewRoom(newroom);
      insertnewroomidindatabase(newroomid);
    }

    const themsg = allMessages.filter(
      (item) => item.conversation_id === RoomID
    );
    var a = [];
    for (let i = 0; i < themsg.length; i++) {
      let row = themsg[i];
      a.push({
        _id: row.mess_id,
        text: row.message,
        createdAt: new Date(row.thetime * 1000),
        user: {
          _id: row.sender,
        },
      });
    }
    setTheMessages(a);
    // Create a new socket instance
    const newSocket = SocketIOClient("http://marihuanadating.com:8443", {
      autoConnect: true,
      jsonp: false,
      transports: ["websocket"],
    });

    // Set the socket state
    setSocket(newSocket);

    newSocket.emit("joinroom", RoomID);

    newSocket.on("message", onReceivedMessage);

    // Cleanup: Close the socket when the component unmounts
    return () => {
      if (socket) {
        socket.emit("leaveRoom", RoomID);
        socket.disconnect();
      }
    };
  }, [RoomID]);

  useEffect(() => {
    deletemessagenotification();
  }, []);

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header
          title={thename}
          photo={thephoto}
          type="inthechat"
          hasgoback={true}
        />

        <div className="chat__body--container">
          <div className="chat__body" style={{ height: page.height - 68 }}>
            <ChatMessages
              messages={themessages}
              user={thename}
              roomId={RoomID}
              thesender={userid}
              thephoto={thephoto}
            />
          </div>
        </div>

        <div className="chat__footer">
          <form onSubmit={sendMessage}>
            <input
              value={input}
              onChange={onChangeInput}
              placeholder={t("c6")}
            />

            <button
              type="submit"
              className="send__btn"
              style={{ background: theme }}
            >
              <IoIosSend color={"#fff"} size={25} />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
