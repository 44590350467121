import { useContext } from "react";
import Gradient from "../Gradient/Gradient.component";
import "./RowInfo.styles.scss";
import { UserContext } from "../../contexts/UserProvider";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
const RowInfo = (props) => {
  const { theme } = useContext(UserContext);
  return (
    <div className="rowContainer">
      <div style={{ width: "80%" }}>
        <Gradient>
          <p className="titleText">
            {props.title}
          </p>
        </Gradient>
      </div>
      {props.showoption ? (
        <div className="qedit" onClick={props.onClick}>
          <IoIosArrowUp size={35} color={theme} style={{ cursor: "pointer" }} />
        </div>
      ) : (
        <div className="qedit" onClick={props.onClick}>
          <IoIosArrowDown
            size={35}
            color={theme}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  );
};

export default RowInfo;
