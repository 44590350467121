import React, { useEffect, useReducer, useMemo } from "react";
import axios from "axios";
import { useLocalStorage } from "./useLocalStorage";

const ACTION_TYPES = {
  UPDATE_USER_PROPERTY: "UPDATE_USER_PROPERTY",
  SET_ARTICLES: "SET_ARTICLES",
  SET_LIKES: "SET_LIKES",
  SET_FILTERED_LIKES: "SET_FILTERED_LIKES",
  SET_LIKED: "SET_LIKED",
  SET_FILTERED_LIKED: "SET_FILTERED_LIKED",
  SET_BLOCKED: "SET_BLOCKED",
  SET_FILTERED_BLOCKED: "SET_FILTERED_BLOCKED",
  SET_CONVERSATIONS: "SET_CONVERSATIONS",
  SET_FILTERED_CONVERSATIONS: "SET_FILTERED_CONVERSATIONS",

  SET_ALL_MESSAGES: "SET_ALL_MESSAGES",
  SET_ROOMS: "SET_ROOMS",
  SET_PHOTOS: "SET_PHOTOS",
  SET_HOBBIES: "SET_HOBBIES",
  SET_MESSAGES_NOTIFICATIONS: "SET_MESSAGES_NOTIFICATIONS",
  SET_PEOPLE: "SET_PEOPLE",
  UPDATE_NUMBER_OF_LIKES_NOTIFICATIONS: "UPDATE_NUMBER_OF_LIKES_NOTIFICATIONS",
  SET_FCMTOKEN: "SET_FCMTOKEN",
  SET_USER_ID: "SET_USER_ID",
  SET_FIRST_NAME: "SET_FIRST_NAME",
  SET_LAST_NAME: "SET_LAST_NAME",
  SET_DOB: "SET_DOB",
  SET_GENDER: "SET_GENDER",
  SET_ORIENTATION: "SET_ORIENTATION",
  SET_EMAIL: "SET_EMAIL",
  SET_DATECREATED: "SET_DATECREATED",
  SET_LATITUDE: "SET_LATITUDE",
  SET_LONGITUDE: "SET_LONGITUDE",
  SET_PROFILE_PHOTO: "SET_PROFILE_PHOTO",
  SET_DESCRIPTION: "SET_DESCRIPTION",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_AGEMIN: "SET_AGEMIN",
  SET_AGEMAX: "SET_AGEMAX",
  SET_LOCATION_BUTTON: "SET_LOCATION_BUTTON",
  SET_AGE_BUTTON: "SET_AGE_BUTTON",
  SET_DISTANCEMAX: "SET_DISTANCEMAX",
  SET_METRICSYS: "SET_METRICSYS",
  SET_ETHNICITY: "SET_ETHNICITY",
  SET_RELATIONSHIP: "SET_RELATIONSHIP",
  SET_RELATIONGOALS: "SET_RELATIONGOALS",
  SET_WORKOUT: "SET_WORKOUT",
  SET_RELIGION: "SET_RELIGION",
  SET_EDUCATION: "SET_EDUCATION",
  SET_SMOKING: "SET_SMOKING",
  SET_MARIJUANA: "SET_MARIJUANA",
  SET_DRINKING: "SET_DRINKING",
  SET_SHOWAGE: "SET_SHOWAGE",
  ADD_HOBBY: "ADD_HOBBY",
  REMOVE_HOBBY: "REMOVE_HOBBY",
  ADD_USER_TO_BLOCKED: "ADD_USER_TO_BLOCKED",
  ADD_USER_TO_FILTERED_BLOCKED: "ADD_USER_TO_FILTERED_BLOCKED",
  REMOVE_USER_FROM_BLOCKED: "REMOVE_USER_FROM_BLOCKED",
  REMOVE_USER_FROM_FILTERED_BLOCKED: "REMOVE_USER_FROM_FILTERED_BLOCKED",
  FILTER_BLOCKED_USERS: "FILTER_BLOCKED_USERS",
  ADD_USER_TO_LIKED: "ADD_USER_TO_LIKED",
  ADD_USER_TO_FILTERED_LIKED: "ADD_USER_TO_FILTERED_LIKED",
  FILTER_LIKED_USERS: "FILTER_LIKED_USERS",
  ADD_USER_TO_LIKES: "ADD_USER_TO_LIKES",
  ADD_USER_TO_FILTERED_LIKES: "ADD_USER_TO_FILTERED_LIKES",
  FILTER_LIKES_USERS: "FILTER_LIKES_USERS",
  REMOVE_USER_FROM_LIKES: "REMOVE_USER_FROM_LIKES",
  REMOVE_USER_FROM_FILTERED_LIKES: "REMOVE_USER_FROM_FILTERED_LIKES",

  ADD_MESSAGE_NOTIFICATION: "ADD_MESSAGE_NOTIFICATION",
  REMOVE_MESSAGE_NOTIFICATION: "REMOVE_MESSAGE_NOTIFICATION",
  ADD_NEW_CONVERSATION_TO_CONVERSATIONS:
    "ADD_NEW_CONVERSATION_TO_CONVERSATIONS",
  ADD_NEW_CONVERSATION_TO_FILTERED_CONVERSATIONS:
    "ADD_NEW_CONVERSATION_TO_FILTERED_CONVERSATIONS",
  REMOVE_CONVERSATION_FROM_CONVERSATIONS:
    "REMOVE_CONVERSATION_FROM_CONVERSATIONS",
  REMOVE_CONVERSATION_FROM_FILTERED_CONVERSATIONS:
    "REMOVE_CONVERSATION_FROM_FILTERED_CONVERSATIONS",
  FILTER_CONVERSATIONS: "FILTER_CONVERSATIONS",
  ADD_NEW_MESSAGE_TO_CHAT: "ADD_NEW_MESSAGE_TO_CHAT",
  ADD_NEW_ROOM: "ADD_NEW_ROOM",
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_THEME: "SET_THEME",
  SET_LOGIN_PANEL: "SET_LOGIN_PANEL",
  SET_REGISTER_PANEL: "SET_REGISTER_PANEL",
  SET_THEME_PANEL: "SET_THEME_PANEL",
  SET_LANGUAGE_PANEL: "SET_LANGUAGE_PANEL",
  SET_SHOW_RESET: "SET_SHOW_RESET",
  SET_SHOW_BOX: "SET_SHOW_BOX",
};

const INITIAL_STATE = {
  userid: "",
  first_name: "",
  last_name: "",
  dob: "",
  gender: "",
  orientation: "",
  email: "",
  datecreated: "",
  latitude: "",
  longitude: "",
  profilephoto: "",
  description: "",
  notifications: "",
  agemin: "",
  agemax: "",
  distancemax: "",
  locationbutton: "",
  agebutton: "",
  metricsys: "",
  ethnicity: "",
  relationship: "",
  relationgoals: "",
  workout: "",
  religion: "",
  education: "",
  smoking: "",
  marijuana: "",
  drinking: "",
  showage: "",

  language: "en",
  theme: "#ed4167",
  loginpanel: false,
  registerpanel: false,
  themepanel: false,
  languagepanel: false,
  showReset: false,
  showBox: false,

  likes: [],
  filteredLikes: [],
  liked: [],
  filteredLiked: [],
  blocked: [],
  filteredBlocked: [],
  conversations: [],
  filteredConversations: [],
  allMessages: [],
  rooms: [],
  photos: [],
  hobbies: [],
  messagesNotifications: [],
  people: [],
  numberOfLikesNotifications: 0,
  fcmtoken: null,
  articles: [],
};

/* Hobbies context functions --> */

const addHobby = (hobbies, hobbyToAdd) => {
  return [...hobbies, hobbyToAdd];
};

const removeHobby = (hobbies, hobbyToRemove) => {
  return hobbies.filter((item) => item !== hobbyToRemove);
};

/*  <-- Hobbies context functions --> */

/*  Blocked context functions --> */

const addBlockedUser = (blocked, userToAdd) => {
  var b = userToAdd;
  b.thetime = Date.now() / 1000;

  return [...blocked, b];
};

const removeBlockedUser = (blocked, userToRemove) => {
  return blocked.filter((item) => item.id !== userToRemove.id);
};

const filterBlocked = (blocked, criteria) => {
  return blocked.filter((item) => {
    const itemData = `${item.first_name.toUpperCase()}   
    ${item.last_name.toUpperCase()} `;
    return itemData.includes(criteria);
  });
};

/*  <-- Blocked context functions --> */

/*  Liked context functions --> */

const addLikedUser = (liked, userToAdd) => {
  var b = userToAdd;
  b.thetime = Date.now() / 1000;
  return [...liked, b];
};

const filterLiked = (liked, criteria) => {
  return liked.filter((item) => {
    const itemData = `${item.first_name.toUpperCase()}   
   ${item.last_name.toUpperCase()} `;
    return itemData.includes(criteria);
  });
};

/*  <-- Liked context functions --> */

/*  Likes context functions --> */

const addLikesUser = (likes, userToAdd) => {
  var b = userToAdd;
  b.thetime = Date.now() / 1000;

  return [...likes, b];
};

const removeLikesUser = (likes, userToRemove) => {
  return likes.filter((item) => item.id !== userToRemove.id);
};

const filterLikes = (likes, criteria) => {
  return likes.filter((item) => {
    const itemData = `${item.first_name.toUpperCase()}   
      ${item.last_name.toUpperCase()} `;
    return itemData.includes(criteria);
  });
};

/*  <-- Likes context functions --> */

/*  Messages notifications context functions --> */

const removeMessageNotif = (messagesNotifications, notificationToRemove) => {
  return messagesNotifications.filter((item) => item !== notificationToRemove);
};

const addMessageNotif = (messagesNotifications, notificationToAdd) => {
  return [...messagesNotifications, notificationToAdd];
};

/*  <-- Messages notifications context functions  */

/*  Conversations context functions --> */

const addConversation = (conversations, conversationToAdd) => {
  const existingConversation = conversations.find(
    (conversationItem) => conversationItem.id === conversationToAdd.id
  );

  if (existingConversation) {
    return conversations.map((conversationItem) =>
      conversationItem.id === conversationToAdd.id
        ? {
            ...conversationItem,
            thetime: conversationToAdd.thetime,
            message: conversationToAdd.message,
          }
        : conversationItem
    );
  }

  return [...conversations, conversationToAdd];
};

const removeConv = (conversations, convToRemove) => {
  return conversations.filter(
    (item) => item.conversation_id !== convToRemove.conversation_id
  );
};

const filterConv = (conversations, criteria) => {
  return conversations.filter((item) => {
    const itemData = `${item.first_name.toUpperCase()}   
      ${item.last_name.toUpperCase()} `;
    return itemData.includes(criteria);
  });
};

/*  <-- Conversations context functions  */

/*  Chat Messages context functions --> */

const addMessage = (allMessages, msgToAdd) => {
  return [...allMessages, msgToAdd];
};

/*  <-- Chat Messages context functions  */

const addRoom = (rooms, roomToAdd) => {
  return [...rooms, roomToAdd];
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.SET_SHOW_BOX:
      return { ...state, showBox: payload };
    case ACTION_TYPES.SET_SHOW_RESET:
      return { ...state, showReset: payload };
    case ACTION_TYPES.SET_LOGIN_PANEL:
      return { ...state, loginpanel: payload };
    case ACTION_TYPES.SET_REGISTER_PANEL:
      return { ...state, registerpanel: payload };
    case ACTION_TYPES.SET_THEME_PANEL:
      return { ...state, themepanel: payload };
    case ACTION_TYPES.SET_LANGUAGE_PANEL:
      return { ...state, languagepanel: payload };
    case ACTION_TYPES.SET_ARTICLES:
      return { ...state, articles: payload };
    case ACTION_TYPES.SET_LANGUAGE:
      return { ...state, language: payload };
    case ACTION_TYPES.SET_THEME:
      return { ...state, theme: payload };
    case ACTION_TYPES.SET_USER_ID:
      return { ...state, userid: payload };
    case ACTION_TYPES.SET_FIRST_NAME:
      return { ...state, first_name: payload };
    case ACTION_TYPES.SET_LAST_NAME:
      return { ...state, last_name: payload };
    case ACTION_TYPES.SET_DOB:
      return { ...state, dob: payload };
    case ACTION_TYPES.SET_GENDER:
      return { ...state, gender: payload };
    case ACTION_TYPES.SET_ORIENTATION:
      return { ...state, orientation: payload };
    case ACTION_TYPES.SET_EMAIL:
      return { ...state, email: payload };
    case ACTION_TYPES.SET_DATECREATED:
      return { ...state, datecreated: payload };
    case ACTION_TYPES.SET_LATITUDE:
      return { ...state, latitude: payload };
    case ACTION_TYPES.SET_LONGITUDE:
      return { ...state, longitude: payload };
    case ACTION_TYPES.SET_PROFILE_PHOTO:
      return { ...state, profilephoto: payload };
    case ACTION_TYPES.SET_DESCRIPTION:
      return { ...state, description: payload };
    case ACTION_TYPES.SET_NOTIFICATIONS:
      return { ...state, notifications: payload };
    case ACTION_TYPES.SET_AGEMIN:
      return { ...state, agemin: payload };
    case ACTION_TYPES.SET_AGEMAX:
      return { ...state, agemax: payload };
    case ACTION_TYPES.SET_DISTANCEMAX:
      return { ...state, distancemax: payload };
    case ACTION_TYPES.SET_LOCATION_BUTTON:
      return { ...state, locationbutton: payload };
    case ACTION_TYPES.SET_AGE_BUTTON:
      return { ...state, agebutton: payload };
    case ACTION_TYPES.SET_METRICSYS:
      return { ...state, metricsys: payload };
    case ACTION_TYPES.SET_ETHNICITY:
      return { ...state, ethnicity: payload };
    case ACTION_TYPES.SET_RELATIONSHIP:
      return { ...state, relationship: payload };
    case ACTION_TYPES.SET_RELATIONGOALS:
      return { ...state, relationgoals: payload };
    case ACTION_TYPES.SET_WORKOUT:
      return { ...state, workout: payload };
    case ACTION_TYPES.SET_RELIGION:
      return { ...state, religion: payload };
    case ACTION_TYPES.SET_EDUCATION:
      return { ...state, education: payload };
    case ACTION_TYPES.SET_SMOKING:
      return { ...state, smoking: payload };
    case ACTION_TYPES.SET_MARIJUANA:
      return { ...state, marijuana: payload };
    case ACTION_TYPES.SET_DRINKING:
      return { ...state, drinking: payload };
    case ACTION_TYPES.SET_SHOWAGE:
      return { ...state, showage: payload };

    case ACTION_TYPES.SET_LIKES:
      return { ...state, likes: payload };
    case ACTION_TYPES.SET_FILTERED_LIKES:
      return { ...state, filteredLikes: payload };
    case ACTION_TYPES.SET_LIKED:
      return { ...state, liked: payload };
    case ACTION_TYPES.SET_FILTERED_LIKED:
      return { ...state, filteredLiked: payload };
    case ACTION_TYPES.SET_BLOCKED:
      return { ...state, blocked: payload };
    case ACTION_TYPES.SET_FILTERED_BLOCKED:
      return { ...state, filteredBlocked: payload };
    case ACTION_TYPES.SET_CONVERSATIONS:
      return { ...state, conversations: payload };
    case ACTION_TYPES.SET_FILTERED_CONVERSATIONS:
      return { ...state, filteredConversations: payload };

    case ACTION_TYPES.SET_ALL_MESSAGES:
      return { ...state, allMessages: payload };
    case ACTION_TYPES.SET_ROOMS:
      return { ...state, rooms: payload };
    case ACTION_TYPES.SET_PHOTOS:
      return { ...state, photos: payload };
    case ACTION_TYPES.SET_HOBBIES:
      return { ...state, hobbies: payload };
    case ACTION_TYPES.SET_MESSAGES_NOTIFICATIONS:
      return { ...state, messagesNotifications: payload };

    case ACTION_TYPES.SET_PEOPLE:
      return { ...state, people: payload };
    case ACTION_TYPES.UPDATE_NUMBER_OF_LIKES_NOTIFICATIONS:
      return {
        ...state,
        numberOfLikesNotifications: payload,
      };
    case ACTION_TYPES.SET_FCMTOKEN:
      return {
        ...state,
        fcmtoken: action.payload,
      };

    case ACTION_TYPES.ADD_HOBBY:
      return {
        ...state,
        hobbies: addHobby(state.hobbies, payload),
      };
    case ACTION_TYPES.REMOVE_HOBBY:
      return {
        ...state,
        hobbies: removeHobby(state.hobbies, payload),
      };

    case ACTION_TYPES.ADD_USER_TO_LIKED:
      return {
        ...state,
        liked: addLikedUser(state.liked, payload),
      };
    case ACTION_TYPES.ADD_USER_TO_FILTERED_LIKED:
      return {
        ...state,
        filteredLiked: addLikedUser(state.filteredLiked, payload),
      };

    case ACTION_TYPES.FILTER_LIKED_USERS:
      return {
        ...state,
        filteredLiked: filterLiked(state.liked, payload),
      };
    case ACTION_TYPES.ADD_USER_TO_BLOCKED:
      return {
        ...state,
        blocked: addBlockedUser(state.blocked, payload),
      };
    case ACTION_TYPES.ADD_USER_TO_FILTERED_BLOCKED:
      return {
        ...state,
        filteredBlocked: addBlockedUser(state.filteredBlocked, payload),
      };

    case ACTION_TYPES.REMOVE_USER_FROM_BLOCKED:
      return {
        ...state,
        blocked: removeBlockedUser(state.blocked, payload),
      };
    case ACTION_TYPES.REMOVE_USER_FROM_FILTERED_BLOCKED:
      return {
        ...state,
        filteredBlocked: removeBlockedUser(state.filteredBlocked, payload),
      };
    case ACTION_TYPES.FILTER_BLOCKED_USERS:
      return {
        ...state,
        filteredBlocked: filterBlocked(state.blocked, payload),
      };

    case ACTION_TYPES.ADD_USER_TO_LIKES:
      return {
        ...state,
        likes: addLikesUser(state.likes, payload),
      };
    case ACTION_TYPES.ADD_USER_TO_FILTERED_LIKES:
      return {
        ...state,
        filteredLikes: addLikesUser(state.filteredLikes, payload),
      };
    case ACTION_TYPES.REMOVE_USER_FROM_LIKES:
      return {
        ...state,
        likes: removeLikesUser(state.likes, payload),
      };
    case ACTION_TYPES.REMOVE_USER_FROM_FILTERED_LIKES:
      return {
        ...state,
        filteredLikes: removeLikesUser(state.filteredLikes, payload),
      };
    case ACTION_TYPES.FILTER_LIKES_USERS:
      return {
        ...state,
        filteredLikes: filterLikes(state.likes, payload),
      };

    case ACTION_TYPES.ADD_MESSAGE_NOTIFICATION:
      return {
        ...state,
        messagesNotifications: addMessageNotif(
          state.messagesNotifications,
          payload
        ),
      };

    case ACTION_TYPES.REMOVE_MESSAGE_NOTIFICATION:
      return {
        ...state,
        messagesNotifications: removeMessageNotif(
          state.messagesNotifications,
          payload
        ),
      };

    case ACTION_TYPES.ADD_NEW_CONVERSATION_TO_CONVERSATIONS:
      return {
        ...state,
        conversations: addConversation(state.conversations, payload),
      };
    case ACTION_TYPES.ADD_NEW_CONVERSATION_TO_FILTERED_CONVERSATIONS:
      return {
        ...state,
        filteredConversations: addConversation(
          state.filteredConversations,
          payload
        ),
      };
    case ACTION_TYPES.REMOVE_CONVERSATION_FROM_CONVERSATIONS:
      return {
        ...state,
        conversations: removeConv(state.conversations, payload),
      };
    case ACTION_TYPES.REMOVE_CONVERSATION_FROM_FILTERED_CONVERSATIONS:
      return {
        ...state,
        filteredConversations: removeConv(state.filteredConversations, payload),
      };
    case ACTION_TYPES.FILTER_CONVERSATIONS:
      return {
        ...state,
        filteredConversations: filterConv(state.conversations, payload),
      };

    case ACTION_TYPES.ADD_NEW_MESSAGE_TO_CHAT:
      return {
        ...state,
        allMessages: addMessage(state.allMessages, payload).sort(function (
          a,
          b
        ) {
          return b.thetime > a.thetime;
        }),
      };
    case ACTION_TYPES.ADD_NEW_ROOM:
      return {
        ...state,
        rooms: addRoom(state.rooms, payload),
      };

    default:
      throw new Error(`Unhandled type ${type} in Reducer`);
  }
};

const UserContext = React.createContext();

const UserProvider = (props) => {
  const [
    {
      language,
      theme,
      userid,
      first_name,
      last_name,
      dob,
      gender,
      orientation,
      email,
      datecreated,
      latitude,
      longitude,
      profilephoto,
      description,
      notifications,
      agemin,
      agemax,
      distancemax,
      locationbutton,
      agebutton,
      metricsys,
      ethnicity,
      relationship,
      relationgoals,
      workout,
      religion,
      education,
      smoking,
      marijuana,
      drinking,
      showage,

      likes,
      filteredLikes,
      liked,
      filteredLiked,
      blocked,
      filteredBlocked,
      conversations,
      filteredConversations,
      allMessages,
      rooms,
      photos,
      hobbies,
      messagesNotifications,
      people,
      numberOfLikesNotifications,
      fcmtoken,
      articles,
      loginpanel,
      registerpanel,
      themepanel,
      languagepanel,
      showReset,
      showBox,
    },
    dispatch,
  ] = useReducer(reducer, INITIAL_STATE);

  const setShowBox = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_SHOW_BOX,
      payload: value,
    });
  };

  const setShowReset = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_SHOW_RESET,
      payload: value,
    });
  };

  const setLoginPanel = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LOGIN_PANEL,
      payload: value,
    });
  };

  const setRegisterPanel = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_REGISTER_PANEL,
      payload: value,
    });
  };

  const setThemePanel = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_THEME_PANEL,
      payload: value,
    });
  };

  const setLanguagePanel = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LANGUAGE_PANEL,
      payload: value,
    });
  };

  const setArticles = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_ARTICLES,
      payload: value,
    });
  };

  const setLanguage = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LANGUAGE,
      payload: value,
    });
  };

  const setTheme = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_THEME,
      payload: value,
    });
  };

  const setUserID = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_USER_ID,
      payload: value,
    });
  };
  const setFirstName = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_FIRST_NAME,
      payload: value,
    });
  };
  const setLastName = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LAST_NAME,
      payload: value,
    });
  };
  const setDob = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_DOB,
      payload: value,
    });
  };
  const setGender = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_GENDER,
      payload: value,
    });
  };
  const setOrientation = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_ORIENTATION,
      payload: value,
    });
  };
  const setEmail = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_EMAIL,
      payload: value,
    });
  };
  const setDatecreated = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_DATECREATED,
      payload: value,
    });
  };
  const setLatitude = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LATITUDE,
      payload: value,
    });
  };
  const setLongitude = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LONGITUDE,
      payload: value,
    });
  };
  const setProfilePhoto = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_PROFILE_PHOTO,
      payload: value,
    });
  };
  const setDescription = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_DESCRIPTION,
      payload: value,
    });
  };
  const setNotifications = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_NOTIFICATIONS,
      payload: value,
    });
  };
  const setAgemin = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_AGEMIN,
      payload: value,
    });
  };
  const setAgemax = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_AGEMAX,
      payload: value,
    });
  };
  const setDistancemax = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_DISTANCEMAX,
      payload: value,
    });
  };
  const setLocationbutton = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LOCATION_BUTTON,
      payload: value,
    });
  };
  const setAgebutton = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_AGE_BUTTON,
      payload: value,
    });
  };
  const setMetricsys = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_METRICSYS,
      payload: value,
    });
  };
  const setEthnicity = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_ETHNICITY,
      payload: value,
    });
  };
  const setRelationship = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_RELATIONSHIP,
      payload: value,
    });
  };
  const setRelationgoals = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_RELATIONGOALS,
      payload: value,
    });
  };
  const setWorkout = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_WORKOUT,
      payload: value,
    });
  };
  const setReligion = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_RELIGION,
      payload: value,
    });
  };
  const setEducation = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_EDUCATION,
      payload: value,
    });
  };
  const setSmoking = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_SMOKING,
      payload: value,
    });
  };
  const setDrinking = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_DRINKING,
      payload: value,
    });
  };
  const setMarijuana = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_MARIJUANA,
      payload: value,
    });
  };
  const setShowage = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_SHOWAGE,
      payload: value,
    });
  };

  const setLikes = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LIKES,
      payload: value,
    });
  };

  const setFilteredLikes = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_FILTERED_LIKES,
      payload: value,
    });
  };

  const setLiked = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_LIKED,
      payload: value,
    });
  };

  const setFilteredLiked = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_FILTERED_LIKED,
      payload: value,
    });
  };

  const setBlocked = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_BLOCKED,
      payload: value,
    });
  };

  const setFilteredBlocked = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_FILTERED_BLOCKED,
      payload: value,
    });
  };

  const setConversations = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_CONVERSATIONS,
      payload: value,
    });
  };

  const setFilteredConversations = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_FILTERED_CONVERSATIONS,
      payload: value,
    });
  };

  const setAllMessages = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_ALL_MESSAGES,
      payload: value,
    });
  };

  const setRooms = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_ROOMS,
      payload: value,
    });
  };

  const setPhotos = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_PHOTOS,
      payload: value,
    });
  };

  const setHobbies = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_HOBBIES,
      payload: value,
    });
  };

  const setMessagesNotifications = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGES_NOTIFICATIONS,
      payload: value,
    });
  };

  const setPeople = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_PEOPLE,
      payload: value,
    });
  };

  const setNumberOfLikesNotifications = (value) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_NUMBER_OF_LIKES_NOTIFICATIONS,
      payload: value,
    });
  };

  const setfcmtoken = (value) => {
    dispatch({
      type: ACTION_TYPES.SET_FCMTOKEN,
      payload: value,
    });
  };

  const addNewHobby = (hobbyToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_HOBBY,
      payload: hobbyToAdd,
    });
  };

  const removeTheHobby = (hobbyToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_HOBBY,
      payload: hobbyToRemove,
    });
  };

  const addUserToBlocked = (userToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_USER_TO_BLOCKED,
      payload: userToAdd,
    });
  };

  const addUserToFilteredBlocked = (userToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_USER_TO_FILTERED_BLOCKED,
      payload: userToAdd,
    });
  };

  const removeUserFromBlocked = (userToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_USER_FROM_BLOCKED,
      payload: userToRemove,
    });
  };

  const removeUserFromFilteredBlocked = (userToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_USER_FROM_FILTERED_BLOCKED,
      payload: userToRemove,
    });
  };

  const filterBlockedUsers = (criteria) => {
    dispatch({
      type: ACTION_TYPES.FILTER_BLOCKED_USERS,
      payload: criteria,
    });
  };

  const addUserToLiked = (userToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_USER_TO_LIKED,
      payload: userToAdd,
    });
  };

  const addUserToFilteredLiked = (userToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_USER_TO_FILTERED_LIKED,
      payload: userToAdd,
    });
  };

  const filterLikedUsers = (criteria) => {
    dispatch({
      type: ACTION_TYPES.FILTER_LIKED_USERS,
      payload: criteria,
    });
  };

  const addUserToLikes = (userToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_USER_TO_LIKES,
      payload: userToAdd,
    });
  };

  const addUserToFilteredLikes = (userToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_USER_TO_FILTERED_LIKES,
      payload: userToAdd,
    });
  };

  const removeUserFromLikes = (userToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_USER_FROM_LIKES,
      payload: userToRemove,
    });
  };

  const removeUserFromFilteredLikes = (userToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_USER_FROM_FILTERED_LIKES,
      payload: userToRemove,
    });
  };

  const filterLikesUsers = (criteria) => {
    dispatch({
      type: ACTION_TYPES.FILTER_LIKES_USERS,
      payload: criteria,
    });
  };

  const removeMessageNotification = (notificationToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_MESSAGE_NOTIFICATION,
      payload: notificationToRemove,
    });
  };

  const addMessageNotification = (notificationToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_MESSAGE_NOTIFICATION,
      payload: notificationToAdd,
    });
  };

  const addNewConversation = (conversationToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_NEW_CONVERSATION_TO_CONVERSATIONS,
      payload: conversationToAdd,
    });
  };

  const addNewConversationToFilteredConversations = (conversationToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_NEW_CONVERSATION_TO_FILTERED_CONVERSATIONS,
      payload: conversationToAdd,
    });
  };

  const removeConversation = (convToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_CONVERSATION_FROM_CONVERSATIONS,
      payload: convToRemove,
    });
  };

  const removeConversationFromFilteredConversations = (convToRemove) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_CONVERSATION_FROM_FILTERED_CONVERSATIONS,
      payload: convToRemove,
    });
  };

  const filterConversations = (criteria) => {
    dispatch({
      type: ACTION_TYPES.FILTER_CONVERSATIONS,
      payload: criteria,
    });
  };

  const addNewMessageToChat = (msgToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_NEW_MESSAGE_TO_CHAT,
      payload: msgToAdd,
    });
  };

  const addNewRoom = (roomToAdd) => {
    dispatch({
      type: ACTION_TYPES.ADD_NEW_ROOM,
      payload: roomToAdd,
    });
  };

  useEffect(() => {
    // Read the theme color from AsyncStorage on component mount
    const savedThemeColor = localStorage.getItem("themeColor");

    if (savedThemeColor) {
      setTheme(savedThemeColor);
    }
  }, []);

  useEffect(() => {
    axios
      .get("https://marihuanadating.com/blogcms/wp-json/wp/v2/posts?_embed")
      .then((res) => setArticles(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <UserContext.Provider
      value={{
        userid,
        setUserID,
        first_name,
        setFirstName,
        last_name,
        setLastName,
        dob,
        setDob,
        gender,
        setGender,
        orientation,
        setOrientation,
        email,
        setEmail,
        datecreated,
        setDatecreated,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        profilephoto,
        setProfilePhoto,
        description,
        setDescription,
        notifications,
        setNotifications,
        agemin,
        setAgemin,
        agemax,
        setAgemax,
        distancemax,
        setDistancemax,
        locationbutton,
        setLocationbutton,
        agebutton,
        setAgebutton,
        metricsys,
        setMetricsys,
        ethnicity,
        setEthnicity,
        relationship,
        setRelationship,
        relationgoals,
        setRelationgoals,
        workout,
        setWorkout,
        religion,
        setReligion,
        education,
        setEducation,
        smoking,
        setSmoking,
        marijuana,
        setMarijuana,
        drinking,
        setDrinking,
        showage,
        setShowage,

        language,
        setLanguage,
        theme,
        setTheme,
        articles,
        setArticles,
        loginpanel,
        setLoginPanel,
        registerpanel,
        setRegisterPanel,
        themepanel,
        setThemePanel,
        languagepanel,
        setLanguagePanel,
        showReset,
        setShowReset,
        showBox,
        setShowBox,

        addNewConversation,
        addNewConversationToFilteredConversations,
        removeConversation,
        removeConversationFromFilteredConversations,
        filterConversations,
        addNewMessageToChat,
        addNewRoom,

        addNewHobby,
        removeTheHobby,
        addUserToBlocked,
        addUserToFilteredBlocked,
        removeUserFromBlocked,
        removeUserFromFilteredBlocked,
        filterBlockedUsers,
        addUserToLiked,
        addUserToFilteredLiked,
        filterLikedUsers,
        addUserToFilteredLikes,
        addUserToLikes,
        filterLikesUsers,
        removeUserFromLikes,
        removeUserFromFilteredLikes,

        addMessageNotification,
        removeMessageNotification,

        likes,
        setLikes,
        filteredLikes,
        setFilteredLikes,
        liked,
        setLiked,
        filteredLiked,
        setFilteredLiked,
        blocked,
        setBlocked,
        filteredBlocked,
        setFilteredBlocked,
        conversations,
        setConversations,
        filteredConversations,
        setFilteredConversations,

        allMessages,
        setAllMessages,
        rooms,
        setRooms,
        photos,
        setPhotos,
        hobbies,
        setHobbies,
        messagesNotifications,
        setMessagesNotifications,

        people,
        setPeople,
        numberOfLikesNotifications,
        setNumberOfLikesNotifications,
        fcmtoken,
        setfcmtoken,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
