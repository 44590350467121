import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import { useLocation } from "react-router-dom";
import "./Blogpost.styles.scss";
import Footer from "../../components/Footer/Footer.component";
import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import AppLinks from "../../components/AppLinks/AppLinks.component";

const Blogpost = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
  const location = useLocation();
  const title = location.state.title;
  const featuredimage = location.state.featuredimage;
  const content = location.state.content;
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <img className="contact-image" src={featuredimage} />
      </div>

      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <h2 className="thetitle">{title}</h2>

        <div
          className="contentdiv"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        <div className="theline"></div>
      </div>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default Blogpost;
