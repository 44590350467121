import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import Footer from "../../components/Footer/Footer.component";
import { useTranslation } from "react-i18next";
import "./Help.styles.scss";
import hcBackground from "../../assets/helpcenter.jpg";
import AppLinks from "../../components/AppLinks/AppLinks.component";

const Help = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <img className="contact-image" src={hcBackground} />
      </div>
      <div className="help-container">
        <h2>{t("l14")}</h2>
        <p className="help-title">1.{t("h102")}</p>
        <p className="help-paragraph">{t("h103")}</p>

        <p className="help-title">2.{t("h104")}</p>
        <p className="help-paragraph">{t("h105")}</p>

        <p className="help-title">3.{t("h106")}</p>
        <p className="help-paragraph">{t("h107")}</p>
        <p className="help-paragraph">{t("h108")}</p>

        <p className="help-title">4.{t("h109")}</p>
        <p className="help-paragraph">{t("h110")}</p>

        <p className="help-title">5.{t("h111")}</p>
        <p className="help-paragraph">{t("h112")}</p>

        <p className="help-title">6.{t("h113")}</p>
        <p className="help-paragraph">{t("h114")}</p>

        <p className="help-title">7.{t("h115")}</p>
        <p className="help-paragraph">{t("h116")}</p>

        <p className="help-title">8.{t("h117")}</p>
        <p className="help-paragraph">{t("h118")}</p>

        <p className="help-title">9.{t("h119")}</p>
        <p className="help-paragraph">{t("h120")}</p>

        <p className="help-title">10.{t("h121")}</p>
        <p className="help-paragraph">{t("h122")}</p>

        <p className="help-title">11.{t("h123")}</p>
        <p className="help-paragraph">{t("h124")}</p>

        <p className="help-title">12.{t("h125")}</p>
        <p className="help-paragraph">{t("h126")}</p>

        <p className="help-title">13.{t("h127")}</p>
        <p className="help-paragraph">{t("h128")}</p>

        <p className="help-title">14.{t("h129")}</p>
        <p className="help-paragraph">{t("h130")}</p>

        <p className="help-title">15.{t("h131")}</p>
        <p className="help-paragraph">{t("h132")}</p>
        <p className="help-paragraph">{t("h133")}</p>
        <p className="help-paragraph">{t("h134")}</p>
        <p className="help-paragraph">{t("h135")}</p>
        <p className="help-paragraph">{t("h136")}</p>
        <p className="help-paragraph">{t("h137")}</p>

        <p className="help-title">16.{t("h138")}</p>
        <p className="help-paragraph">{t("h139")}</p>

        <p className="help-title">17.{t("h140")}</p>
        <p className="help-paragraph">{t("h141")}</p>

        <p className="help-title">18.{t("h142")}</p>
        <p className="help-paragraph">{t("h143")}</p>

        <p className="help-title">19.{t("h144")}</p>
        <p className="help-paragraph">{t("h145")}</p>

        <p className="help-title">20.{t("h146")}</p>
        <p className="help-paragraph">{t("h147")}</p>

        <p className="help-title">21.{t("h148")}</p>
        <p className="help-paragraph">{t("h149")}</p>

        <p className="help-title">22.{t("h150")}</p>
        <p className="help-paragraph">{t("h151")}</p>

        <p className="help-title">23.{t("h152")}</p>
        <p className="help-paragraph">{t("h153")}</p>

        <p className="help-title">24.{t("h154")}</p>
        <p className="help-paragraph">{t("h155")}</p>

        <p className="help-title">25.{t("h156")}</p>
        <p className="help-paragraph">{t("h157")}</p>
      </div>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default Help;
