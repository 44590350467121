import React, { useContext } from "react";
import "./PlanButton.styles.scss";
import { UserContext } from "../../contexts/UserProvider";

const PlanButton = (props) => {
  const { theme } = useContext(UserContext);
  return (
    <div className="thebuttonContainer">
      <div
        className="Primalbutton"
        style={{
          borderColor: props.activation ? "gray" : theme,
          cursor: props.activation ? null : "pointer",
        }}
        onClick={props.activation ? null : props.onFunction}
      >
        <p
          className="buttonText"
          style={{ color: props.activation ? "gray" : theme }}
        >
          {props.text}
        </p>
        {props.text2 ? (
          <p
            className="raisedText"
            style={{ backgroundColor: props.activation ? "gray" : theme }}
          >
            {props.text2}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default PlanButton;
