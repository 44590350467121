import React, { useEffect, useContext } from "react";
import "./Modal.styles.scss";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";

const Modal = ({ isOpen, closeModal }) => {
  const { i18n, t } = useTranslation();
  const { theme } = useContext(UserContext);
  const handleCloseModal = () => {
    closeModal();
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modal")) {
        handleCloseModal();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <h2 style={{color:theme}}>{t("l4")}</h2>
        <div className="delimiter"></div>
        <div className="thegrid">
          <div
            className={`grid-item ${i18n.language === "en" ? "bold" : ""}`}
            onClick={() => changeLanguage("en")}
          >
            English
          </div>
          <div
            className={`grid-item ${i18n.language === "fr" ? "bold" : ""}`}
            onClick={() => changeLanguage("fr")}
          >
            French
          </div>
          <div
            className={`grid-item ${i18n.language === "de" ? "bold" : ""}`}
            onClick={() => changeLanguage("de")}
          >
            German
          </div>
          <div
            className={`grid-item ${i18n.language === "it" ? "bold" : ""}`}
            onClick={() => changeLanguage("it")}
          >
            Italian
          </div>
  
          <div
            className={`grid-item ${i18n.language === "es" ? "bold" : ""}`}
            onClick={() => changeLanguage("es")}
          >
            Spanish
          </div>
          <div
            className={`grid-item ${i18n.language === "pt" ? "bold" : ""}`}
            onClick={() => changeLanguage("pt")}
          >
            Portuguese
          </div>
          <div
            className={`grid-item ${i18n.language === "nl" ? "bold" : ""}`}
            onClick={() => changeLanguage("nl")}
          >
            Dutch
          </div>
          <div
            className={`grid-item ${i18n.language === "ro" ? "bold" : ""}`}
            onClick={() => changeLanguage("ro")}
          >
            Romanian
          </div>
       
          <div
            className={`grid-item ${i18n.language === "af" ? "bold" : ""}`}
            onClick={() => changeLanguage("af")}
          >
            Afrikaans
          </div>
          <div
            className={`grid-item ${i18n.language === "cs" ? "bold" : ""}`}
            onClick={() => changeLanguage("cs")}
          >
            Czech
          </div>
          <div
            className={`grid-item ${i18n.language === "et" ? "bold" : ""}`}
            onClick={() => changeLanguage("et")}
          >
            Estonian
          </div>
          <div
            className={`grid-item ${i18n.language === "is" ? "bold" : ""}`}
            onClick={() => changeLanguage("is")}
          >
            Icelandic
          </div>
       
          <div
            className={`grid-item ${i18n.language === "hr" ? "bold" : ""}`}
            onClick={() => changeLanguage("hr")}
          >
            Croatian
          </div>
          <div
            className={`grid-item ${i18n.language === "hu" ? "bold" : ""}`}
            onClick={() => changeLanguage("hu")}
          >
            Hungarian
          </div>
          <div
            className={`grid-item ${i18n.language === "lt" ? "bold" : ""}`}
            onClick={() => changeLanguage("lt")}
          >
            Lithuanian
          </div>
          <div
            className={`grid-item ${i18n.language === "lv" ? "bold" : ""}`}
            onClick={() => changeLanguage("lv")}
          >
            Latvian
          </div>
       
          <div
            className={`grid-item ${i18n.language === "no" ? "bold" : ""}`}
            onClick={() => changeLanguage("no")}
          >
            Norwegian
          </div>
          <div
            className={`grid-item ${i18n.language === "sv" ? "bold" : ""}`}
            onClick={() => changeLanguage("sv")}
          >
            Swedish
          </div>
          <div
            className={`grid-item ${i18n.language === "fi" ? "bold" : ""}`}
            onClick={() => changeLanguage("fi")}
          >
            Finnish
          </div>
          <div
            className={`grid-item ${i18n.language === "da" ? "bold" : ""}`}
            onClick={() => changeLanguage("da")}
          >
            Dannish
          </div>
       
          <div
            className={`grid-item ${i18n.language === "sk" ? "bold" : ""}`}
            onClick={() => changeLanguage("sk")}
          >
            Slovakian
          </div>
          <div
            className={`grid-item ${i18n.language === "sl" ? "bold" : ""}`}
            onClick={() => changeLanguage("sl")}
          >
            Slovenian
          </div>
          <div
            className={`grid-item ${i18n.language === "pl" ? "bold" : ""}`}
            onClick={() => changeLanguage("pl")}
          >
            Polish
          </div>
          <div
            className={`grid-item ${i18n.language === "tr" ? "bold" : ""}`}
            onClick={() => changeLanguage("tr")}
          >
            Turkish
          </div>
       
          <div
            className={`grid-item ${i18n.language === "el" ? "bold" : ""}`}
            onClick={() => changeLanguage("el")}
          >
            Greek
          </div>
          <div
            className={`grid-item ${i18n.language === "ja" ? "bold" : ""}`}
            onClick={() => changeLanguage("ja")}
          >
            Japanese
          </div>
          <div
            className={`grid-item ${i18n.language === "ko" ? "bold" : ""}`}
            onClick={() => changeLanguage("ko")}
          >
            Korean
          </div>
          <div
            className={`grid-item ${i18n.language === "th" ? "bold" : ""}`}
            onClick={() => changeLanguage("th")}
          >
            Thai
          </div>
       
          <div
            className={`grid-item ${i18n.language === "vi" ? "bold" : ""}`}
            onClick={() => changeLanguage("vi")}
          >
            Vietnamese
          </div>
          <div
            className={`grid-item ${i18n.language === "zh" ? "bold" : ""}`}
            onClick={() => changeLanguage("zh")}
          >
            Chinese
          </div>
          <div
            className={`grid-item ${i18n.language === "ru" ? "bold" : ""}`}
            onClick={() => changeLanguage("ru")}
          >
            Russian
          </div>
          <div
            className={`grid-item ${i18n.language === "id" ? "bold" : ""}`}
            onClick={() => changeLanguage("id")}
          >
            Indonesian
          </div>
      
          <div
            className={`grid-item ${i18n.language === "hi" ? "bold" : ""}`}
            onClick={() => changeLanguage("hi")}
          >
            Hindi
          </div>
          <div
            className={`grid-item ${i18n.language === "bn" ? "bold" : ""}`}
            onClick={() => changeLanguage("bn")}
          >
            Bengali
          </div>
        </div>

        <button className="close-button" style={{color:theme}} onClick={handleCloseModal}>
          <MdClose size={20} /> {/* Use the desired icon component */}
        </button>
      </div>
    </div>
  );
};

export default Modal;
