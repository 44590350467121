import React, { useContext } from "react";
import moment from "moment";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './LikedRow.styles.scss';

const LikedRow = (props) => {
  const { theme } = useContext(UserContext);
  return (
    <div>
      <div className="userBar">
        <div className="partplusbutton">
          <div className="imageandtext">
            <div onClick={props.onSelect}>
              <img
                className="userPic"
                src={`https://marihuanadating.com/wld_uploads/${props.image}`}
              />
            </div>
            <div style={{ width: "80%", marginLeft: "10px" }}>
              <div onClick={props.onSelect}>
                <p style={{ color: theme }} className="name">
                  {props.first_name} {props.last_name}
                </p>
              </div>
              <p style={{ color: theme }} className="thedate">
                {moment(props.thetime * 1000).format("MMMM Do YYYY")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikedRow;
