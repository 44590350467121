import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import Footer from "../../components/Footer/Footer.component";
import { useTranslation } from "react-i18next";
import "./Privacy.styles.scss";
import privacyBackground from "../../assets/privacyimage.jpg";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import Background from "../../components/Background/Background.component";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <Background />
      </div>
      <div className="privacy-container">
        <h2>{t("l7")}</h2>
        <p className="privacy-paragraph">{t("m2")}</p>

        <p className="privacy-paragraph">{t("m3")} </p>

        <p className="privacy-paragraph">{t("m4")} </p>

        <p className="privacy-title">{t("m5")} </p>

        <p className="privacy-title">{t("m6")} </p>

        <p className="privacy-paragraph">{t("m7")} </p>

        <p className="privacy-paragraph">{t("m8")} </p>

        <p className="privacy-title">{t("m9")} </p>

        <p className="privacy-paragraph">{t("m10")} </p>

        <p className="privacy-title">{t("m11")} </p>

        <p className="privacy-paragraph">{t("m12")} </p>

        <p className="privacy-title">{t("m13")} </p>

        <p className="privacy-paragraph">{t("m14")} </p>

        <p className="privacy-title">{t("m15")} </p>

        <p className="privacy-paragraph">{t("m16")} </p>

        <p className="privacy-paragraph">{t("m17")} </p>

        <p className="privacy-paragraph">{t("m18")} </p>

        <p className="privacy-paragraph">{t("m19")} </p>

        <p className="privacy-title">{t("m20")} </p>

        <p className="privacy-title">{t("m21")} </p>

        <p className="privacy-paragraph">{t("m22")} </p>

        <p className="privacy-paragraph">{t("m23")} </p>

        <p className="privacy-title">{t("m24")} </p>

        <p className="privacy-paragraph">{t("m25")} </p>

        <p className="privacy-title">{t("m26")} </p>

        <p className="privacy-paragraph">{t("m27")} </p>

        <p className="privacy-title">{t("m28")} </p>

        <p className="privacy-paragraph">{t("m29")} </p>

        <p className="privacy-paragraph">{t("m30")} </p>

        <p className="privacy-paragraph">{t("m31")} </p>

        <p className="privacy-paragraph">{t("m32")} </p>

        <p className="privacy-paragraph">{t("m33")} </p>

        <p className="privacy-title">{t("m34")} </p>

        <p className="privacy-paragraph">{t("m35")} </p>

        <p className="privacy-title">{t("m36")} </p>

        <p className="privacy-paragraph">{t("m37")} </p>

        <p className="privacy-paragraph">{t("m38")} </p>

        <p className="privacy-paragraph">{t("m39")} </p>

        <p className="privacy-paragraph">{t("m40")} </p>

        <p className="privacy-paragraph">{t("m41")} </p>

        <p className="privacy-paragraph">{t("m42")} </p>

        <p className="privacy-paragraph">{t("m43")} </p>

        <p className="privacy-title">{t("m44")} </p>

        <p className="privacy-paragraph">{t("m45")} </p>

        <p className="privacy-title">{t("m46")} </p>

        <p className="privacy-paragraph">{t("m47")} </p>

        <p className="privacy-title">{t("m48")} </p>

        <p className="privacy-paragraph">{t("m49")} </p>

        <p className="privacy-paragraph">{t("m50")} </p>

        <p className="privacy-paragraph">{t("m51")} </p>

        <p className="privacy-title">{t("m52")} </p>

        <p className="privacy-paragraph">{t("m53")} </p>

        <p className="privacy-title">{t("m54")} </p>

        <p className="privacy-paragraph">{t("m55")} </p>

        <p className="privacy-paragraph">{t("m56")} </p>

        <p className="privacy-title">{t("m57")} </p>

        <p className="privacy-paragraph">{t("m58")} </p>

        <p className="privacy-paragraph">{t("m59")} </p>

        <p className="privacy-title">{t("m60")} </p>

        <p className="privacy-paragraph">{t("m61")} </p>
      </div>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default Privacy;
