import "./Dashboard.styles.scss";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserProvider";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import Header from "../../components/Header/Header.component";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/favicon.png";
import { requestPermission, requestToken } from "../../contexts/firebase";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPermission, setShowPermission] = useState(false);
  const {
    setLikes,
    setFilteredLikes,
    setLiked,
    setFilteredLiked,
    setBlocked,
    setFilteredBlocked,
    setConversations,
    setFilteredConversations,
    theme,
    setAllMessages,
    setRooms,
    setPhotos,
    setHobbies,
    setMessagesNotifications,
    setPeople,
    setNumberOfLikesNotifications,
    setUserID,
    setFirstName,
    setLastName,
    setDob,
    setGender,
    setOrientation,
    setEmail,
    setDatecreated,
    setLatitude,
    setLongitude,
    setProfilePhoto,
    setDescription,
    setNotifications,
    setAgemin,
    setAgemax,
    setDistancemax,
    setLocationbutton,
    setAgebutton,
    setMetricsys,
    setEthnicity,
    setRelationship,
    setRelationgoals,
    setWorkout,
    setReligion,
    setEducation,
    setSmoking,
    setMarijuana,
    setDrinking,
    setShowage,
    setfcmtoken,
  } = useContext(UserContext);

  function fetchallmessages(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getallmessages.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setAllMessages(result);
      });
  }

  function fetchrooms(userid) {
    return fetch("https://marihuanadating.com/endpoint-wld/getrooms.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setRooms(result);
      });
  }

  function fetchpeople(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getfilteredpeople.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setPeople(result);
      });
  }

  function fetchmessagenotifications(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getmessagenotifications.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setMessagesNotifications(result);
      });
  }

  function fetchconversations(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getconversationsnew.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setConversations(result);
        setFilteredConversations(result);
      });
  }

  function fetchhobbies(userid) {
    return fetch("https://marihuanadating.com/endpoint-wld/gethobbies.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        var array = [];

        for (let i = 0; i < result.length; i++) {
          array.push(JSON.stringify(result[i].hobby));
        }
        setHobbies(array);
      });
  }

  function fetchlikes(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getlikesspecial.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLikes(result);
        setFilteredLikes(result);
      });
  }

  function fetchblocked(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getblockedusersspecial.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setBlocked(result);
        setFilteredBlocked(result);
      });
  }

  function fetchliked(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getlikedspecial.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLiked(result);
        setFilteredLiked(result);
      });
  }

  function fetchlikesnotif(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getnumberoflikesnotifications.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setNumberOfLikesNotifications(result);
      });
  }

  function getphotos(userid) {
    return fetch("https://marihuanadating.com/endpoint-wld/getphotos.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setPhotos(result);
      });
  }

  function getloggeduserdata(userid) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/getloggeduserdetails.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setFirstName(result.first_name);
        setLastName(result.last_name);
        setDob(result.dob);
        setGender(result.gender);
        setOrientation(result.orientation);
        setEmail(result.email);
        setDatecreated(result.datecreated);
        setProfilePhoto(result.profile_photo);
        setDescription(result.description);
        setNotifications(JSON.stringify(result.notifications));
        setAgemin(result.agemin);
        setAgemax(result.agemax);
        setDistancemax(result.distancemax);
        setLocationbutton(JSON.stringify(result.locationbutton));
        setAgebutton(JSON.stringify(result.agebutton));
        setMetricsys(result.metricsys);
        setEthnicity(JSON.stringify(result.ethnicity));
        setRelationship(JSON.stringify(result.relationship));
        setRelationgoals(JSON.stringify(result.relationgoals));
        setWorkout(JSON.stringify(result.workout));
        setReligion(JSON.stringify(result.religion));
        setEducation(JSON.stringify(result.education));
        setSmoking(JSON.stringify(result.smoking));
        setMarijuana(JSON.stringify(result.marijuana));
        setDrinking(JSON.stringify(result.drinking));
        setShowage(JSON.stringify(result.showage));
      });
  }

  function getlocation(userToken) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          insertCoordinates(userToken, latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }

  function insertCoordinates(userid, lat, long) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/insertcoordinates.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
          latitude: lat,
          longitude: long,
        }),
      }
    );
  }

  function proceedWithoutNotifications() {
    navigate("/profile");
  }

  function insertFCMtoken(userid, token) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/insertfcmtoken.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
          fcmtoken: token,
        }),
      }
    );
  }

  React.useEffect(() => {
    const checkLoginStatus = async () => {
      const userToken = localStorage.getItem("userId");
      setUserID(userToken);
      if (userToken) {
        await getlocation(userToken);
        await getloggeduserdata(userToken);
        await getphotos(userToken);
        await fetchlikesnotif(userToken);
        await fetchliked(userToken);
        await fetchblocked(userToken);
        await fetchlikes(userToken);
        await fetchrooms(userToken);

        await fetchconversations(userToken);
        await fetchhobbies(userToken);
        await fetchmessagenotifications(userToken);

        await fetchpeople(userToken);
        await fetchallmessages(userToken);
        if ("Notification" in window) {
          // Get the current notification permission status
          const thepermission = Notification.permission;
          if (thepermission === "denied") {
            setShowPermission(true);
            requestPermission()
              .then((token) => {
                // Use the token here
                setfcmtoken(token);
                insertFCMtoken(userToken, token);
                if (token) {
                  navigate("/profile");
                }
              })
              .catch((error) => {
                console.error("Error getting permission or token:", error);
              });
          }
          if (thepermission === "default") {
            setShowPermission(true);

            requestPermission()
              .then((token) => {
                // Use the token here
                setfcmtoken(token);
                insertFCMtoken(userToken, token);
                if (token) {
                  navigate("/profile");
                }
              })
              .catch((error) => {
                console.error("Error getting permission or token:", error);
              });
          }
          if (thepermission === "granted") {
            requestToken()
              .then((token) => {
                // Use the token here
                setfcmtoken(token);
                insertFCMtoken(userToken, token);
                if (token) {
                  navigate("/profile");
                }
              })
              .catch((error) => {
                console.error("Error getting permission or token:", error);
              });
          }
        } else {
          console.log("Notifications not supported in this browser");
        }
      } else {
        navigate("/");
      }
    };

    checkLoginStatus();
  }, []);

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={"Weed Lovers Dating"} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 100,
          }}
        >
          <img
            src={Logo}
            style={{ width: "60%", marginLeft: "20%", marginRight: "20%" }}
            alt="Logo"
          />
          <p className="logotext" style={{ color: theme, fontSize: 35 }}>
            Weed Lovers Dating
          </p>
          <p style={{ color: theme, fontSize: 20 }}>{t("dt5")}</p>
          {showPermission ? (
            <div
              style={{
                background: "#fff",
                padding: 20,
                width: "70%",
                zIndex: 99,
                border: "1px solid #ccc",
                borderRadius: 5,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <p style={{ color: "red" }}>{t("dt7")}</p>
            </div>
          ) : null}
          {showPermission ? (
            <div
              style={{
                background: "#fff",
                padding: 20,
                width: "70%",
                zIndex: 99,
                border: "1px solid #ccc",
                borderRadius: 5,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={proceedWithoutNotifications}
            >
              <p style={{ color: "green" }}>{t("dt6")}</p>
            </div>
          ) : null}
        </div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default Dashboard;
