import React, { useContext } from "react";
import "./ThemeButton.styles.scss";
import { UserContext } from "../../contexts/UserProvider";

const ThemeButton = (props) => {
  const { theme } = useContext(UserContext);
  return (
    <div className="buttonContainer">
      <div
        className="Primarybutton"
        style={
          theme === props.color
            ? { backgroundColor: props.color, borderColor: props.color }
            : { borderColor: props.color }
        }
        onClick={props.onClick}
      >
        <p
          className="buttonText"
          style={
            theme === props.color ? { color: "#fff" } : { color: props.color }
          }
        >
          {props.text}
        </p>
      </div>
    </div>
  );
};

export default ThemeButton;
