import { useContext } from "react";
import "./HomeSpecifications.css";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import thegirl from "../../assets/thegirl.png";
import theapp from "../../assets/appimg.png";
const HomeSpecifications = (props) => {
  const { theme } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div className="third-chapter">
      <div className="column1">
        <p className="bluetitle" style={{ borderBottomColor: theme }}>
          {t("l23")}
        </p>
        <p className="greentitle" style={{ borderBottomColor: theme }}>
          {t("l24")}
        </p>
        <p className="greentitle" style={{ borderBottomColor: theme }}>
          {t("l25")}
        </p>
        <p className="greentitle" style={{ borderBottomColor: theme }}>
          {t("l26")}
        </p>
        <p className="greentitle" style={{ borderBottomColor: theme }}>
          {t("l27")}
        </p>
        <p className="greentitle" style={{ borderBottomColor: theme }}>
          {t("l28")}
        </p>
        <p className="greentitle" style={{ borderBottomColor: theme }}>
          {t("l29")}
        </p>
      </div>
      <div className="column2">
        <img src={thegirl} />
      </div>
      <div className="column3">
        <img src={theapp} />
      </div>
    </div>
  );
};

export default HomeSpecifications;
