import React, { useState, useContext, useRef } from "react";
import InputWithValidation from "../InputWithValidation/InputWithValidation.component";
import "./Register.styles.scss";
import Button from "../Button/Button.component";
import PlanButton from "../PlanButton/PlanButton.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import { AiFillFileImage } from "react-icons/ai";
import moment from "moment";
import { FormComponent, FormContainer } from "react-authorize-net";
import { BsGenderMale, BsGenderFemale } from "react-icons/bs";

const transactionkey = "23Rfr67TgeJ82sK7";
const apiloginkey = "952WrpUGm";

const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme, setUserID } = useContext(UserContext);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [nastere, setnastere] = useState(new Date());
  const [ageerror, setAgeerror] = useState(null);
  const [photoerror, setphotoerror] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [gender, setgender] = React.useState(0);
  const swiperRef = useRef(null);
  const [makeinactive, setmakeinactive] = useState(false);
  const [regMsg, setRegMsg] = useState("");
  const [amount, setAmount] = useState(0);
  const [plan, setPlan] = useState(false);
  const [status, setStatus] = useState(["", []]);
  const [alreadymsg, setAlreadymsg] = useState("");
  const [alreadymsgstatus, setAlreadymsgstatus] = useState("");

  const onErrorHandler = (response) => {
    setStatus(["failure", response.messages.message.map((err) => err.text)]);
    console.log(response);
  };

  const onSuccessHandler = (response) => {
    // Process API response on your backend...
    console.log(response);
    setStatus(["success", []]);
  };

  const handlePlan1 = () => {
    setAmount(9.95);
    setPlan(true);
  };

  const handlePlan2 = () => {
    setAmount(17.95);
    setPlan(true);
  };

  const handlePlan3 = () => {
    setAmount(29.85);
    setPlan(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setphotoerror(null);
      setPreviewURL(URL.createObjectURL(file));
    }
  };

  function gotoscreen2() {
    if (fname == "") {
      setFnameError(t("d3"));
      return;
    }
    if (lname == "") {
      setLnameError(t("d4"));
      return;
    }

    goToNextSlide();
  }

  function gotoscreen3() {
    let x = new Date().getFullYear() - nastere.getFullYear();
    if (x < 18) {
      setAgeerror(t("d5"));
    } else {
      goToNextSlide();
    }
  }

  const verifyEmail = () => {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/verifyregemail.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result === "alreadyregistered") {
          setAlreadymsg(t("l44"));
          return;
        }

        if (result === "youregisteredbefore") {
          setAlreadymsg(t("d6"));
          return;
        }
        if (result === "ok") {
          if (password == "") {
            setPasswordError(t("p19"));
            return;
          } else {
            goToNextSlide();
          }
        }
      });
  };

  function gotoscreen5() {
    if (email == "") {
      setEmailError(t("p9"));
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t("p17"));
      return;
    } else {
      verifyEmail();
    }
  }

  function gotoscreen6() {
    if (selectedFile) {
      goToNextSlide();
    } else {
      setphotoerror(t("x12"));
    }
  }

  // Function to navigate to the next slide
  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // Function to navigate to the previous slide
  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleFnameChange = (event) => {
    setFname(event.target.value);
    setFnameError("");
  };

  const handleBirth = (date) => {
    setAgeerror(null);
    setnastere(date);
  };

  const handleLnameChange = (event) => {
    setLname(event.target.value);
    setLnameError("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
    setAlreadymsg("");
    setAlreadymsgstatus("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setmakeinactive(true);
    const formattedBirthDate = `${nastere.getFullYear()}-${(
      "0" +
      (nastere.getMonth() + 1)
    ).slice(-2)}-${("0" + nastere.getDate()).slice(-2)}`;

    // Validate email and password
    if (!email) {
      setEmailError(t("p9"));
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(t("p17"));
      return;
    }
    if (!password) {
      setPasswordError(t("p18"));
      return;
    }

    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("firstName", fname);
      formData.append("lastName", lname);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("birth", formattedBirthDate);
      formData.append("gender", gender);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://marihuanadating.com/endpoint-wld/registerwithimage.php",
        true
      );

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            // Handle successful response
            console.log(xhr.responseText);
            setRegMsg(xhr.responseText);
          } else {
            console.log(xhr.responseText);
            setRegMsg(xhr.responseText);
          }
        }
      };

      xhr.send(formData);
    }
  };

  return (
    <div>
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        navigation={false}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <InputWithValidation
            label={t("d7")}
            type="text"
            value={fname}
            error={fnameError}
            onChange={handleFnameChange}
          />
          <InputWithValidation
            label={t("d8")}
            type="text"
            value={lname}
            error={lnameError}
            onChange={handleLnameChange}
          />
          <Button
            onClick={gotoscreen2}
            backgroundcolor={theme}
            color={"#fff"}
            text={t("x11")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <p
            style={{
              color: theme,
              marginVertical: 10,
              fontSize: 22,
              textAlign: "center",
            }}
          >
            {t("g3")}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DatePicker
              selected={nastere}
              onChange={(date) => handleBirth(date)}
            />
          </div>
          <p style={{ color: theme, textAlign: "center", margin: 20 }}>
            {ageerror}
          </p>
          <p
            style={{
              color: theme,
              textAlign: "center",
              fontSize: 20,
            }}
          >
            {t("x4")} {moment(nastere).format("MMMM Do YYYY")}
          </p>
          <div className="swiper-buttons">
            <Button
              onClick={goToPrevSlide}
              backgroundcolor={theme}
              color={"#fff"}
              text={t("x10")}
            />
            <Button
              onClick={gotoscreen3}
              backgroundcolor={theme}
              color={"#fff"}
              text={t("x11")}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <p
            style={{
              color: theme,
              marginVertical: 10,
              fontSize: 22,
              textAlign: "center",
            }}
          >
            {t("d11")}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: 50,
            }}
          >
            {gender == 0 ? (
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                }}
              >
                <div
                  style={{
                    backgroundColor: theme,
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    borderWidth: 1,
                    borderColor: theme,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BsGenderMale size={30} color={"#fff"} />
                </div>
                <p style={{ color: theme, marginTop: 10 }}>{t("d12")}</p>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,

                  cursor: "pointer",
                }}
                onClick={() => {
                  setgender(0);
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    borderWidth: 1,
                    borderColor: theme,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BsGenderMale size={30} color={theme} />
                </div>
                <p style={{ color: theme, marginTop: 10 }}>{t("d12")}</p>
              </div>
            )}
            {gender == 1 ? (
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                }}
              >
                <div
                  style={{
                    backgroundColor: theme,
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    borderWidth: 1,
                    borderColor: theme,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BsGenderFemale size={30} color={"#fff"} />
                </div>
                <p style={{ color: theme, marginTop: 10 }}>{t("d13")}</p>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,

                  cursor: "pointer",
                }}
                onClick={() => {
                  setgender(1);
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    borderWidth: 1,
                    borderColor: theme,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BsGenderFemale size={30} color={theme} />
                </div>
                <p style={{ color: theme, marginTop: 10 }}>{t("d13")}</p>
              </div>
            )}
          </div>
          <div className="swiper-buttons">
            <Button
              onClick={goToPrevSlide}
              backgroundcolor={theme}
              color={"#fff"}
              text={t("x10")}
            />
            <Button
              onClick={goToNextSlide}
              backgroundcolor={theme}
              color={"#fff"}
              text={t("x11")}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <InputWithValidation
            label={t("p15")}
            type="email"
            value={email}
            error={emailError}
            onChange={handleEmailChange}
          />

          <InputWithValidation
            label={t("p20")}
            type="password"
            value={password}
            error={passwordError}
            onChange={handlePasswordChange}
          />

          {alreadymsg ? (
            <p
              style={{
                color: theme,
                fontSize: 20,
                textAlign: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              {alreadymsg}
            </p>
          ) : (
            <div className="swiper-buttons">
              <Button
                onClick={goToPrevSlide}
                backgroundcolor={theme}
                color={"#fff"}
                text={t("x10")}
              />
              <Button
                onClick={gotoscreen5}
                backgroundcolor={theme}
                color={"#fff"}
                text={t("x11")}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <p
              style={{
                color: theme,
                marginVertical: 10,
                fontSize: 22,
                textAlign: "center",
              }}
            >
              {t("x5")}
            </p>

            {previewURL && (
              <img
                src={previewURL}
                alt="Preview"
                style={{
                  width: "20%",
                  height: "auto",
                  marginLeft: "40%",
                  marginRight: "40%",
                }}
                source={previewURL ? { uri: previewURL } : null}
              />
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  backgroundColor: theme,
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderWidth: 1,
                  borderColor: theme,
                  cursor: "pointer",
                }}
              >
                <label htmlFor="file-upload" className="custom-file-upload">
                  <AiFillFileImage
                    size={25}
                    color={"#fff"}
                    style={{ cursor: "pointer" }}
                  />
                </label>
                <input
                  className="photoupload"
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          {photoerror ? (
            <p style={{ color: theme, textAlign: "center", margin: 20 }}>
              {photoerror}
            </p>
          ) : null}

          <div className="swiper-buttons">
            <Button
              onClick={goToPrevSlide}
              backgroundcolor={theme}
              color={"#fff"}
              text={t("x10")}
            />
            <Button
              onClick={gotoscreen6}
              backgroundcolor={theme}
              color={"#fff"}
              text={t("x11")}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {plan ? (
            <div>
              {" "}
              <FormContainer
                environment="production"
                amount={amount}
                onError={onErrorHandler}
                onSuccess={onSuccessHandler}
                component={FormComponent}
                clientKey={transactionkey}
                apiLoginId={apiloginkey}
              />
              <p
                style={{ color: theme, cursor: "pointer" }}
                onClick={() => setPlan(false)}
              >
                {t("l49")}
              </p>
            </div>
          ) : (
            <div>
              {" "}
              <PlanButton text={"$9.95 /" + t("x6")} onFunction={handlePlan1} />
              <PlanButton
                text={"$17.95 /3" + " " + t("x7")}
                text2={t("x8") + " " + "40%"}
                onFunction={handlePlan2}
              />
              <PlanButton
                text={"$29.85 /6" + " " + t("x7")}
                text2={t("x8") + " " + "50%"}
                onFunction={handlePlan3}
              />
              {!makeinactive ? (
                <div className="swiper-buttons">
                  <Button
                    onClick={goToPrevSlide}
                    backgroundcolor={theme}
                    color={"#fff"}
                    text={t("x10")}
                  />

                  <Button
                    onClick={handleSubmit}
                    backgroundcolor={theme}
                    color={"#fff"}
                    text={t("x9")}
                    disabled={makeinactive}
                  />
                </div>
              ) : null}
            </div>
          )}

          <div>
            {regMsg === '"registrationsuccess"' ? (
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {t("d2")}
              </p>
            ) : null}

            {regMsg === '"registrationfailed"' ? (
              <p
                style={{
                  color: "red",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {t("d1")}
              </p>
            ) : null}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Register;
