import React, { useContext } from "react";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import SearchInput from "../../components/SearchInput/SearchInput.component";
import Empty from "../../components/Empty/Empty.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import BlockedRow from "../../components/BlockedRow/BlockedRow.component";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';

const BlockedUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    blocked,
    filteredBlocked,
    userid,
    removeUserFromBlocked,
    removeUserFromFilteredBlocked,
    filterBlockedUsers,
  } = useContext(UserContext);

  function _unblockUser(item) {
    removeUserFromBlocked(item);
    removeUserFromFilteredBlocked(item);

    return fetch("https://marihuanadating.com/endpoint-wld/unblockuser.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        theuser: item.id,
      }),
    });
  }

  const SearchFilterFunction = (text) => {
    filterBlockedUsers(text);
  };

  if (blocked.length == 0) {
    return (
      <div className="app-container">
        <div className="mobile-container">
          <Header title={t("b3")} hasgoback={true}/>
          <Empty>{t("b1")}</Empty>
          <BottomNavigation />
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("b3")} hasgoback={true}/>
        <SearchInput onSearchFilterFunction={SearchFilterFunction} />
        {filteredBlocked.map((item, key) => (
          <BlockedRow
            image={item.profile_photo}
            first_name={item.first_name}
            last_name={item.last_name}
            thetime={item.thetime}
            onSelect={() => {
              navigate(
                `/encounters/UserProfile?${queryString.stringify(item)}`
              );
            }}
            onUnblock={() => _unblockUser(item)}
          />
        ))}
        <div style={{height:'50px'}}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default BlockedUsers;
