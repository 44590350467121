import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCIhcqkoBk8g27PJenHKj5Fi5LEH62HlnU",
  authDomain: "weed-lovers-dating-bf1b5.firebaseapp.com",
  projectId: "weed-lovers-dating-bf1b5",
  storageBucket: "weed-lovers-dating-bf1b5.appspot.com",
  messagingSenderId: "755144878903",
  appId: "1:755144878903:web:649985c0059a2da52f88b2",
  measurementId: "G-97YXJY8WR7",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapid:
          "BBqIJmTUt9pvoBR1K3NRQp81a8Ia4vv_lm7xQsAfk5ufoQIpE1A6cebG3_Lw4xfIYOjh7VZWN-ooLRa58cdJzx8",
      })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
          } else {
            console.log("Failed to generate the app registration token");
          }
        })
        .catch((err) => {
          console.log("An error occured", err);
        });
    } else {
      console.log("Permission denied");
    }
  });
};

export const requestToken = () => {
  return getToken(messaging, {
    vapid:
      "BBqIJmTUt9pvoBR1K3NRQp81a8Ia4vv_lm7xQsAfk5ufoQIpE1A6cebG3_Lw4xfIYOjh7VZWN-ooLRa58cdJzx8",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log("Failed to generate the app registration token");
      }
    })
    .catch((err) => {
      console.log("An error occured", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
