import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserProvider";
import { useTranslation } from "react-i18next";
import LanguageButton from "../../components/LanguageButton/LanguageButton.component";
import "./Language.styles.scss";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";

const Language = (props) => {
  const { theme } = useContext(UserContext);
  const { i18n, t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("o21")} hasgoback={true}/>
        <div className="compartiment">
          <LanguageButton
            text="English"
            iconname="en"
            onClick={() => changeLanguage("en")}
          />
          <LanguageButton
            text="French"
            iconname="fr"
            onClick={() => changeLanguage("fr")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="German"
            iconname="de"
            onClick={() => changeLanguage("de")}
          />
          <LanguageButton
            text="Spanish"
            iconname="es"
            onClick={() => changeLanguage("es")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Portuguese"
            iconname="pt"
            onClick={() => changeLanguage("pt")}
          />
          <LanguageButton
            text="Dutch"
            iconname="nl"
            onClick={() => changeLanguage("nl")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Italian"
            iconname="it"
            onClick={() => changeLanguage("it")}
          />
          <LanguageButton
            text="Romanian"
            iconname="ro"
            onClick={() => changeLanguage("ro")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Afrikaans"
            iconname="af"
            onClick={() => changeLanguage("af")}
          />
          <LanguageButton
            text="Czech"
            iconname="cs"
            onClick={() => changeLanguage("cs")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Estonian"
            iconname="et"
            onClick={() => changeLanguage("et")}
          />

          <LanguageButton
            text="Icelandic"
            iconname="is"
            onClick={() => changeLanguage("is")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Croatian"
            iconname="hr"
            onClick={() => changeLanguage("hr")}
          />
          <LanguageButton
            text="Hungarian"
            iconname="hu"
            onClick={() => changeLanguage("hu")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Lithuanian"
            iconname="lt"
            onClick={() => changeLanguage("lt")}
          />
          <LanguageButton
            text="Latvian"
            iconname="lv"
            onClick={() => changeLanguage("lv")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Norwegian"
            iconname="no"
            onClick={() => changeLanguage("no")}
          />
          <LanguageButton
            text="Swedish"
            iconname="sv"
            onClick={() => changeLanguage("sv")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Finnish"
            iconname="fi"
            onClick={() => changeLanguage("fi")}
          />
          <LanguageButton
            text="Dannish"
            iconname="da"
            onClick={() => changeLanguage("da")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Slovakian"
            iconname="sk"
            onClick={() => changeLanguage("sk")}
          />
          <LanguageButton
            text="Slovenian"
            iconname="sl"
            onClick={() => changeLanguage("sl")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Polish"
            iconname="pl"
            onClick={() => changeLanguage("pl")}
          />

          <LanguageButton
            text="Turkish"
            iconname="tr"
            onClick={() => changeLanguage("tr")}
          />
        </div>
        <div className="compartiment">
          <LanguageButton
            text="Greek"
            iconname="el"
            onClick={() => changeLanguage("el")}
          />

          <LanguageButton
            text="Japanese"
            iconname="ja"
            onClick={() => changeLanguage("ja")}
          />
        </div>
        <div className="compartiment">
          <LanguageButton
            text="Korean"
            iconname="ko"
            onClick={() => changeLanguage("ko")}
          />
          <LanguageButton
            text="Thai"
            iconname="th"
            onClick={() => changeLanguage("th")}
          />
        </div>
        <div className="compartiment">
          <LanguageButton
            text="Vietnamese"
            iconname="vi"
            onClick={() => changeLanguage("vi")}
          />
          <LanguageButton
            text="Chinese"
            iconname="zh"
            onClick={() => changeLanguage("zh")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Russian"
            iconname="ru"
            onClick={() => changeLanguage("ru")}
          />
          <LanguageButton
            text="Indonesian"
            iconname="id"
            onClick={() => changeLanguage("id")}
          />
        </div>

        <div className="compartiment">
          <LanguageButton
            text="Hindi"
            iconname="hi"
            onClick={() => changeLanguage("hi")}
          />

          <LanguageButton
            text="Bengali"
            iconname="bn"
            onClick={() => changeLanguage("bn")}
          />
        </div>
        <div style={{height:'50px'}}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default Language;
