import { useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./routes/Home/Home.component";
import Privacy from "./routes/Privacy/Privacy.component";
import Terms from "./routes/Terms/Terms.component";
import Help from "./routes/Help/Help.component";
import DatingSecurely from "./routes/Dating Securely/DatingSecurely.component";
import Navigation from "./routes/Navigation/Navigation.component";
import SecondaryNav from "./routes/SecondaryNav/SecondaryNav.component";
import About from "./routes/About/About.component";
import Contact from "./routes/Contact/Contact.component";
import Blog from "./routes/Blog/Blog.component";
import Blogpost from "./routes/Blogpost/Blogpost.component";
import Dashboard from "./routes/Dashboard/Dashboard.component";
import { UserContext } from "./contexts/UserProvider";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.component";
import Settings from "./routes/Settings/Settings.component";
import FullChat from "./routes/FullChat/FullChat.component";
import Chat from "./routes/Chat/Chat.component";
import Encounters from "./routes/Encounters/Encounters.component";
import Profile from "./routes/Profile/Profile.component";
import BlockedUsers from "./routes/BlockedUsers/BlockedUsers.component";
import ProfileLikes from "./routes/ProfileLikes/ProfileLikes.component";
import ProfileLiked from "./routes/ProfileLiked/ProfileLiked.component";
import Language from "./routes/Language/Language.component";
import Theme from "./routes/Theme/Theme.component";
import Hobbies from "./routes/Hobbies/Hobbies.component";
import PhotoGallery from "./routes/PhotoGallery/PhotoGallery.component";
import AddProfilePhoto from "./routes/AddProfilePhoto/AddProfilePhoto.component";
import UserProfile from "./routes/UserProfile/UserProfile.component";
import ChangePass from "./routes/ChangePass/ChangePass.component";
import Expand from "./routes/Expand/Expand.component";
import ProtectedAdmin from "./components/ProtectedAdmin/ProtectedAdmin.component";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const App = () => {
  const {
    userid,
    setUserID,
    addMessageNotification,
    addNewMessageToChat,
    addNewConversationToFilteredConversations,
    addNewConversation,
    allMessages,
    setAllMessages,
    addNewRoom,
    rooms,
    setNumberOfLikesNotifications,
    setFilteredConversations,
    setConversations,
    setMessagesNotifications,
    setRooms,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyCIhcqkoBk8g27PJenHKj5Fi5LEH62HlnU",
    authDomain: "weed-lovers-dating-bf1b5.firebaseapp.com",
    projectId: "weed-lovers-dating-bf1b5",
    storageBucket: "weed-lovers-dating-bf1b5.appspot.com",
    messagingSenderId: "755144878903",
    appId: "1:755144878903:web:649985c0059a2da52f88b2",
    measurementId: "G-97YXJY8WR7",
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);

  onMessage(messaging, (payload) => {
    console.log("Message received. Payload:", payload);
    const data = payload.data;
    if (data.title === "MessageNotification") {
      addMessageNotification(data.senderid);
      const newMessage = {
        conversation_id: data.roomid,
        sender: data.senderid,
        thetime: data.createdAt,
        message: data.mess,
        mess_id: data.chatid,
      };
      addNewMessageToChat(newMessage);
      const newConversation = {
        fcmtoken: data.sender_fcmtoken,
        first_name: data.sender_first_name,
        last_name: data.sender_last_name,
        id: data.senderid,
        notifications: data.sender_notifications,
        profile_photo: data.sender_photo,
        message: data.mess,
        thetime: data.createdAt,
      };
      addNewConversationToFilteredConversations(newConversation);
      addNewConversation(newConversation);
      let allmessages = allMessages;
      // New object to add
      let newObj = {
        conversation_id: data.roomid,
        mess_id: data.chatid,
        message: data.mess,
        sender: data.senderid,
        thetime: Date.now() / 1000,
      };
      allmessages.push(newObj);
      allmessages.sort((obj1, obj2) => obj2.thetime - obj1.thetime);
      setAllMessages(allmessages);
      let roomExists = rooms.some((obj) => obj.roomid === data.roomid);

      if (!roomExists) {
        const newroom = {
          roomid: data.roomid,
          user1: data.senderid,
          user2: userid,
        };
        addNewRoom(newroom);
      }
    }

    // Handle the incoming notification payload here
    // You can update your UI or take any other actions
  });

  function fetchmessagenotifications() {
    const userid = localStorage.getItem("userId");
    if (userid) {
      return fetch(
        "https://marihuanadating.com/endpoint-wld/getmessagenotifications.php",
        {
          method: "post",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            id: userid,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setMessagesNotifications(result);
        });
    }
  }

  function fetchconversations() {
    const userid = localStorage.getItem("userId");
    if (userid) {
      return fetch(
        "https://marihuanadating.com/endpoint-wld/getconversationsnew.php",
        {
          method: "post",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            id: userid,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setConversations(result);
          setFilteredConversations(result);
        });
    }
  }

  function fetchlikesnotif() {
    const userid = localStorage.getItem("userId");
    if (userid) {
      return fetch(
        "https://marihuanadating.com/endpoint-wld/getnumberoflikesnotifications.php",
        {
          method: "post",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            id: userid,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setNumberOfLikesNotifications(result);
        });
    }
  }

  function fetchallmessages() {
    const userid = localStorage.getItem("userId");
    if (userid) {
      return fetch(
        "https://marihuanadating.com/endpoint-wld/getallmessages.php",
        {
          method: "post",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            id: userid,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setAllMessages(result);
        });
    }
  }

  function fetchrooms() {
    const userid = localStorage.getItem("userId");
    if (userid) {
      return fetch("https://marihuanadating.com/endpoint-wld/getrooms.php", {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          setRooms(result);
        });
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchlikesnotif();
        fetchconversations();
        fetchmessagenotifications();
        fetchrooms();
        fetchallmessages();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const ReadLocalStorage = () => {
    const userid = localStorage.getItem("userId");
    if (userid) {
      setUserID(userid);
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    ReadLocalStorage();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route
          index
          element={
            <ProtectedAdmin>
              <Home />
            </ProtectedAdmin>
          }
        />
      </Route>
      <Route
        path="dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="chat"
        element={
          <ProtectedRoute>
            <FullChat />
          </ProtectedRoute>
        }
      />
      <Route
        path="chat/:slug"
        element={
          <ProtectedRoute>
            <Chat />
          </ProtectedRoute>
        }
      />
      <Route
        path="encounters"
        element={
          <ProtectedRoute>
            <Encounters />
          </ProtectedRoute>
        }
      />
      <Route
        path="profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/change-password"
        element={
          <ProtectedRoute>
            <ChangePass />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/blocked-users"
        element={
          <ProtectedRoute>
            <BlockedUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/received-likes"
        element={
          <ProtectedRoute>
            <ProfileLikes />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/liked-users"
        element={
          <ProtectedRoute>
            <ProfileLiked />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/language"
        element={
          <ProtectedRoute>
            <Language />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/theme-color"
        element={
          <ProtectedRoute>
            <Theme />
          </ProtectedRoute>
        }
      />
      <Route
        path="profile/hobbies"
        element={
          <ProtectedRoute>
            <Hobbies />
          </ProtectedRoute>
        }
      />
      <Route
        path="profile/photo-gallery"
        element={
          <ProtectedRoute>
            <PhotoGallery />
          </ProtectedRoute>
        }
      />
      <Route
        path="profile/add-profile-photo"
        element={
          <ProtectedRoute>
            <AddProfilePhoto />
          </ProtectedRoute>
        }
      />
      <Route
        path="encounters/UserProfile"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<SecondaryNav />}>
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="help" element={<Help />} />
        <Route path="dating-securely" element={<DatingSecurely />} />
        <Route path="about-us" element={<About />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="expand-membership" element={<Expand />} />
        <Route path="blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<Blogpost />} />
      </Route>
    </Routes>
  );
};

export default App;
