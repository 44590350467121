import React, { useContext, useState } from "react";
import { AiFillFileImage } from "react-icons/ai";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";
import Button from "../../components/Button/Button.component";
import ProgressBar from "react-progress-bar-plus";

const AddProfilePhoto = () => {
  const { userid, setProfilePhoto, theme } = useContext(UserContext);
  const { t } = useTranslation();

  const [isUploading, setisUploading] = useState(false);
  const [showerror, setshowerror] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const handleFileChange = (event) => {
    setshowerror(false);
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file));
    }
  };

  function uploadImageToServer() {
    setisUploading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("userID", userid);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://marihuanadating.com/endpoint-wld/uploadprofilephotofromsite.php",
        true
      );

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            // Handle successful response
            console.log(xhr.responseText);
            setisUploading(false);
            setUploadProgress(0);
            setProfilePhoto(JSON.parse(xhr.responseText).profile_photo);
            setSelectedFile(null);
            setPreviewURL(null);
          } else {
            setshowerror(true);
            setUploadProgress(0);
            setisUploading(false);
            setPreviewURL(null);
          }
        }
      };

      xhr.send(formData);
    }
  }
  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("a9")} />
        <div className="safeArea">
          <div
            style={{
              flexDirection: "row",
              marginVertical: 5,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {isUploading ? (
              <div
                style={{
                  alignItems: "center",
                  width: "70%",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    color: theme,
                    textAlign: "center",
                    fontSize: 10,
                    marginTop: 10,
                  }}
                >
                  {t("a1")}
                </p>
                {uploadProgress > 0 && (
                  <ProgressBar
                    percent={uploadProgress}
                    autoIncrement
                    spinner={false}
                    style={{ margin: 5, color: theme, borderColor: theme }}
                  />
                )}
              </div>
            ) : null}
          </div>

          {previewURL && (
            <img
              src={previewURL}
              alt="Preview"
              style={{
                width: "60%",
                height: "auto",
                marginLeft: "20%",
                marginRight: "20%",
                marginTop: 50,
              }}
              source={previewURL ? { uri: previewURL } : null}
            />
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                backgroundColor: theme,
                width: 50,
                height: 50,
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderWidth: 1,
                borderColor: theme,
                cursor: "pointer",
              }}
            >
              <label htmlFor="file-upload" className="custom-file-upload">
                <AiFillFileImage
                  size={25}
                  color={"#fff"}
                  style={{ cursor: "pointer" }}
                />
              </label>
              <input
                className="photoupload"
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>
          {showerror ? (
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: theme,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 15,
                marginRight: 15,
              }}
            >
              There has been an error uploading the photo to the server. Please
              try again!
            </p>
          ) : null}
          {selectedFile ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 40,
                marginTop: 40,
              }}
            >
              <Button
                text={t("a8")}
                onClick={uploadImageToServer}
                backgroundcolor={theme}
                color={"#fff"}
              />
            </div>
          ) : null}
        </div>
        <div style={{ height: "50px" }}></div>

        <BottomNavigation />
      </div>
    </div>
  );
};

export default AddProfilePhoto;
