import React, { useContext, useState, useMemo, useRef } from "react";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import { UserContext } from "../../contexts/UserProvider";
import Logo from "../../assets/favicon.png";
import "./Encounters.styles.scss";
import TinderCard from "react-tinder-card";
import { BiSolidLike, BiSolidDislike } from "react-icons/bi";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Encounters = () => {
  const {
    people,
    addUserToLiked,
    addUserToFilteredLiked,
    userid,
    first_name,
    last_name,
    theme,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(people.length - 1);
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(people.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    updateCurrentIndex(index - 1);
  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < people.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  function addLike(card) {
    addUserToLiked(card);
    addUserToFilteredLiked(card);
    if (card.notifications == 1) {
      const serverKey =
        "AAAAr9Ikazc:APA91bGUAl5Ei_fcxeKQgY8nJC-lfQtIsu3x_mC0dzGNsRahLi7cJLq7HQq9BQTcYME9RCb8sEu4ZIMv7pD0kG18h-AGv1wTP4X2_QWcmC6OUFTcSH7veI51MguvpvOwdce-1bFBMzNJ";

      const fcmEndpoint = "https://fcm.googleapis.com/fcm/send";

      const notificationPayload = {
        notification: {
          title: "LikeNotification",
          body: card,
        },
        to: card.fcmtoken,
      };
      setTimeout(() => {
        axios
          .post(fcmEndpoint, notificationPayload, {
            headers: {
              Authorization: `key=${serverKey}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("Successfully sent FCM notification:", response.data);
          })
          .catch((error) => {
            console.error(
              "Error sending FCM notification:",
              error.response.data
            );
          });
      }, 10000); // 10 seconds delay
    }

    addlikenotification(card.id);
    return fetch("https://marihuanadating.com/endpoint-wld/addlike.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        theuser: card.id,
      }),
    });
  }

  function addlikenotification(iduser) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/addlikenotification.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: userid,
          friendid: iduser,
        }),
      }
    );
  }

  function addDislike(iduser) {
    return fetch("https://marihuanadating.com/endpoint-wld/adddislike.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        theuser: iduser,
      }),
    });
  }

  function age(birthdate) {
    const today = new Date();
    const thedate = new Date(Date.parse(birthdate));

    const age =
      today.getFullYear() -
      thedate.getFullYear() -
      (today.getMonth() < thedate.getMonth() ||
        (today.getMonth() === thedate.getMonth() &&
          today.getDate() < thedate.getDate()));
    return age;
  }

  if (people.length == 0) {
    return (
      <div className="app-container">
        <div className="mobile-container">
          <Header title={t("l43")} />

          <div className="pulse">
            <img
              src={Logo}
              style={{ width: "40%", marginLeft: "30%", marginRight: "30%" }}
              alt="Logo"
            />
          </div>
          <BottomNavigation />
        </div>
      </div>
    );
  }
  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("l43")} />
        <div className="cardContainer">
          {people.map((card, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              key={card.id}
              onSwipe={(dir) => swiped(dir, card.first_name, index)}
              onCardLeftScreen={() => outOfFrame(card.first_name, index)}
            >
              <div className="card">
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={`http://www.marihuanadating.com/wld_uploads/${card.profile_photo}`}
                />
                <h3
                  style={{ cursor: "pointer", fontSize: 20 }}
                  onClick={() => {
                    navigate(
                      `/encounters/UserProfile?${queryString.stringify(card)}`
                    );
                  }}
                >
                  {card.first_name}
                </h3>
                {card.showage == "1" ? (
                  <div style={{ position: "absolute", bottom: 90, left: 10 }}>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      {age(card.dob)}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="buttons">
                <button
                  onClick={() => {
                    swipe("left");
                    addDislike(card.id);
                  }}
                >
                  <BiSolidDislike color="red" />
                </button>

                <button
                  onClick={() => {
                    swipe("right");
                    addLike(card);
                  }}
                >
                  <BiSolidLike color="green" />
                </button>
              </div>
            </TinderCard>
          ))}
        </div>

        <BottomNavigation />
      </div>
    </div>
  );
};

export default Encounters;
