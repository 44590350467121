import React from "react";
import "./InputWithValidation.styles.scss";

const InputWithValidation = ({ label, type, value, error, onChange }) => {
  return (
    <div className="input-container">
    <label>{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={error ? 'input-field input-error' : 'input-field'}
      noValidate // Disable browser validation
      autoComplete="off" 
    />
    {error && <span className="error-message">{error}</span>}
  </div>
  );
};

export default InputWithValidation;
