import { useContext } from "react";
import "./Footer.styles.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserProvider";

const Footer = () => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
  const currentYear = new Date().getFullYear();
  const handleMouseEnter = (event) => {
    event.target.style.color = theme;
  };

  const handleMouseLeave = (event) => {
    event.target.style.color = "";
  };
  return (
    <div className="footer-container">
      <div className="delimiter"></div>
      <div className="grid-container">
        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/about-us"
          >
            {t("l5")}
          </Link>
        </div>
        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/contact-us"
          >
            {t("l15")}
          </Link>
        </div>
        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/blog"
          >
            {t("l12")}
          </Link>
        </div>
        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/dating-securely"
          >
            {t("l11")}
          </Link>
        </div>
        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/help"
          >
            {t("l14")}
          </Link>
        </div>

        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/terms"
          >
            {t("l13")}
          </Link>
        </div>

        <div className="grid-item">
          <Link
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            to="/privacy"
          >
            {t("l7")}
          </Link>
        </div>
      </div>
      <div className="delimiter"></div>
      <div className="copyright">
        &copy; <span className="year">{currentYear}</span> <span style={{color:"green"}}>Weed <span style={{color:"red"}}>Lovers</span> Dating
        LLC</span>
      </div>
    </div>
  );
};

export default Footer;
