import React, { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserProvider";
import Header from "../../components/Header/Header.component";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import Gradient from "../../components/Gradient/Gradient.component";
import DivNotSelected from "../../components/divNotSelected/divNotSelected.component";
import DivSelected from "../../components/divSelected/divSelected.component";
import Button from "../../components/Button/Button.component";
import RowInfo from "../../components/RowInfo/RowInfo.component";
import Switch from "react-switch";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    theme,
    userid,
    orientation,
    setOrientation,
    notifications,
    setNotifications,
    agemin,
    setAgemin,
    agemax,
    setAgemax,
    distancemax,
    setDistancemax,
    locationbutton,
    setLocationbutton,
    agebutton,
    setAgebutton,
    metricsys,
    setMetricsys,
    showage,
    setShowage,
    numberOfLikesNotifications,
    setPeople,
  } = useContext(UserContext);

  const [agevalues, setagevalues] = React.useState({
    min: parseInt(agemin),
    max: parseInt(agemax),
  });
  const [showgender, setShowgender] = React.useState(false);
  const [sliderOneValue, setsliderOneValue] = React.useState([
    parseInt(distancemax),
  ]);

  const [locationactive, setlocationactive] = React.useState(
    stringToBoolean(locationbutton)
  );
  const [ageactive, setageactive] = React.useState(stringToBoolean(agebutton));
  const [showageactive, setshowageactive] = React.useState(
    stringToBoolean(showage)
  );

  const [notifactive, setnotifactive] = React.useState(
    stringToBoolean(notifications)
  );

  const [msys, setmsys] = React.useState(metricsys);



  function stringToBoolean(string) {
    switch (string) {
      case "1":
        return true;
      case "0":
        return false;
    }
  }

  function toggleSwitchnot(value) {
    setnotifactive(value);
    _changenotificationsactivation(value);
  }

  function toggleSwitchshowage(value) {
    setshowageactive(value);
    _changeshowage(value);
  }

  function toggleSwitch(value) {
    setlocationactive(value);
    _changelocationactivation(value);
  }

  function agetoggleSwitch(value) {
    setageactive(value);
    _changeageactivation(value);
  }

  function _renderOrientation() {
    if (orientation == "0") return <p>{t("z1")}</p>;
    else if (orientation == "1") return <p>{t("z2")}</p>;
    else if (orientation == "3") return <p>{t("z3")}</p>;
    else if (orientation == "4") return <p>{t("z4")}</p>;
    else if (orientation == "5") return <p>{t("z5")}</p>;
    else if (orientation == "6") return <p>{t("z6")}</p>;
    else if (orientation == "7") return <p>{t("z7")}</p>;
  }

  function _changeOrientation(thevalue) {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/changeorientation.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          thevalue: thevalue,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setPeople(result);
      });
  }

  function _changeagePreferance(value1, value2) {
    if (agemin !== value1 || agemax !== value2) {
      setAgemin(JSON.stringify(value1));
      setAgemax(JSON.stringify(value2));

      return fetch(
        "https://marihuanadating.com/endpoint-wld/changeagepreferance.php",
        {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            userlogged: userid,
            valuemin: value1,
            valuemax: value2,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setPeople(result);
        });
    }
  }

  function _changeDistance(distancevalue) {
    if (distancemax !== distancevalue) {
      setDistancemax(JSON.stringify(distancevalue));

      return fetch(
        "https://marihuanadating.com/endpoint-wld/changedistance.php",
        {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            userlogged: userid,
            value: distancevalue,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setPeople(result);
        });
    }
  }

  function _changenotificationsactivation(value) {
    if (value == true) {
      var val = 1;
    } else {
      var val = 0;
    }

    setNotifications(val);

    return fetch(
      "https://marihuanadating.com/endpoint-wld/changenotifications.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          value: val,
        }),
      }
    );
  }

  function _changeshowage(value) {
    if (value == true) {
      var val = 1;
    } else {
      var val = 0;
    }

    setShowage(val);

    return fetch("https://marihuanadating.com/endpoint-wld/changeshowage.php", {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userlogged: userid,
        value: val,
      }),
    });
  }

  function _changelocationactivation(value) {
    if (value == true) {
      var val = 1;
    } else {
      var val = 0;
    }

    setLocationbutton(val);

    return fetch(
      "https://marihuanadating.com/endpoint-wld/changelocationbutton.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          value: val,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setPeople(result);
      });
  }

  function _changeageactivation(value) {
    if (value == true) {
      var val = 1;
    } else {
      var val = 0;
    }

    setAgebutton(val);

    return fetch(
      "https://marihuanadating.com/endpoint-wld/changeagebutton.php",
      {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userlogged: userid,
          value: val,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setPeople(result);
      });
  }

  function _changemetricsystem(thevalue) {
    if (metricsys !== thevalue) {
      setMetricsys(JSON.stringify(thevalue));

      return fetch(
        "https://marihuanadating.com/endpoint-wld/changemetricsystem.php",
        {
          method: "POST",
          headers: {
            "access-control-allow-origin": "*",
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            userlogged: userid,
            thevalue: thevalue,
          }),
        }
      );
    }
  }

  function _logOut() {
    // Alert.alert(t("w1"), t("z8"), [
    //   { text: t("y1"), onClick: () => _theloggingout() },
    //   { text: t("n1") },
    // ]);
  }

  async function _theloggingout() {
    fetch("https://marihuanadating.com/endpoint-wld/insertfcmtoken.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
        fcmtoken: "",
      }),
    });

    localStorage.removeItem("user_id");
    // props.navigation.dispatch(
    //   CommonActions.reset({
    //     index: 0,
    //     routes: [{ name: "Login" }],
    //   })
    // );
  }

  function _deleteAccount() {
    // Alert.alert(t("w1"), t("z9"), [
    //   { text: t("y1"), onClick: () => _theDeletion() },
    //   { text: t("n1") },
    // ]);
  }

  function _theDeletion() {
    return fetch("https://marihuanadating.com/endpoint-wld/deleteaccount.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson == "accountdeleted") {
          _theloggingout();
        }
      });
  }

  function multiSliderValuesChange(values) {
    setagevalues(values);
    _changeagePreferance(values.min, values.max);
  }

  function sliderOneValuesChange(value) {
    setsliderOneValue(value);
    _changeDistance(value);
  }

  var miles = Math.ceil(distancemax * 0.62137);
  return (
    <div className="app-container">
      <div className="mobile-container">
        <Header title={t("z34")} type="logout" />

        <p className="maintext" style={{ color: theme }}>
          {t("z10")}
        </p>

        <div className="maindiv">
          <div className="border">
            <p className="titlerow">{t("z11")}</p>

            <RowInfo
              title={_renderOrientation()}
              onClick={() => setShowgender(!showgender)}
              showoption={showgender}
            />
          </div>
          {showgender ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "90%",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: 10,
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {orientation == 0 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z1")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(0));
                    _changeOrientation(0);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z1")}
                    </p>
                  </DivNotSelected>
                </div>
              )}
              {orientation == 1 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z2")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(1));
                    _changeOrientation(1);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z2")}
                    </p>
                  </DivNotSelected>
                </div>
              )}

              {orientation == 3 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z3")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(3));
                    _changeOrientation(3);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z3")}
                    </p>
                  </DivNotSelected>
                </div>
              )}
              {orientation == 4 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z4")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(4));
                    _changeOrientation(4);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z4")}
                    </p>
                  </DivNotSelected>
                </div>
              )}
              {orientation == 5 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z5")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(5));
                    _changeOrientation(5);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z5")}
                    </p>
                  </DivNotSelected>
                </div>
              )}
              {orientation == 6 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z6")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(6));
                    _changeOrientation(6);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z6")}
                    </p>
                  </DivNotSelected>
                </div>
              )}
              {orientation == 7 ? (
                <div>
                  <DivSelected>
                    <p className="selectedText">{t("z7")}</p>
                  </DivSelected>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setOrientation(JSON.stringify(7));
                    _changeOrientation(7);
                  }}
                >
                  <DivNotSelected>
                    <p className="notSelectedText" style={{ color: theme }}>
                      {t("z7")}
                    </p>
                  </DivNotSelected>
                </div>
              )}
            </div>
          ) : null}

          <div className="border">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <p className="titlerow">{t("z12")}</p>

              <Gradient>
                <p>
                  {t("z13", {
                    agemin: agevalues.min,
                    agemax: agevalues.max,
                  })}
                </p>
              </Gradient>
            </div>
            <div
              style={{
                width: "80%",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10%",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <InputRange
                maxValue={90}
                minValue={18}
                step={1}
                value={agevalues}
                onChange={multiSliderValuesChange}
              
             
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              {agebutton == 1 ? (
                <div style={{ width: "80%" }}>
                  <Gradient>
                    <p numberOfLines={2}>{t("z14")}</p>
                  </Gradient>
                </div>
              ) : (
                <div style={{ width: "80%" }}>
                  <Gradient>
                    <p numberOfLines={2}>{t("z14")}</p>
                  </Gradient>
                </div>
              )}
            
              <Switch
                offColor="#000"
                onColor={theme}
                offHandleColor="#81b0ff"
                onHandleColor="#767577"
                uncheckedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="red"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                checkedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="aqua"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                className="react-switch"
                id="icon-switch"
                onChange={agetoggleSwitch}
                checked={ageactive}
              />
            </div>
          </div>
          <div className="border">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <p className="titlerow">{t("z15")}</p>

              {metricsys == 2 ? (
                <Gradient>
                  <p>{t("z16", { dist: distancemax })}</p>
                </Gradient>
              ) : (
                <Gradient>
                  <p>{t("z17", { dist: miles })}</p>
                </Gradient>
              )}
            </div>

            {metricsys == 2 ? (
              <div
                style={{
                  width: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10%",
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InputRange
                  maxValue={500}
                  minValue={0}
                  step={1}
                  value={distancemax}
                  style={{
                    track: {
                      backgroundColor: "#767577",
                    },
                    activeTrack: {
                      backgroundColor: theme,
                    },
                    slider: {
                      backgroundColor: theme,
                    },
                  }}
                  onChange={sliderOneValuesChange}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10%",
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <InputRange
                  maxValue={311}
                  minValue={0}
                  step={1}
                  value={miles}
                  style={{
                    track: {
                      backgroundColor: "#767577",
                    },
                    activeTrack: {
                      backgroundColor: theme,
                    },
                    slider: {
                      backgroundColor: theme,
                    },
                  }}
                  onChange={sliderOneValuesChange}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              {locationbutton == 1 ? (
                <div style={{ width: "80%" }}>
                  <Gradient>
                    <p numberOfLines={2}>{t("z14")}</p>
                  </Gradient>
                </div>
              ) : (
                <div style={{ width: "80%" }}>
                  <Gradient>
                    <p numberOfLines={2}>{t("z14")}</p>
                  </Gradient>
                </div>
              )}

              <Switch
                offColor="#000"
                onColor={theme}
                offHandleColor="#81b0ff"
                onHandleColor="#767577"
                uncheckedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="red"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                checkedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="aqua"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                className="react-switch"
                id="icon-switch"
                onChange={toggleSwitch}
                checked={locationactive}
              />
            </div>
          </div>

          <div className="border">
            <p className="titlerow">{t("z18")}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
                marginLeft: 60,
                marginRight: 60,
              }}
            >
              {msys == 1 ? (
                <div>
                  <div
                    style={{
                      backgroundColor: theme,
                      padding: "1px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    <p className="selectedText">{t("z19")}</p>
                  </div>
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setmsys(1);
                    _changemetricsystem(1);
                  }}
                >
                  <p className="notSelectedText" style={{ color: theme }}>
                    {t("z19")}
                  </p>
                </div>
              )}

              {msys == 2 ? (
                <div>
                  <div
                    style={{
                      backgroundColor: theme,
                      padding: "1px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    <p className="selectedText">{t("z20")}</p>
                  </div>
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setmsys(2);
                    _changemetricsystem(2);
                  }}
                >
                  <p className="notSelectedText" style={{ color: theme }}>
                    {t("z20")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <p className="maintext" style={{ color: theme }}>
          {t("z21")}
        </p>
        <div className="maindiv">
          <div className="border">
            <p className="titlerow">{t("z22")}</p>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/settings/change-password")}
            >
              <Gradient>
                <p>{t("z23")}</p>
              </Gradient>
            </div>
          </div>
          <div className="border">
            <p className="titlerow">{t("o21")}</p>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/settings/language")}
            >
              <Gradient>
                <p>{t("o22")}</p>
              </Gradient>
            </div>
          </div>
          <div className="border">
            <p className="titlerow">{t("o27")}</p>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/settings/theme-color")}
            >
              <Gradient>
                <p>{t("o28")}</p>
              </Gradient>
            </div>
          </div>
          <div className="border">
            <p className="titlerow">{t("b3")}</p>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/settings/blocked-users")}
            >
              <Gradient>
                <p>{t("z24")}</p>
              </Gradient>
            </div>
          </div>

          <div className="border">
            <p className="titlerow">{t("z25")}</p>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/settings/liked-users")}
            >
              <Gradient>
                <p>{t("z26")}</p>
              </Gradient>
            </div>
          </div>

          <div className="border">
            <div
              style={{
                justifyContent: "flex-start",
                flexDirection: "row",
                display:'flex'
              }}
            >
              <p className="titlerow">{t("ll1")}</p>
              {numberOfLikesNotifications !== 0 ? (
                <div
                  style={{
                    alignItems: "center",
                    height: 20,
                    width: 20,
                    backgroundColor: "red",
                    borderRadius: 10,
                    justifyContent:"center",
                    alignItems:"center",
                    display:'flex',
                    marginTop:10
                  }}
                >
                  <p style={{ fontSize: 14, color: "#fff" }}>
                    {numberOfLikesNotifications}
                  </p>
                </div>
              ) : null}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/settings/received-likes")}
            >
              <Gradient>
                <p>{t("ll2")}</p>
              </Gradient>
            </div>
          </div>

          <div className="border">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <p className="titlerow">{t("z27")}</p>

              <Switch
                offColor="#000"
                onColor={theme}
                offHandleColor="#81b0ff"
                onHandleColor="#767577"
                uncheckedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="red"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                checkedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="aqua"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                className="react-switch"
                id="icon-switch"
                onChange={toggleSwitchnot}
                checked={notifactive}
              />
            </div>

            {notifactive ? (
              <Gradient>
                <p>{t("z28")}</p>
              </Gradient>
            ) : (
              <Gradient>
                <p>{t("z29")}</p>
              </Gradient>
            )}
          </div>
          <div className="border">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <p className="titlerow">{t("z30")}</p>

              <Switch
                offColor="#000"
                onColor={theme}
                offHandleColor="#81b0ff"
                onHandleColor="#767577"
                uncheckedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="red"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                checkedIcon={
                  <svg
                    viewBox="0 0 10 10"
                    height="100%"
                    width="100%"
                    fill="aqua"
                  >
                    <circle r={3} cx={5} cy={5} />
                  </svg>
                }
                className="react-switch"
                id="icon-switch"
                onChange={toggleSwitchshowage}
                checked={showageactive}
              />
            </div>

            {showageactive ? (
              <Gradient>
                <p>{t("z31")}</p>
              </Gradient>
            ) : (
              <Gradient>
                <p>{t("z32")}</p>
              </Gradient>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            text={t("z33")}
            onClick={_deleteAccount}
            color={"#fff"}
            backgroundcolor={theme}
          />
        </div>
        <div style={{ height: "50px" }}></div>

        <BottomNavigation />
      </div>
    </div>
  );
};

export default Settings;
