import React, { useContext } from "react";
import moment from "moment";
import { UserContext } from "../../contexts/UserProvider";
import "./BlockedRow.styles.scss";
import { useTranslation } from "react-i18next";

const BlockedRow = (props) => {
  const { theme } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="userBar">
        <div className="partplusbutton">
          <div className="imageandtext">
            <div onClick={props.onSelect}>
              <img
                className="userPic"
                src={`https://marihuanadating.com/wld_uploads/${props.image}`}
              />
            </div>
            <div style={{ width: "80%", marginLeft: "10px" }}>
              <div onClick={props.onSelect}>
                <p style={{ color: theme }} className="name">
                  {props.first_name} {props.last_name}
                </p>
              </div>
              <p style={{ color: theme }} className="thedate">
                {moment(props.thetime * 1000).format("MMMM Do YYYY")}
              </p>
            </div>
          </div>

          <div onClick={props.onUnblock}>
            <p
              style={{
                color: theme,
                borderColor: theme,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                borderRadius: 5,
                fontSize: 16,
                cursor: "pointer",
              }}
            >
              {t("b2")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedRow;
