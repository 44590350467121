import React, { useContext, useState, useEffect } from "react";
import BottomNavigation from "../../components/bottomNavigation/bottomNavigation.component";
import Header from "../../components/Header/Header.component";
import { GiDiamondRing } from "react-icons/gi";
import {
  FaCannabis,
  FaBirthdayCake,
  FaChurch,
  FaSmoking,
  FaMapMarkerAlt,
  FaDumbbell,
} from "react-icons/fa";
import { BiSolidQuoteAltRight, BiSolidDrink } from "react-icons/bi";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { IoMdSchool, IoMdFlag } from "react-icons/io";
import { IoHandLeftSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { AiOutlineSkin, AiFillHeart } from "react-icons/ai";
import { UserContext } from "../../contexts/UserProvider";
import moment from "moment";
import DivNotSelected from "../../components/divNotSelected/divNotSelected.component";
import "./UserProfile.styles.scss";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    userid,
    addUserToBlocked,
    addUserToFilteredBlocked,
    latitude,
    longitude,
    blocked,
    metricsys,
    theme,
  } = useContext(UserContext);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const item = {
    id: params.id,
    dob: params.dob,
    first_name: params.first_name,
    last_name: params.last_name,
    showage: params.showage,
    latitude: params.latitude,
    longitude: params.longitude,
    ethnicity: params.ethnicity,
    religion: params.religion,
    education: params.education,
    relationgoals: params.relationgoals,
    relationship: params.relationship,
    smoking: params.smoking,
    drinking: params.drinking,
    marijuana: params.marijuana,
    workout: params.workout,
    description: params.description,
    profile_photo: params.profile_photo,
  };
  const [photosData, setphotosData] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  const existingBlockedUser = blocked.find((user) => user.id === item.id);

  const fetchphotos = () => {
    fetch("https://marihuanadating.com/endpoint-wld/getphotos.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: item.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setphotosData(result);
      });
  };

  const fetchhobbies = async () => {
    return fetch("https://marihuanadating.com/endpoint-wld/gethobbies.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: item.id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        var array = [];

        for (let i = 0; i < result.length; i++) {
          array.push(JSON.stringify(result[i].hobby));
        }

        setHobbies(array);
      });
  };

  useEffect(() => {
    fetchphotos();
  }, []);

  useEffect(() => {
    fetchhobbies();
  }, []);

  function _renderEducation() {
    switch (item.education) {
      case "1":
        return <p>{t("k6")}</p>;
        break;
      case "2":
        return <p>{t("k7")}</p>;
        break;
      case "3":
        return <p>{t("k8")}</p>;
        break;
      case "4":
        return <p>{t("k9")}</p>;
        break;
      case "5":
        return <p>{t("k10")}</p>;
        break;
    }
  }

  function _renderReligion() {
    switch (item.religion) {
      case "1":
        return <p>{t("k11")}</p>;
        break;
      case "2":
        return <p>{t("k12")}</p>;
        break;
      case "3":
        return <p>{t("k13")}</p>;
        break;
      case "4":
        return <p>{t("k14")}</p>;
        break;
      case "5":
        return <p>{t("k15")}</p>;
        break;
    }
  }

  function _renderRelation() {
    switch (item.relationship) {
      case "1":
        return <p>{t("k16")}</p>;
        break;
      case "2":
        return <p>{t("k17")}</p>;
        break;
      case "3":
        return <p>{t("k18")}</p>;
        break;
      case "4":
        return <p>{t("k19")}</p>;
        break;
      case "5":
        return <p>{t("k20")}</p>;
        break;
      case "6":
        return <p>{t("k21")}</p>;
        break;
      case "7":
        return <p>{t("k22")}</p>;
        break;
    }
  }
  function _renderSmoke() {
    switch (item.smoking) {
      case "1":
        return <p>{t("n1")}</p>;
        break;
      case "2":
        return <p>{t("k23")}</p>;
        break;
      case "3":
        return <p>{t("k24")}</p>;
        break;
      case "4":
        return <p>{t("k25")}</p>;
        break;
    }
  }
  function _renderDrink() {
    switch (item.drinking) {
      case "1":
        return <p>{t("k26")}</p>;
        break;
      case "2":
        return <p>{t("k27")}</p>;
        break;
      case "3":
        return <p>{t("k28")}</p>;
        break;
      case "4":
        return <p>{t("k29")}</p>;
        break;
    }
  }

  function _renderCannabis() {
    switch (item.marijuana) {
      case "1":
        return <p>{t("n1")}</p>;
        break;
      case "2":
        return <p>{t("k30")}</p>;
        break;
      case "3":
        return <p>{t("y1")}</p>;
        break;
    }
  }

  function _renderEthnicity() {
    switch (item.ethnicity) {
      case "1":
        return <p>{t("k1")}</p>;
        break;
      case "2":
        return <p>{t("k2")}</p>;
        break;
      case "3":
        return <p>{t("k3")}</p>;
        break;
      case "4":
        return <p>{t("k4")}</p>;
        break;
      case "5":
        return <p>{t("k5")}</p>;
        break;
    }
  }

  function _renderWorkout() {
    switch (item.workout) {
      case "1":
        return <p>{t("k36")}</p>;
        break;
      case "2":
        return <p>{t("k37")}</p>;
        break;
      case "3":
        return <p>{t("k30")}</p>;
        break;
      case "4":
        return <p>{t("k29")}</p>;
        break;
    }
  }

  function _renderRelationgoals() {
    switch (item.relationgoals) {
      case "1":
        return <p>{t("k38")}</p>;
        break;
      case "2":
        return <p>{t("k39")}</p>;
        break;
      case "3":
        return <p>{t("k40")}</p>;
        break;
      case "4":
        return <p>{t("k41")}</p>;
        break;
      case "5":
        return <p>{t("k42")}</p>;
        break;
      case "6":
        return <p>{t("k43")}</p>;
        break;
    }
  }

  function _reportUser() {
    navigate("Report", {
      theuser: item,
    });
  }

  function _blockUser() {
    _theblocking();
  }

  function _theblocking() {
    addUserToBlocked(item);
    addUserToFilteredBlocked(item);

    return fetch("https://marihuanadating.com/endpoint-wld/blockuser.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
        blockedid: item.id,
      }),
    });
  }

  function renderHobby(item) {
    switch (item) {
      case "1":
        return <p>{t("h1")}</p>;
        break;
      case "2":
        return <p>{t("h2")}</p>;
        break;
      case "3":
        return <p>{t("h3")}</p>;
        break;
      case "4":
        return <p>{t("h4")}</p>;
        break;
      case "5":
        return <p>{t("h5")}</p>;
        break;
      case "6":
        return <p>{t("h6")}</p>;
        break;
      case "7":
        return <p>{t("h7")}</p>;
        break;
      case "8":
        return <p>{t("h8")}</p>;
        break;
      case "9":
        return <p>{t("h9")}</p>;
        break;
      case "10":
        return <p>{t("h10")}</p>;
        break;
      case "11":
        return <p>{t("h11")}</p>;
        break;
      case "12":
        return <p>{t("h12")}</p>;
        break;
      case "13":
        return <p>{t("h13")}</p>;
        break;
      case "14":
        return <p>{t("h14")}</p>;
        break;
      case "15":
        return <p>{t("h15")}</p>;
        break;
      case "16":
        return <p>{t("h16")}</p>;
        break;
      case "17":
        return <p>{t("h17")}</p>;
        break;
      case "18":
        return <p>{t("h18")}</p>;
        break;
      case "19":
        return <p>{t("h19")}</p>;
        break;
      case "20":
        return <p>{t("h20")}</p>;
        break;
      case "21":
        return <p>{t("h21")}</p>;
        break;
      case "22":
        return <p>{t("h22")}</p>;
        break;
      case "23":
        return <p>{t("h23")}</p>;
        break;
      case "24":
        return <p>{t("h24")}</p>;
        break;
      case "25":
        return <p>{t("h25")}</p>;
        break;
      case "26":
        return <p>{t("h26")}</p>;
        break;
      case "27":
        return <p>{t("h27")}</p>;
        break;
      case "28":
        return <p>{t("h28")}</p>;
        break;
      case "29":
        return <p>{t("h29")}</p>;
        break;
      case "30":
        return <p>{t("h30")}</p>;
        break;
      case "31":
        return <p>{t("h31")}</p>;
        break;
      case "32":
        return <p>{t("h32")}</p>;
        break;
      case "33":
        return <p>{t("h33")}</p>;
        break;
      case "34":
        return <p>{t("h34")}</p>;
        break;
      case "35":
        return <p>{t("h35")}</p>;
        break;
      case "36":
        return <p>{t("h36")}</p>;
        break;
    }
  }

  var earthRadius = 6371;
  var latitudeFrom = (latitude * Math.PI) / 180;
  var longitudeFrom = (longitude * Math.PI) / 180;
  var latitudeTo = (item.latitude * Math.PI) / 180;
  var longitudeTo = (item.longitude * Math.PI) / 180;
  var latitudeDelta = ((item.latitude - latitude) * Math.PI) / 180;
  var longitudeDelta = ((item.longitude - longitude) * Math.PI) / 180;

  var a =
    Math.sin(latitudeDelta / 2) * Math.sin(latitudeDelta / 2) +
    Math.cos(latitudeFrom) *
      Math.cos(latitudeTo) *
      Math.sin(longitudeDelta / 2) *
      Math.sin(longitudeDelta / 2);

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  var d = Math.ceil(earthRadius * c);

  var x =
    Math.pow(Math.cos(latitudeTo) * Math.sin(longitudeDelta), 2) +
    Math.pow(
      Math.cos(latitudeFrom) * Math.sin(latitudeTo) -
        Math.sin(latitudeFrom) *
          Math.cos(latitudeTo) *
          Math.cos(longitudeDelta),
      2
    );
  var y =
    Math.sin(latitudeFrom) * Math.sin(latitudeTo) +
    Math.cos(latitudeFrom) * Math.cos(latitudeTo) * Math.cos(longitudeDelta);

  var angle = Math.atan2(Math.sqrt(x), y);
  var distance = Math.ceil((angle * earthRadius) / 1000);
  var miles = Math.ceil(d * 0.62137);

  return (
    <div className="app-container">
      <div className="mobile-container">
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img
              src={`https://www.marihuanadating.com/wld_uploads/${tempimg}`}
              className="fullScreenImage"
            />
          </div>
        ) : null}
        <Header
          type="chat"
          slug={item.first_name + "." + item.last_name}
          params={item}
          hasgoback={true}
        />

        <div style={{ flexDirection: "row", zIndex: 0, display: "flex" }}>
          <div style={{ width: "85%", height: 550, display: "flex" }}>
            <div
              style={{
                background: `linear-gradient(to bottom, ${theme}, #ddd, ${theme})`,
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                borderBottomRightRadius: 100,
                borderTopRightRadius: 280,
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontSize: 20,
                  marginLeft: 5,
                  marginRight: 5,
                  fontWeight: "bold",
                }}
              >
                {item.first_name} {item.last_name}
              </p>
              {item.showage == "1" ? (
                <div
                  style={{
                    flexDirection: "row",
                    margin: 5,
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FaBirthdayCake color="#fff" size={15} />
                  <p
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      fontSize: 18,
                      color: "#fff",
                    }}
                  >
                    {moment(item.dob).format("MMMM Do YYYY")}
                  </p>
                </div>
              ) : null}

              {latitude !== null &&
              longitude !== null &&
              item.latitude !== null &&
              item.longitude !== null ? (
                <div
                  style={{
                    flexDirection: "row",
                    margin: 5,
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FaMapMarkerAlt color="#fff" size={15} />
                  {metricsys == 2 ? (
                    <p
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        fontSize: 18,
                        color: "#fff",
                      }}
                    >
                      {d} km {t("u3")}{" "}
                    </p>
                  ) : (
                    <p
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                        fontSize: 18,
                        color: "#fff",
                      }}
                    >
                      {miles} {t("z19")} {t("u3")}{" "}
                    </p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div
            style={{
              width: "30%",
              height: 550,
              backgroundColor: "#ddd",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
            }}
          >
            {existingBlockedUser ? (
              <p
                style={{
                  color: "red",
                  fontSize: 16,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <IoHandLeftSharp color="red" size={16} /> {t("u4")}
              </p>
            ) : (
              <div onClick={() => _blockUser()}>
                <p
                  style={{
                    color: theme,
                    fontSize: 16,
                    marginLeft: 10,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                >
                  <IoHandLeftSharp color={theme} size={16} /> Block
                </p>
              </div>
            )}

            <div onClick={() => _reportUser()}>
              <p
                style={{
                  color: theme,
                  fontSize: 16,
                  marginLeft: 10,
                  marginRight: 10,
                  cursor: "pointer",
                }}
              >
                <IoMdFlag color={theme} size={16} /> {t("r7")}
              </p>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: 100,
              zIndex: 999999,
              width: "100%",
              height: 400,
            }}
          >
            <div
              style={{
                height: 400,
                width: "100%",
              }}
            >
              <img
                style={{ height: 400, width: "100%", objectFit: "cover" }}
                src={`https://marihuanadating.com/wld_uploads/${item.profile_photo}`}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ddd",
            paddingTop: 50,
          }}
        >
          {item.description !== "" && item.description !== null ? (
            <div
              style={{
                height: "auto",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p
                style={{
                  color: theme,
                  fontSize: 20,
                  fontStyle: "italic",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                {item.description}
              </p>
              <div style={{ position: "absolute", right: 0, opacity: 0.1 }}>
                <BiSolidQuoteAltRight color={theme} size={120} />
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  borderColor: theme,
                  borderWidth: 1,
                }}
              ></div>
            </div>
          ) : null}
          {item.relationship !== null ||
          item.ethnicity !== null ||
          item.education !== null ||
          item.religion !== null ||
          item.relationgoals !== null ? (
            <div className="border">
              <p style={{ color: theme }} className="maintext">
                {t("k45")}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  marginBottom: 5,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {item.relationship !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <AiFillHeart color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderRelation()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.ethnicity !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <AiOutlineSkin color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderEthnicity()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.education !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <IoMdSchool color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderEducation()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.religion !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FaChurch color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderReligion()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.relationgoals !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <GiDiamondRing color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderRelationgoals()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}
              </div>
            </div>
          ) : null}

          {item.workout !== null ||
          item.drinking !== null ||
          item.smoking !== null ||
          item.marijuana !== null ? (
            <div className="border">
              <p className="maintext" style={{ color: theme }}>
                {t("k46")}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  marginBottom: 5,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {item.workout !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FaDumbbell color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderWorkout()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.marijuana !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FaCannabis color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderCannabis()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.smoking !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FaSmoking color={theme} size={15} />
                      <p style={{ color: theme }}>{_renderSmoke()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}

                {item.drinking !== null ? (
                  <DivNotSelected>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <BiSolidDrink size={15} color={theme} />
                      <p style={{ color: theme }}>{_renderDrink()}</p>
                    </div>
                  </DivNotSelected>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        {hobbies.length !== 0 ? (
          <div className="border">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginVertical: 10,
              }}
            >
              <p className="maintext" style={{ color: theme }}>
                {t("k60")}
              </p>
            </div>
            <div
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                display: "flex",
              }}
            >
              {hobbies.map((item, key) => (
                <p
                  key={key}
                  style={{
                    color: theme,
                    fontSize: 20,
                    padding: 7,
                    borderColor: theme,
                    borderStyle: "solid",
                    borderRadius: 10,
                    borderWidth: 1,
                    marginTop: 5,
                    marginBottom: 5,
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  {renderHobby(item)}
                </p>
              ))}
            </div>
          </div>
        ) : null}

        {photosData.length !== 0 ? (
          <div className="border">
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginVertical: 10,
                display: "flex",
              }}
            >
              <p className="maintext" style={{ color: theme }}>
                {t("k61")}
              </p>
            </div>
            <div
              style={{
                flexWrap: "wrap",
                flexDirection: "row",
                display: "flex",
              }}
            >
              {photosData.map((item, key) => (
                <div
                  key={key}
                  style={{
                    width: "30%",
                    aspectRatio: 1,
                    margin: "1.66%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ flex: 1 }}
                    onClick={() => {
                      handleThumbnailPress();
                      setTempimg(item.thepath);
                    }}
                  >
                    <img
                      style={{
                        flex: 1,
                        objectFit: "cover",
                        display: "flex",
                        width: "100%",
                        height: 150,
                      }}
                      src={`https://www.marihuanadating.com/wld_uploads/${item.thepath}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div style={{ height: "50px" }}></div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export default UserProfile;
