import React, { useState, useContext, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import ThemeEditor from "../../components/ThemeEditor/ThemeEditor.component";
import { Fragment } from "react";
import Logo from "../../assets/oneicon.png";
import { MdColorLens } from "react-icons/md";
import "./SecondaryNav.styles.scss";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";

const SecondaryNav = () => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to the window
    const handleScroll = () => {
      // Calculate the scroll position in pixels
      const scrollY = window.scrollY;

      // Determine whether to add or remove the "hidden" class based on scroll position
      if (scrollY >= window.innerHeight * 0.3) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleThemeEditor = () => {
    setPanelOpen(false);
  };

  const openThemeEditor = () => {
    setPanelOpen(true);
  };

  return (
    <Fragment>
      <div className={`secondary-navigation ${scrolling ? "hidden" : ""}`}>
        <Link className="secondary-logo-container" to="/">
          <img src={Logo} className="secondary-logo" alt="Logo" />
          <p className="logotext" style={{ color: "green" }}>
            Weed <span style={{ color: "red" }}>Lovers</span> Dating
          </p>
        </Link>
        <div className="secondary-nav-links-container">
          <div
            className="secondary-nav-link"
            style={{ alignItems: "center", display: "flex", color: "#fff" }}
            onClick={setPanelOpen}
          >
            <MdColorLens size={20} color={"#fff"} /> <p>{t("o27")}</p>
          </div>

          <div className="secondary-nav-link"></div>
        </div>
      </div>

      <ThemeEditor
        isPanelOpen={isPanelOpen}
        toggleThemeEditor={toggleThemeEditor}
      />
      <Outlet />
    </Fragment>
  );
};

export default SecondaryNav;
