import { useContext } from "react";
import FooterLanguage from "../../components/FooterLanguage/FooterLanguage.component";
import Footer from "../../components/Footer/Footer.component";
import { useTranslation } from "react-i18next";
import "./DatingSecurely.styles.scss";
import dsBackground from "../../assets/datingsecure.jpg";
import { UserContext } from "../../contexts/UserProvider";
import AppLinks from "../../components/AppLinks/AppLinks.component";
import Background from "../../components/Background/Background.component";

const DatingSecurely = () => {
  const { t } = useTranslation();
  const { theme } = useContext(UserContext);
  return (
    <div>
      <div className="contact-container">
        <div className="contact-gradient-overlay"></div>
        <Background />
      </div>
      <div className="dating-container">
        <h2>{t("l11")}</h2>
        <p className="dating-paragraph">{t("ds2")}</p>

        <p className="dating-title">1.{t("ds3")}</p>

        <p className="dating-paragraph">{t("ds4")}</p>

        <p className="dating-title">2.{t("ds5")}</p>

        <p className="dating-paragraph">{t("ds6")}</p>

        <p className="dating-title">3.{t("ds7")}</p>

        <p className="dating-paragraph">{t("ds8")}</p>

        <p className="dating-title">4.{t("ds9")}</p>

        <p className="dating-paragraph">{t("ds10")}</p>
        <ul>
          <li className="dating-paragraph">{t("ds11")}</li>
          <li className="dating-paragraph">{t("ds12")}</li>
          <li className="dating-paragraph">{t("ds13")}</li>
          <li className="dating-paragraph">{t("ds14")}</li>
          <li className="dating-paragraph">{t("ds15")}</li>
        </ul>

        <p className="dating-title">5.{t("ds16")}</p>

        <p className="dating-paragraph">{t("ds17")}</p>

        <p className="dating-title">6.{t("ds18")}</p>

        <p className="dating-paragraph">{t("ds19")}</p>

        <p className="dating-title">RAINN’s National Sexual Assault Hotline</p>
        <p className="dating-paragraph">
          1-800-656-4673 |{" "}
          <a style={{ color: theme }} href="https://hotline.rainn.org/online">
            online.rainn.org
          </a>{" "}
          |{" "}
          <a style={{ color: theme }} href="https://www.rainn.org/">
            www.rainn.org
          </a>
        </p>

        <p className="dating-title">National Domestic Violence Hotline</p>
        <p className="dating-paragraph">
          1-800-799-7233 or 1-800-787-3224 |{" "}
          <a style={{ color: theme }} href="https://www.thehotline.org/">
            www.thehotline.org
          </a>
        </p>

        <p className="dating-title">National Human Trafficking Hotline</p>
        <p className="dating-paragraph">
          1-888-373-7888 |{" "}
          <a
            style={{ color: theme }}
            href="https://humantraffickinghotline.org/"
          >
            www.humantraffickinghotline.org
          </a>
        </p>

        <p className="dating-title">National Sexual Violence Resource Center</p>
        <p className="dating-paragraph">
          1-877-739-3895 |{" "}
          <a style={{ color: theme }} href="https://www.nsvrc.org/">
            www.nsvrc.org
          </a>
        </p>

        <p className="dating-title">Cyber Civil Rights Initiative</p>
        <p className="dating-paragraph">
          1-844-878-2274 |{" "}
          <a style={{ color: theme }} href="https://www.cybercivilrights.org/">
            www.cybercivilrights.org
          </a>
        </p>

        <p className="dating-title">
          VictimConnect - Crime Victim Resource Center
        </p>
        <p className="dating-paragraph">
          1-855-484-2846 |{" "}
          <a style={{ color: theme }} href="https://victimconnect.org/">
            www.victimconnect.org
          </a>
        </p>

        <p className="dating-title">FBI Internet Crime Complaint Center</p>
        <p className="dating-paragraph">
          <a style={{ color: theme }} href="https://www.ic3.gov/">
            www.ic3.gov
          </a>
        </p>

        <p className="dating-title">LGBT National Help Center</p>
        <p className="dating-paragraph">
          1-888-843-4564 |{" "}
          <a style={{ color: theme }} href="http://lgbtnationalhelpcenter.org/">
            www.lgbtnationalhelpcenter.org
          </a>
        </p>

        <p className="dating-title">Trans Lifeline</p>
        <p className="dating-paragraph">
          1-877-565-8860 (US) or 1-877-330-6366 (CA) |{" "}
          <a style={{ color: theme }} href="https://translifeline.org/">
            www.translifeline.org
          </a>
        </p>

        <p className="dating-title">7.{t("ds20")}</p>

        <p className="dating-paragraph">
          <a style={{ color: theme }} href="https://www.inhope.org/EN">
            INHOPE (To Report Illegal Online Content)
          </a>
        </p>

        <p className="dating-paragraph">
          <a
            style={{ color: theme }}
            href="https://www.globalmodernslavery.org/"
          >
            Global Modern Slavery Directory (Anti-Trafficking Service Provider
            and Hotline Directory)
          </a>
        </p>

        <p className="dating-paragraph">
          <a
            style={{ color: theme }}
            href="https://www.nowmattersnow.org/help-line"
          >
            Now Matters Now – Crisis Lines
          </a>
        </p>

        <p className="dating-paragraph">
          <a style={{ color: theme }} href="https://pathwaystosafety.org/">
            Pathways to Safety International (Support and Assistance for
            Americans Abroad)
          </a>
        </p>

        <p className="dating-paragraph">
          <a
            style={{ color: theme }}
            href="https://ilga.org/maps-sexual-orientation-laws"
          >
            ILGA Map of Sexual Orientation Laws
          </a>
        </p>

        <p className="dating-paragraph">
          <a style={{ color: theme }} href="https://www.rcne.com/">
            Rape Crisis Network Europe
          </a>
        </p>

        <p className="dating-paragraph">
          <a style={{ color: theme }} href="https://victimsupport.eu/">
            Victim Support Europe
          </a>
        </p>

        <p className="dating-paragraph">
          <a
            style={{ color: theme }}
            href="https://www.wave-network.org/find-help/"
          >
            Women Against Violence Europe (WAVE) – Find Help
          </a>
        </p>

        <p className="dating-paragraph">
          <a
            style={{ color: theme }}
            href="https://www.cyber.gov.au/acsc/report"
          >
            Australian Cybercrime Online Reporting Network
          </a>
        </p>
      </div>
      <AppLinks />
      <FooterLanguage />
      <Footer />
    </div>
  );
};

export default DatingSecurely;
