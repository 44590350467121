import React, { useContext } from "react";
import "./Header.styles.scss";
import { UserContext } from "../../contexts/UserProvider";
import { IoIosLogOut, IoMdArrowRoundBack } from "react-icons/io";
import { IoChatbubbleSharp } from "react-icons/io5";
import { useNavigate  } from "react-router-dom";

const Header = (props) => {
  const { theme, userid } = useContext(UserContext);
  const navigate = useNavigate();

  async function _theloggingout() {
    fetch("https://marihuanadating.com/endpoint-wld/insertfcmtoken.php", {
      method: "post",
      headers: {
        "access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        id: userid,
        fcmtoken: "",
      }),
    });

    localStorage.removeItem("userId");
    navigate("/");
  }
  function gotochat() {
    navigate(`/chat/${props.slug}`, { state: props.params });
  }

  function gotoback() {
    navigate(-1);
  }

  if (props.type === "logout") {
    return (
      <header className="header" style={{ backgroundColor: theme }}>
        <h1 style={{ color: "#fff" }}>{props.title}</h1>
        <IoIosLogOut
          size={25}
          color={"#fff"}
          style={{ cursor: "pointer" }}
          onClick={_theloggingout}
        />
      </header>
    );
  }

  if (props.type === "chat") {
    return (
      <header className="header" style={{ backgroundColor: theme }}>
         {props.hasgoback ? (
        <IoMdArrowRoundBack
          size={25}
          color={"#fff"}
          style={{ cursor: "pointer", marginRight:10  }}
          onClick={gotoback}
        />
      ) : null}
        <h1 style={{ color: "#fff" }}>{props.title}</h1>
        <IoChatbubbleSharp
          size={25}
          color={"#fff"}
          style={{ cursor: "pointer" }}
          onClick={gotochat}
        />
      </header>
    );
  }

  if (props.type === "inthechat") {
    return (
      <header
        className="header"
        style={{ backgroundColor: theme, justifyContent: "flex-start" }}
      >
         {props.hasgoback ? (
        <IoMdArrowRoundBack
          size={25}
          color={"#fff"}
          style={{ cursor: "pointer", marginRight:10 }}
          onClick={gotoback}
        />
      ) : null}
        <img
          className="userPic"
          src={`https://marihuanadating.com/wld_uploads/${props.photo}`}
        />
        <h1 style={{ color: "#fff", paddingLeft: "10px" }}>{props.title}</h1>
      </header>
    );
  }

  return (
    <header
      className="header"
      style={{ backgroundColor: theme, justifyContent: "flex-start" }}
    >
      {props.hasgoback ? (
        <IoMdArrowRoundBack
          size={25}
          color={"#fff"}
          style={{ cursor: "pointer", marginRight:10  }}
          onClick={gotoback}
        />
      ) : null}
      <h1 style={{ color: "#fff" }}>{props.title}</h1>
    </header>
  );
};

export default Header;
