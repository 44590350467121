import { useContext } from "react";
import "./FooterLanguage.styles.scss";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/UserProvider";

const FooterLanguage = () => {
  const { i18n, t } = useTranslation();
  const { theme } = useContext(UserContext);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleMouseEnter = (event) => {
    event.target.style.color = theme;
  };

  const handleMouseLeave = (event) => {
    event.target.style.color = "";
  };

  return (
    <div className="container-languages">
      <h3>{t("l3")}</h3>
      <div className="delimiter"></div>
      <div className="grid-container">
        <div
          className={`grid-item ${i18n.language === "en" ? "bold" : ""}`}
          onClick={() => changeLanguage("en")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          English
        </div>
        <div
          className={`grid-item ${i18n.language === "fr" ? "bold" : ""}`}
          onClick={() => changeLanguage("fr")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          French
        </div>
        <div
          className={`grid-item ${i18n.language === "de" ? "bold" : ""}`}
          onClick={() => changeLanguage("de")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          German
        </div>
        <div
          className={`grid-item ${i18n.language === "it" ? "bold" : ""}`}
          onClick={() => changeLanguage("it")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Italian
        </div>
     
        <div
          className={`grid-item ${i18n.language === "es" ? "bold" : ""}`}
          onClick={() => changeLanguage("es")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Spanish
        </div>
        <div
          className={`grid-item ${i18n.language === "pt" ? "bold" : ""}`}
          onClick={() => changeLanguage("pt")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Portuguese
        </div>
        <div
          className={`grid-item ${i18n.language === "nl" ? "bold" : ""}`}
          onClick={() => changeLanguage("nl")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Dutch
        </div>
        <div
          className={`grid-item ${i18n.language === "ro" ? "bold" : ""}`}
          onClick={() => changeLanguage("ro")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Romanian
        </div>
     
        <div
          className={`grid-item ${i18n.language === "af" ? "bold" : ""}`}
          onClick={() => changeLanguage("af")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Afrikaans
        </div>
        <div
          className={`grid-item ${i18n.language === "cs" ? "bold" : ""}`}
          onClick={() => changeLanguage("cs")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Czech
        </div>
        <div
          className={`grid-item ${i18n.language === "et" ? "bold" : ""}`}
          onClick={() => changeLanguage("et")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Estonian
        </div>
        <div
          className={`grid-item ${i18n.language === "is" ? "bold" : ""}`}
          onClick={() => changeLanguage("is")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Icelandic
        </div>
     
        <div
          className={`grid-item ${i18n.language === "hr" ? "bold" : ""}`}
          onClick={() => changeLanguage("hr")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Croatian
        </div>
        <div
          className={`grid-item ${i18n.language === "hu" ? "bold" : ""}`}
          onClick={() => changeLanguage("hu")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Hungarian
        </div>
        <div
          className={`grid-item ${i18n.language === "lt" ? "bold" : ""}`}
          onClick={() => changeLanguage("lt")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Lithuanian
        </div>
        <div
          className={`grid-item ${i18n.language === "lv" ? "bold" : ""}`}
          onClick={() => changeLanguage("lv")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Latvian
        </div>

        <div
          className={`grid-item ${i18n.language === "no" ? "bold" : ""}`}
          onClick={() => changeLanguage("no")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Norwegian
        </div>
        <div
          className={`grid-item ${i18n.language === "sv" ? "bold" : ""}`}
          onClick={() => changeLanguage("sv")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Swedish
        </div>
        <div
          className={`grid-item ${i18n.language === "fi" ? "bold" : ""}`}
          onClick={() => changeLanguage("fi")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Finnish
        </div>
        <div
          className={`grid-item ${i18n.language === "da" ? "bold" : ""}`}
          onClick={() => changeLanguage("da")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Dannish
        </div>
     
        <div
          className={`grid-item ${i18n.language === "sk" ? "bold" : ""}`}
          onClick={() => changeLanguage("sk")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Slovakian
        </div>
        <div
          className={`grid-item ${i18n.language === "sl" ? "bold" : ""}`}
          onClick={() => changeLanguage("sl")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Slovenian
        </div>
        <div
          className={`grid-item ${i18n.language === "pl" ? "bold" : ""}`}
          onClick={() => changeLanguage("pl")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Polish
        </div>
        <div
          className={`grid-item ${i18n.language === "tr" ? "bold" : ""}`}
          onClick={() => changeLanguage("tr")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Turkish
        </div>
     
        <div
          className={`grid-item ${i18n.language === "el" ? "bold" : ""}`}
          onClick={() => changeLanguage("el")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Greek
        </div>
        <div
          className={`grid-item ${i18n.language === "ja" ? "bold" : ""}`}
          onClick={() => changeLanguage("ja")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Japanese
        </div>
        <div
          className={`grid-item ${i18n.language === "ko" ? "bold" : ""}`}
          onClick={() => changeLanguage("ko")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Korean
        </div>
        <div
          className={`grid-item ${i18n.language === "th" ? "bold" : ""}`}
          onClick={() => changeLanguage("th")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Thai
        </div>
   
        <div
          className={`grid-item ${i18n.language === "vi" ? "bold" : ""}`}
          onClick={() => changeLanguage("vi")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Vietnamese
        </div>
        <div
          className={`grid-item ${i18n.language === "zh" ? "bold" : ""}`}
          onClick={() => changeLanguage("zh")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Chinese
        </div>
        <div
          className={`grid-item ${i18n.language === "ru" ? "bold" : ""}`}
          onClick={() => changeLanguage("ru")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Russian
        </div>
        <div
          className={`grid-item ${i18n.language === "id" ? "bold" : ""}`}
          onClick={() => changeLanguage("id")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Indonesian
        </div>
      
        <div
          className={`grid-item ${i18n.language === "hi" ? "bold" : ""}`}
          onClick={() => changeLanguage("hi")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Hindi
        </div>
        <div
          className={`grid-item ${i18n.language === "bn" ? "bold" : ""}`}
          onClick={() => changeLanguage("bn")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Bengali
        </div>
      </div>
    </div>
  );
};

export default FooterLanguage;
