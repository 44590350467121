import { useContext, useState, useEffect } from "react";
import "./Expand.css";
import { UserContext } from "../../contexts/UserProvider";
import PlanButton from "../PlanButton/PlanButton.component";
import { useLocation } from "react-router-dom";
import { FormComponent, FormContainer } from "react-authorize-net";
import Button from "../Button/Button.component";
import CreditCardInput from "react-credit-card-input";
import { useTranslation } from "react-i18next";
import axios from "axios";

const transactionkey = "23Rfr67TgeJ82sK7";
const apiloginkey = "952WrpUGm";
const authorizeNetApiUrl = "https://api.authorize.net/xml/v1/request.api"; // Adjust this URL according to your environment

const ExpandBox = (props) => {
  const { theme, showBox, setShowBox, setRegisterPanel } = useContext(
    UserContext
  );
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const [amount, setAmount] = useState(0);
  const [plan, setPlan] = useState(false);
  const [days, setDays] = useState(0);
  const [status, setStatus] = useState(["", []]);
  const [expMsg, setExpMsg] = useState("");
  const [expColor, setExpColor] = useState("green");
  const [inProgress, setInProgress] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
    setExpColor("green");
    setExpMsg("");
    console.log(cardNumber);
  };

  const handleCardCVCChange = (e) => {
    setCvc(e.target.value);
    setExpColor("green");
    setExpMsg("");
    console.log(cvc);
  };

  const handleCardExpiryChange = (e) => {
    setExpiry(e.target.value);
    setExpColor("green");
    setExpMsg("");
    console.log(expiry);
  };

  const gobackoptions = () => {
    setPlan(false);
    setInProgress(false);
    setCardNumber("");
    setExpiry("");
    setCvc("");
    setExpColor("green");
    setExpMsg("");
  };

  useEffect(() => {
    // Set showBox to true after component mounts to trigger animation
    setShowBox(true);
  }, []);

  const createTransaction = async (paymentData) => {
    try {
      const response = await axios.post(authorizeNetApiUrl, paymentData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiloginkey, // Replace with your actual API key
        },
      });

      console.log("Transaction created:", response.data);

      if (response.data.transactionResponse.errors) {
        if (
          response.data.transactionResponse.errors[0].errorText ==
          "This transaction has been declined."
        ) {
          setExpMsg(t("x14"));
          setExpColor("red");
        }
      }


      // Process the response and handle success/error
      if (response.data.messages.resultCode === "Ok") {
        if (response.data.transactionResponse.messages) {
          if (
            response.data.transactionResponse.messages[0].description ==
            "This transaction has been approved."
          ) {
            setExpMsg(t("x19"));
            setExpColor("green");
            setInProgress(true);
            if (amount == 0.01) {
              expandMembership1();
              return;
            }
            if (amount == 0.02) {
              expandMembership2();
              return;
            }
            if (amount == 0.03) {
              expandMembership3();
              return;
            }
          }
        }
      } else {
        setExpMsg(t("x20"));
        setExpColor("red");
      }
    } catch (error) {
      console.error("Error creating transaction:", error);
      setExpMsg(t("x20"));
      setExpColor("red");
    }
  };

  const handlePayment = (card) => {
    // Example payment data
    const paymentData = {
      createTransactionRequest: {
        merchantAuthentication: {
          name: apiloginkey, // Replace with your actual API login ID
          transactionKey: transactionkey, // Replace with your actual transaction key
        },
        transactionRequest: {
          transactionType: "authCaptureTransaction",
          amount: amount,
          payment: {
            creditCard: {
              cardNumber: card.number,
              expirationDate: card.exp_month + card.exp_year,
              cardCode: card.cvc,
            },
          },
          customer: {
            email: email,
          },
        },
      },
    };

    createTransaction(paymentData);
  };

  const handlePlan1 = () => {
    setInProgress(true);
    setAmount(0.01);
    //expandMembership1();
    setPlan(true);
  };

  const expandMembership1 = () => {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/expandmembership1.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          amount: 9.95,
          days: 30,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson === "ok") {
          setExpMsg(
            "The transaction has been registered. Your subscription has been expanded."
          );
        }
      });
  };

  const expandMembership2 = () => {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/expandmembership2.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          amount: 17.95,
          days: 90,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson === "ok") {
          setExpMsg(
            "The transaction has been registered. Your subscription has been expanded."
          );
        }
      });
  };

  const expandMembership3 = () => {
    return fetch(
      "https://marihuanadating.com/endpoint-wld/expandmembership3.php",
      {
        method: "post",
        headers: {
          "access-control-allow-origin": "*",
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          amount: 29.85,
          days: 180,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson === "ok") {
          setExpMsg(
            "The transaction has been registered. Your subscription has been expanded."
          );
        }
      });
  };

  const handlePlan2 = () => {
    setInProgress(true);
    setAmount(0.02);
    //expandMembership2();
    setPlan(true);
  };

  const handlePlan3 = () => {
    setInProgress(true);
    setAmount(0.03);
    //expandMembership3();
    setPlan(true);
  };

  // Function to validate the expiry date
  const validateExpiryDate = (month, year) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
    const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed
    const expiryYearInt = parseInt(year, 10);
    const expiryMonthInt = parseInt(month, 10);


      return !(
        expiryYearInt < currentYear ||
        (expiryYearInt === currentYear && expiryMonthInt < currentMonth)
      );
  };

  const makeThePayment = () => {
    const card = {
      number: cardNumber.replace(/ /g, ""),
      exp_month: expiry.split("/")[0],
      exp_year: expiry.split("/")[1],
      cvc: cvc,
    };

    if (card.number == "") {
      setExpMsg(t("x15"));
      setExpColor("red");
      return;
    }

    if (card.number.length < 16) {
      setExpMsg(t("x15"));
      setExpColor("red");
      console.log("errrrrrrrr");
      return;
    }

    if (card.exp_month == "") {
      setExpMsg(t("x16"));
      setExpColor("red");
      return;
    }
    if (card.exp_year == "") {
      setExpMsg(t("x16"));
      setExpColor("red");
      return;
    }

    if (validateExpiryDate(card.exp_month, card.exp_year) === false) {
      console.log(validateExpiryDate(card.exp_month, card.exp_year));
      setExpMsg(t("x16"));
      setExpColor("red");
      return;
    }
    if (card.cvc == "") {
      setExpMsg(t("x17"));
      setExpColor("red");
      return;
    }
   handlePayment(card);
  };

  return (
    <div>
      <div className={`expansion-box ${showBox ? "show" : ""}`}>
        <p className="thetitle" style={{ color: theme }}>
          {t("l47")}
        </p>
        <p className="theparagraf">
          {t("l48")} <br /> <span style={{ color: theme }}> {email}</span>
        </p>
        {plan ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
              }}
            >
              <CreditCardInput
                customTextLabels={{
                  invalidCardNumber: t("x15"),
                  expiryError: {
                    invalidExpiryDate: t("x16"),
                    monthOutOfRange: t("x16"),
                    yearOutOfRange: t("x16"),
                    dateOutOfRange: t("x16"),
                  },
                  invalidCvc: t("x17"),

                  cardNumberPlaceholder: t("x24"),
                  expiryPlaceholder: "MM/YY",
                  cvcPlaceholder: t("x23"),
                }}
                cardNumberInputProps={{
                  value: cardNumber,
                  onChange: (e) => handleCardNumberChange(e),
                }}
                cardExpiryInputProps={{
                  value: expiry,
                  onChange: (e) => handleCardExpiryChange(e),
                }}
                cardCVCInputProps={{
                  value: cvc,
                  onChange: (e) => handleCardCVCChange(e),
                }}
                fieldClassName="input"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
              }}
            >
              <button
                style={{
                  backgroundColor: theme,
                  color: "#fff",
                }}
                className="button "
                onClick={makeThePayment}
              >
                {t("x21") + " $" + amount}
              </button>
            </div>
            <p
              style={{
                color: expColor,
                textAlign: "center",
                fontSize: 16,
                fontWeight: "900",
                marginVertical: 10,
              }}
            >
              {expMsg}
            </p>
            <p
              style={{ color: theme, cursor: "pointer", margin: 50 }}
              onClick={() => {
                gobackoptions();
              }}
            >
              {t("l49")}
            </p>
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            <PlanButton
              text={"$9.95 /" + t("x6")}
              onFunction={handlePlan1}
              activation={inProgress}
            />
            <PlanButton
              text={"$17.95 /3" + " " + t("x7")}
              text2={t("x8") + " " + "40%"}
              onFunction={handlePlan2}
              activation={inProgress}
            />
            <PlanButton
              text={"$29.85 /6" + " " + t("x7")}
              text2={t("x8") + " " + "50%"}
              onFunction={handlePlan3}
              activation={inProgress}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandBox;
